import React, { FormEvent } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MainWrapper, GoogleMap, TerritoryForm } from 'components';
import { useCreate, useFormData, useSnackbar, useValidateTerritoryForm } from 'hooks';
import { TerritoryData, TerritoryDataResponse, TerritoryDataSend, TerritoryFields } from 'types';
import { territoryFormInitialValues } from 'constant';
import { useNavigate } from 'react-router-dom';
import { getAreaPolygonArray, getPrepareTerritoryData } from 'helpers';

export const TerritoryCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();

  const {
    loading,
    data,
    createData: createTerritory,
  } = useCreate<TerritoryDataSend, TerritoryDataResponse>('/territories');

  const { values, errors, setValue, handleChange } = useFormData(
    territoryFormInitialValues,
    data as TerritoryData,
    useValidateTerritoryForm
  );

  const mapCoordinates: string[] | undefined = values[TerritoryFields.Map]?.split(/[ ,]+/);
  const mapLat = mapCoordinates?.at(0) ?? '';
  const mapLng = mapCoordinates?.at(1) ?? '';

  const isNotValidFormData = Boolean(Object.keys(errors).length);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isNotValidFormData) return;

    const data = getPrepareTerritoryData(values);

    createTerritory(data)
      .then(data => {
        openSnackbar(t('CreateTerritorySuccess'), 'success');
        navigate(`/territory/${data?.lastInsertId}`);
      })
      .catch(err => {
        openSnackbar(`${t('CreateTerritoryError')}\n${err}`, 'error');
      });
  };

  return (
    <MainWrapper>
      <Stack my={1} gap={{ xs: 3, md: 4 }} direction={{ xs: 'column', md: 'row' }}>
        <GoogleMap
          lat={+mapLat}
          lng={+mapLng}
          info={`${values[TerritoryFields.Street]}, ${values[TerritoryFields.House]}, ${
            values[TerritoryFields.Entrance]
          }`}
          zoom={values[TerritoryFields.Zoom]}
          polygonCoordinates={getAreaPolygonArray(values[TerritoryFields.Area])}
        />

        <TerritoryForm
          headerText={t('TerritoryCreate')}
          buttonText={t('Confirm')}
          loading={loading || isNotValidFormData}
          values={values}
          errors={errors}
          setValue={setValue}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </Stack>
    </MainWrapper>
  );
};
