import React, { FC, useCallback, useState } from 'react';
import { Snackbar, Alert, AlertColor, AlertTitle } from '@mui/material';
import { SnackbarContext } from './SnackbarContext';

export const SnackbarProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [variant, setVariant] = useState<AlertColor>('success');
  const [showTitle, setShowTitle] = useState(false);

  const openSnackbar = useCallback(
    (message: string, variant: AlertColor = 'success', showTitle = false) => {
      setOpen(true);
      setMessage(message);
      setVariant(variant);
      setShowTitle(showTitle);
    },
    []
  );

  const closeSnackbar = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={closeSnackbar}
          elevation={6}
          severity={variant}
          sx={{ whiteSpace: 'pre-line' }}
        >
          {showTitle && <AlertTitle>{variant}</AlertTitle>}
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
