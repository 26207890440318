import * as React from 'react';
import { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { IMaskInput } from 'react-imask';

import ErrorDialog from '../../common/error-dialog';
import BackDrop from '../../common/backdrop';

import { useTranslation } from 'react-i18next';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://jwhlp.com/">
        https://jwhlp.com/
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const theme = createTheme();

export default function SignUp() {
  const { t } = useTranslation(); // i18n

  const [firstName, changeFirstName] = useState('');
  const [lastName, changeLastName] = useState('');
  const [phoneNumber, changePhoneNumber] = useState('');
  const [congregation, changeCongregation] = useState('');
  const [email, changeEmail] = useState('');
  const [message, changeMessage] = useState('');

  const [firstNameValidationErr, setFirstNameValidationErr] = useState(null);
  const [lastNameValidationErr, setLastNameValidationErr] = useState(null);
  const [pnValidationErr, setPNValidationErr] = useState(null);
  const [congregationValidationErr, setCongregationValidationErr] = useState(null);
  const [emailValidationErr, setEmailValidationErr] = useState(null);
  const [messageValidationErr, setMessageValidationErr] = useState(null);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleFirstNameChange = event => {
    if (firstNameValidationErr) setFirstNameValidationErr(null);
    changeFirstName(event.target.value);
  };
  const handleLastNameChange = event => {
    if (lastNameValidationErr) setLastNameValidationErr(null);
    changeLastName(event.target.value);
  };
  const handlePNChange = event => {
    if (pnValidationErr) setPNValidationErr(null);
    changePhoneNumber(event.target.value);
  };
  const handleCongregationChange = event => {
    if (congregationValidationErr) setCongregationValidationErr(null);
    changeCongregation(event.target.value);
  };
  const handleEmailChange = event => {
    if (emailValidationErr) setEmailValidationErr(null);
    changeEmail(event.target.value);
  };
  const handleMessageChange = event => {
    if (messageValidationErr) setMessageValidationErr(null);
    changeMessage(event.target.value);
  };

  const handleSubmit = async () => {
    setBackdropOpen(true);

    let errors = {
      firstName: firstNameValidationErr,
      lastName: lastNameValidationErr,
      phoneNumber: pnValidationErr,
      congregation: congregationValidationErr,
      email: emailValidationErr,
      message: messageValidationErr,
    };

    if (firstName.length === 0) errors.firstName = t('Please provide your first name.');
    else if (!/^.{2,30}$/.test(firstName)) errors.firstName = t('Allowed 2-30 symbols');

    if (lastName.length === 0) errors.lastName = t('Please provide your last name.');
    else if (!/^.{2,30}$/.test(lastName)) errors.lastName = t('Allowed 2-30 symbols');

    if (phoneNumber.length === 0)
      errors.phoneNumber = t('Please provide your phone number that connected with messenger');
    if (!/^.{10,50}$/.test(phoneNumber)) errors.phoneNumber = t('Allowed 10-50 symbols');

    if (congregation.length === 0) errors.congregation = t('Please provide your congregation name');
    else if (!/^.{10,50}$/.test(congregation)) errors.congregation = t('Allowed 10-50 symbols');

    if (email.length === 0) errors.email = t('Please provide your email');
    else if (
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
      )
    )
      errors.email = t('Please, provide valid email');

    if (message.length === 0)
      errors.message = t('Please write some additional information about your congreagetion');

    if (
      errors.firstName ||
      errors.lastName ||
      errors.phoneNumber ||
      errors.congregation ||
      errors.email ||
      errors.message
    ) {
      setBackdropOpen(false);

      setFirstNameValidationErr(errors.firstName);
      setLastNameValidationErr(errors.lastName);
      setPNValidationErr(errors.phoneNumber);
      setCongregationValidationErr(errors.congregation);
      setEmailValidationErr(errors.email);
      setMessageValidationErr(errors.message);

      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/congr`;
      const body = {
        firstName: firstName,
        lastName: lastName,
        phones: phoneNumber,
        congregation: congregation,
        username: email,
        message: message,
      };

      const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
          if (response.ok) {
            setBackdropOpen(false);
            window.location = '/sign-up';
          }
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <BackDrop open={backdropOpen} />
      <ErrorDialog open={Boolean(error)} text={error} />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign-Up Congregation')}
          </Typography>

          <Box onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  autoComplete="given-name"
                  fullWidth
                  label={t('First Name')}
                  error={Boolean(firstNameValidationErr)}
                  helperText={firstNameValidationErr}
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  autoComplete="family-name"
                  label={t('Last Name')}
                  error={Boolean(lastNameValidationErr)}
                  helperText={lastNameValidationErr}
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel>{t('Phone Number')}</InputLabel>
                  <OutlinedInput
                    fullWidth
                    label={t('Phone Number')}
                    autoComplete="phone"
                    inputComponent={TextMaskCustom}
                    value={phoneNumber}
                    onChange={handlePNChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Congregation name')}
                  autoComplete="congregation"
                  error={Boolean(congregationValidationErr)}
                  helperText={congregationValidationErr}
                  value={congregation}
                  onChange={handleCongregationChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Email Address')}
                  autoComplete="email"
                  error={Boolean(emailValidationErr)}
                  helperText={emailValidationErr ? emailValidationErr : t('EmailUsername')}
                  value={email}
                  onChange={handleEmailChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Message')}
                  type="message"
                  multiline
                  maxRows={500}
                  rows={5}
                  error={Boolean(messageValidationErr)}
                  helperText={messageValidationErr}
                  value={message}
                  onChange={handleMessageChange}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              {t('Sign up')}
            </Button>

            <Grid container justifyContent="flex-end">
              <Grid item xs>
                <Link href="/sign-in" variant="body2">
                  {t('Sign in')}
                </Link>
              </Grid>
              <Grid item>
                <Link href="/sign-up" variant="body2">
                  {t('Sign up')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
