import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';

const YES = '\u2713';
const NO = '\u2715';

export default function YesNo({ classes, name, label, hint, value, onChange }) {
  // console.log("YesNo.begining", classes, name, label, value, typeof value);

  return (
    <Tooltip title={hint ? hint : ''}>
      <FormControl component="fieldset" className={classes.yesNo}>
        <FormLabel component="legend">{label}</FormLabel>

        <RadioGroup
          aria-label={name}
          name={name}
          value={value === 1 ? '1' : '0'}
          onChange={onChange}
          row
        >
          <FormControlLabel value="1" control={<Radio color="primary" />} label={YES} />
          <FormControlLabel value="0" control={<Radio color="secondary" />} label={NO} />
        </RadioGroup>
      </FormControl>
    </Tooltip>
  );
}
