import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import Backend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './en/translation.json';
import translationRU from './ru/translation.json';
import translationUA from './ua/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  ua: {
    translation: translationUA,
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //   .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next

  .init({
    resources,
    lng: localStorage.lang ? localStorage.lang : 'ua',
    fallbackLng: 'ua',
    debug: false,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  });

export default i18n;
