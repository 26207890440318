import React, { ChangeEvent, FC, FormEvent, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ChevronLeft, Delete, Edit, EditOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import {
  AreaPolygon,
  Maybe,
  Scalars,
  TerritoryData,
  TerritoryFields,
  UserData,
  UserFields,
  UserPermissions,
} from 'types';
import { FormDataErrors, useAll, useDelete, useSnackbar, useUpdate } from 'hooks';
import {
  getJwtData,
  getSetLocalStorage,
  getWarnEndTerritoryDates,
  isPermissionsIncludes,
} from 'helpers';
import { format, isBefore } from 'date-fns';

// import ShareTerritoryButton from '../common/ShareTerritoryButton';
import ButtonCopyToClipboard from '../common/ButtonCopyToClipboard';
import { Icon } from 'legos';
import { GoogleMapPolygonCoordinatesRepeater } from 'components/GoogleMapPolygonCoordinatesRepeater';

type Props = {
  headerText: string;
  buttonText: string;
  loading?: boolean;
  values: TerritoryData;
  errors?: FormDataErrors<TerritoryData>;
  setValue: (name: keyof TerritoryData, value?: AreaPolygon | string | number | null) => void;
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

const isCanEdit =
  isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
  isPermissionsIncludes(UserPermissions.TerritoryAssist);

export const TerritoryForm: FC<Props> = ({
  headerText,
  buttonText,
  loading,
  values,
  errors,
  setValue,
  handleChange,
  handleSubmit,
}) => {
  const jwtData = getJwtData();
  const [favorite, setFavorite] =
    getSetLocalStorage<Array<Maybe<Scalars['ID']> | undefined>>('favorite');

  const { t } = useTranslation();
  const { id } = useParams();
  const { openSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { data: users } = useAll<UserData[]>('/users');
  const { updateData: updateTerritory } = useUpdate<TerritoryData, TerritoryData>(
    '/territories',
    id
  );
  const { deleteData: deleteTerritory } = useDelete('/territories', id);

  const isTerritoryCreate = pathname === '/territory/create';

  const [isFavorite, setIsFavorite] = useState(favorite.includes(Number(id)));
  const [isEditMode, setIsEditMode] = useState(isTerritoryCreate);

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const options = users?.map(option => {
    const firstLetter = option?.lastName?.at(0)?.toUpperCase();
    const fullName = `${option.lastName} ${option.firstName}`;

    return {
      firstLetter: firstLetter ?? '',
      fullName,
      ...option,
    };
  });

  const today = new Date();
  const dateString = format(today, 'yyyy-MM-dd');
  const { warnDate, endDate } = getWarnEndTerritoryDates(
    new Date(values[TerritoryFields.StartAt] ?? '')
  );

  const date3monthInAdvance = new Date();
  date3monthInAdvance.setMonth(date3monthInAdvance.getMonth() + 3);
  const dateFormatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const formatted3MonthInAdvanceDate = date3monthInAdvance.toLocaleDateString(
    undefined,
    dateFormatOptions
  );

  const alertSeverity: { value: AlertColor } = {
    value: 'success',
  };

  if (warnDate && isBefore(warnDate, today)) {
    alertSeverity.value = 'warning';
  }

  if (endDate && isBefore(endDate, today)) {
    alertSeverity.value = 'error';
  }

  const optionValues =
    options?.find(option => option[UserFields.ID] === values[TerritoryFields.UserID]) ?? null;

  const handleClickEditButton = () => {
    setIsEditMode(prevState => !prevState);
  };

  const handleClickTakeButton = () => {
    const data = {
      ...values,
      [TerritoryFields.UserID]: jwtData.id,
      [TerritoryFields.StartAt]: dateString,
      [TerritoryFields.FinishAt]: '',
    };

    updateTerritory(data)
      .then(() => {
        openSnackbar(t('TakeTerritorySuccess'), 'success');
        navigate('/territories');
      })
      .catch(err => {
        openSnackbar(`${t('UpdateTerritoryError')}\n${err}`, 'error');
      });
  };

  const handleClickPassButton = () => {
    const data = {
      ...values,
      [TerritoryFields.UserID]: null,
      [TerritoryFields.StartAt]: '',
      [TerritoryFields.FinishAt]: dateString,
    };

    updateTerritory(data)
      .then(() => {
        openSnackbar(t('PassTerritorySuccess'), 'success');
        navigate('/territories');
      })
      .catch(err => {
        openSnackbar(`${t('UpdateTerritoryError')}\n${err}`, 'error');
      });
  };

  const handleClickDeleteButton = (el: HTMLElement) => {
    setAnchorEl(el);
    setIsPopperOpen(true);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setIsPopperOpen(false);
  };

  const handleDelete = () => {
    handleClickAway();

    deleteTerritory()
      .then(() => {
        openSnackbar(t('DeleteTerritorySuccess'), 'success');
        navigate('/territories');
      })
      .catch(err => {
        openSnackbar(`${t('UpdateTerritoryError')}\n${err}`, 'error');
      });
  };

  const handleAddFavorite = () => {
    const newFavorite = [...favorite, Number(id)];

    setIsFavorite(isFavorite => !isFavorite);
    setFavorite([...newFavorite]);
  };

  const handleRemoveFavorite = () => {
    const filteredFavorite = favorite.filter(favoriteId => favoriteId !== Number(id));

    setIsFavorite(isFavorite => !isFavorite);
    setFavorite([...filteredFavorite]);
  };

  return (
    <Stack
      component="form"
      gap={2}
      width="100%"
      onSubmit={e => {
        setIsEditMode(isEditMode => !isEditMode);
        handleSubmit(e);
      }}
    >
      {!!values[TerritoryFields.StartAt] && !!values[TerritoryFields.UserID] && (
        <Stack direction="row">
          <Alert severity={alertSeverity.value}>
            {t('TerritoryInfo')} {t(format(endDate, 'EEEE'))}, {format(endDate, 'd')}{' '}
            {t(format(endDate, 'LLLL'))} {format(endDate, 'Y')}
          </Alert>
        </Stack>
      )}
      <Stack
        direction="row"
        gap={1}
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6">
          {isCanEdit && isEditMode
            ? headerText
            : `${t(TerritoryFields.Code)}: ${values[TerritoryFields.Code]}`}
        </Typography>
        <Stack gap={1} direction="row">
          <IconButton color="primary" sx={{ border: '1px solid' }} onClick={() => navigate(-1)}>
            <ChevronLeft />
          </IconButton>

          {!isTerritoryCreate &&
            (isFavorite ? (
              <IconButton
                color="primary"
                sx={{ border: '1px solid' }}
                onClick={handleRemoveFavorite}
              >
                <Icon icon="favorite" />
              </IconButton>
            ) : (
              <IconButton color="primary" sx={{ border: '1px solid' }} onClick={handleAddFavorite}>
                <Icon icon="favoriteBorder" />
              </IconButton>
            ))}

          {!isTerritoryCreate && (isCanEdit || jwtData.id === values[TerritoryFields.UserID]) && (
            <IconButton
              color="primary"
              sx={{ border: '1px solid' }}
              onClick={handleClickEditButton}
            >
              {isEditMode ? <EditOff /> : <Edit />}
            </IconButton>
          )}
        </Stack>
      </Stack>

      {isCanEdit && isEditMode && (
        <Stack gap={2} direction={{ xs: 'column', sm: 'row' }}>
          <Autocomplete
            fullWidth
            value={optionValues}
            options={options?.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) ?? []}
            groupBy={option => option.firstLetter}
            getOptionLabel={option => option.fullName}
            onChange={(e, newValue) => {
              setValue(TerritoryFields.UserID, newValue?.id);
              setValue(TerritoryFields.StartAt, dateString);
              setValue(TerritoryFields.FinishAt, '');
            }}
            renderInput={params => (
              <TextField
                label={t('publisher')}
                error={!!errors?.user_id}
                helperText={errors?.user_id}
                {...params}
              />
            )}
          />
          <TextField
            fullWidth
            label={t(TerritoryFields.Map)}
            name={TerritoryFields.Map}
            value={values[TerritoryFields.Map]}
            onChange={handleChange}
          />
        </Stack>
      )}

      {isCanEdit && isEditMode && (
        <GoogleMapPolygonCoordinatesRepeater
          area={values[TerritoryFields.Area]}
          zoom={values[TerritoryFields.Zoom]}
          setValue={setValue}
          handleChange={handleChange}
        />
      )}

      {optionValues && (!isEditMode || !isCanEdit) && (
        <Stack>
          <Typography>
            <Box component="span" fontWeight="fontWeightMedium" display="inline">
              {t('publisher')}:
            </Box>{' '}
            {optionValues?.fullName}
          </Typography>
        </Stack>
      )}

      <Stack gap={2} direction={{ xs: 'column', sm: 'row' }}>
        {isCanEdit && isEditMode ? (
          <>
            <TextField
              fullWidth
              label={t(TerritoryFields.Code)}
              name={TerritoryFields.Code}
              value={values[TerritoryFields.Code]}
              onChange={handleChange}
              error={!!errors?.code}
              helperText={errors?.code}
            />
            <TextField
              fullWidth
              label={t(TerritoryFields.Street)}
              name={TerritoryFields.Street}
              value={values[TerritoryFields.Street]}
              onChange={handleChange}
              error={!!errors?.street}
              helperText={errors?.street}
            />
          </>
        ) : (
          <Typography>
            <Box component="span" fontWeight="fontWeightMedium" display="inline">
              {t(TerritoryFields.Street)}:
            </Box>
            {` ${values[TerritoryFields.Street]}${
              values[TerritoryFields.House]
                ? ', ' +
                  t(TerritoryFields.House).toLowerCase().substring(0, 3) +
                  '. ' +
                  values[TerritoryFields.House]
                : ''
            }${
              values[TerritoryFields.Entrance]
                ? ', ' +
                  t(TerritoryFields.Entrance).toLowerCase().substring(0, 3) +
                  '. ' +
                  values[TerritoryFields.Entrance]
                : ''
            }`}
          </Typography>
        )}
      </Stack>

      {isCanEdit && isEditMode && (
        <>
          <Stack gap={2} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              fullWidth
              label={t(TerritoryFields.House)}
              name={TerritoryFields.House}
              value={values[TerritoryFields.House]}
              error={!!errors?.house}
              helperText={errors?.house}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label={t(TerritoryFields.Entrance)}
              name={TerritoryFields.Entrance}
              value={values[TerritoryFields.Entrance]}
              error={!!errors?.entrance}
              helperText={errors?.entrance}
              onChange={handleChange}
            />
          </Stack>
          <Stack gap={2} direction={{ xs: 'column', sm: 'row' }}>
            <TextField
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              label={t(`${TerritoryFields.StartAt}2`)}
              name={TerritoryFields.StartAt}
              value={values[TerritoryFields.StartAt]}
              onChange={e => {
                handleChange(e);
                setValue(TerritoryFields.FinishAt, '');
              }}
              error={!!errors?.start_at}
              helperText={errors?.start_at}
            />
            <TextField
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              label={t(TerritoryFields.FinishAt)}
              name={TerritoryFields.FinishAt}
              value={values[TerritoryFields.FinishAt]}
              onChange={e => {
                handleChange(e);
                setValue(TerritoryFields.UserID, '');
                setValue(TerritoryFields.StartAt, '');
              }}
              error={!!errors?.finish_at}
              helperText={errors?.finish_at}
            />
          </Stack>
        </>
      )}

      <Stack>
        {isEditMode ? (
          <TextField
            fullWidth
            multiline
            rows={5}
            label={t(TerritoryFields.Comment)}
            name={TerritoryFields.Comment}
            value={values[TerritoryFields.Comment]}
            onChange={handleChange}
          />
        ) : (
          !!values[TerritoryFields.Comment] && (
            <>
              <Typography fontWeight="medium">{t(TerritoryFields.Comment)}:</Typography>
              <Typography sx={{ whiteSpace: 'pre-line' }}>
                {values[TerritoryFields.Comment]}
              </Typography>
            </>
          )
        )}
      </Stack>

      <Stack gap={1} direction="row" justifyContent="flex-end">
        {!isTerritoryCreate && (
          <ButtonCopyToClipboard
            text={`Дорогий вісник, за тобою закріплено територію №${
              values[TerritoryFields.Code]
            }\r\nАдреса: ${values[TerritoryFields.Street]} ${values[TerritoryFields.House]} ${
              values[TerritoryFields.Entrance] ? '(' + values[TerritoryFields.Entrance] + ')' : ''
            }\r\nПостарайся здати територію до ${formatted3MonthInAdvanceDate}\r\n${
              values[TerritoryFields.Map]
                ? 'https://www.google.com/maps/place/' +
                  values[TerritoryFields.Map]?.replace(/\s/g, ',')
                : ''
            }`}
          />
        )}
        {!isTerritoryCreate &&
          (!values[TerritoryFields.UserID]
            ? (isPermissionsIncludes(UserPermissions.TerritoryView) || isCanEdit) && (
                <Button variant="outlined" onClick={handleClickTakeButton}>
                  {t('take')}
                </Button>
              )
            : (isCanEdit || jwtData.id === values[TerritoryFields.UserID]) && (
                <Button variant="outlined" onClick={handleClickPassButton}>
                  {t('pass')}
                </Button>
              ))}
        {isEditMode && (
          <>
            <Button variant="contained" type="submit" disabled={loading}>
              {buttonText}
            </Button>
            {!isTerritoryCreate && isPermissionsIncludes(UserPermissions.TerritoryEditor) && (
              <Button
                color="error"
                variant="contained"
                onClick={e => handleClickDeleteButton(e.currentTarget)}
              >
                <Delete />
              </Button>
            )}
          </>
        )}
      </Stack>

      {isPopperOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper open={isPopperOpen} anchorEl={anchorEl}>
            <Paper elevation={6}>
              <Stack p={2} my={2} width={270}>
                <Typography mb={2} textAlign="center">
                  {t('AreYouSure')}
                </Typography>
                <Stack gap={2} direction="row" justifyContent="center">
                  <Button size="small" variant="outlined" onClick={handleClickAway}>
                    {t('no')}
                  </Button>
                  <Button size="small" variant="outlined" color="error" onClick={handleDelete}>
                    {t('yes')}
                  </Button>
                </Stack>
              </Stack>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </Stack>
  );
};
