import * as React from 'react';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import TransferList from './TransferList';
import { useAll } from '../hooks/useAll';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

const StyledTextField = styled(TextField)({
  margin: '8px 0',
});

function not(a, b) {
return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
return a.filter((value) => b.indexOf(value) !== -1);
}

  
function UserRoles() {
//   const [roles, setRoles] = React.useState([]);
//   const [congregations, setCongregations] = React.useState([]);
  const [selectedCongregation, setSelectedCongregation] = React.useState(null);
  const [publishers, setPublishers] = React.useState([]);
  const [selectedPublisher, setSelectedPublisher] = React.useState(null);

  const { data: roles } = useAll('/roles');
  const { data: congregations } = useAll('/congr');

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(["role0", "role1", "role2", "role3"]);
  const [right, setRight] = React.useState(["role4", "role5", "role6", "role7"]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items) => (
    <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value + 1}`} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );

//   React.useEffect(() => {
//     // Fetch roles on component mount
//     fetch('http://localhost/api/roles')
//       .then((response) => response.json())
//       .then((data) => setRoles(data))
//       .catch((error) => console.log(error));
//   }, []);

//   React.useEffect(() => {
//     // Fetch congregations on component mount
//     fetch('http://localhost/api/congr')
//       .then((response) => response.json())
//       .then((data) => setCongregations(data))
//       .catch((error) => console.log(error));
//   }, []);

  React.useEffect(() => {
    if (selectedCongregation) {
      // Fetch publishers for selected congregation
      //fetch(`${process.env.REACT_APP_API_URL}/users/${selectedCongregation.id}`, {
      fetch(`${process.env.REACT_APP_API_URL}/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      }
      )

        .then((response) => response.json())
        .then((data) => setPublishers(data))
        .catch((error) => console.log(error));
    }
  }, [selectedCongregation]);

  return (
    <div>
                      <Autocomplete
        id="congregation-select"
        options={congregations}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => setSelectedCongregation(newValue)}
        renderInput={(params) => (
          <StyledTextField {...params} label="Select Congregation" variant="outlined" />
        )}
      />
      <Autocomplete
        id="publisher-select"
        options={publishers}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        onChange={(event, newValue) => setSelectedPublisher(newValue)}
        renderInput={(params) => (
          <StyledTextField {...params} label="Select Publisher" variant="outlined" />
        )}
      />        

<Grid container spacing={2} justifyContent="center" alignItems="center">

      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>

</div>


  );
}

export default UserRoles;

// import React, { useState, useEffect } from 'react';
// // import { styled } from '@mui/material/styles';
// import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
// import { List, ListItem, ListItemText } from '@mui/material';
// import { Button, Typography } from '@mui/material';
// import Grid from '@mui/material/Grid';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import Checkbox from '@mui/material/Checkbox';
// import Paper from '@mui/material/Paper';

// // const StyledFormControl = styled(FormControl)(({ theme }) => ({
// //  margin: theme.spacing(1),
// //  minWidth: 120,
// // }));

// // const StyledButton = styled(Button)(({ theme }) => ({
// //  margin: theme.spacing(0.5, 0),
// // }));

// function Roles2() {
//  const [congregations, setCongregations] = React.useState([]);
//  const [selectedCongregation, setSelectedCongregation] = React.useState(null);
//  const [publishers, setPublishers] = React.useState([]);
//  const [selectedPublisher, setSelectedPublisher] = React.useState(null);
//  const [allRoles, setAllRoles] = React.useState([]);
//  const [userRoles, setUserRoles] = React.useState([]);

//  useEffect(() => {
//     // Fetch congregations on component mount
//     fetch('http://localhost/api/congregations')
//       .then(response => response.json())
//       .then(data => setCongregations(data))
//       .catch(error => console.log(error));
//   }, []);

//   useEffect(() => {
//     if (selectedCongregation) {
//       // Fetch publishers for selected congregation
//       fetch("http://localhost/api/publishers?congregation_id=${selectedCongregation.id}")
//         .then(response => response.json())
//         .then(data => setPublishers(data))
//         .catch(error => console.log(error));
//     }
//   }, [selectedCongregation]);

//   useEffect(() => {
//     if (selectedPublisher) {
//       // Fetch user roles for selected publisher
//       fetch("http://localhost/api/user_roles?user_id=${selectedPublisher.id}")
//         .then(response => response.json())
//         .then(data => setUserRoles(data.map(item => item.role)))
//         .catch(error => console.log(error));
//     }
//   }, [selectedPublisher]);

//   useEffect(() => {
//     // Fetch all roles on component mount
//     fetch('http://localhost/api/roles')
//       .then(response => response.json())
//       .then(data => setAllRoles(data))
//       .catch(error => console.log(error));
//   }, []);

//   const handleCongregationChange = (event) => {
//     const congregationId = event.target.value;
//     const selected = congregations.find(item => item.id  === congregationId);
//     setSelectedCongregation(selected);
//     setSelectedPublisher(null);
//     setUserRoles([]);
//   };

//   const handlePublisherChange = (event) => {
//     const publisherId = event.target.value;
//     const selected = publishers.find(item => item.id  === publisherId);
//     setSelectedPublisher(selected);
//     setUserRoles([]);
//   };

//   const handleUserRolesChange = (selectedRoles) => {
//     setUserRoles(selectedRoles);
//   };

//   const handleSubmit = () => {
//     // Submit selected roles for user to server
//     const data = {
//       user_id: selectedPublisher.id,
//       roles: userRoles
//     };
//     fetch('http://localhost/api/user_roles', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify(data)
//     })
//       .then(response => response.json())
//       .then(data => console.log(data))
//       .catch(error => console.log(error));
//   };

//  return (
//  <div>
//  <Typography variant="h5" gutterBottom>
//  User Roles
//  </Typography>
//  <Paper sx={{ p: 2 }}>
//  <Grid container spacing={2}>
//  <Grid item xs={12} sm={6}>
//  <Autocomplete
//       disablePortal
//       id="SelectCongregation"
//       options={top100Films}
//       sx={{ width: 300 }}
//       renderInput={(params) => <TextField {...params} label="Congregation" />}
//     />
//  {/* <SelectCongregation
//  congregations={congregations}
//  selectedCongregation={selectedCongregation}
//  onCongregationChange={handleCongregationChange}
//  /> */}
//  </Grid>
//  <Grid item xs={12} sm={6}>
//  <SelectUser
//  users={users}
//  selectedUser={selectedUser}
//  onUserChange={handleUserChange}
//  publishers={publishers}
//  congregationId={selectedCongregation?.id}
//  />
//  </Grid>
//  <Grid item xs={12}>
//  <RoleTransferList
//  roles={roles}
//  selectedRoles={selectedRoles}
//  onRoleChange={handleRoleChange}
//  userRoles={userRoles}
//  onSubmit={handleSubmit}
//  />
//  </Grid>
//  </Grid>
//  </Paper>
//  </div>
//  );
// };

// export default Roles2;
