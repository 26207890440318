import React, { useState, useEffect, useCallback, useRef } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { IMaskInput } from 'react-imask';

import ErrorDialog from '../../common/error-dialog';
import BackDrop from '../../common/backdrop';

import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://jwhlp.com/">
        https://jwhlp.com/
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const theme = createTheme();

export default function SignUp() {
  const { t } = useTranslation(); // i18n

  const [firstName, changeFirstName] = useState('');
  const [lastName, changeLastName] = useState('');
  const [phoneNumber, changePhoneNumber] = useState('');
  const [congregation, changeCongregation] = useState('');
  const [email, changeEmail] = useState('');
  const [password, changePassword] = useState('');

  const [firstNameValidationErr, setFirstNameValidationErr] = useState(null);
  const [lastNameValidationErr, setLastNameValidationErr] = useState(null);
  const [pnValidationErr, setPNValidationErr] = useState(null);
  const [congregationValidationErr, setCongregationValidationErr] = useState(null);
  const [emailValidationErr, setEmailValidationErr] = useState(null);
  const [passwordValidationErr, setPasswordValidationErr] = useState(null);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);

  const captchaRef = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    // Do whatever you want with the token
    console.log('token=', token);
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleFirstNameChange = event => {
    if (firstNameValidationErr) setFirstNameValidationErr(null);
    changeFirstName(event.target.value);
  };
  const handleLastNameChange = event => {
    if (lastNameValidationErr) setLastNameValidationErr(null);
    changeLastName(event.target.value);
  };
  const handlePNChange = event => {
    if (pnValidationErr) setPNValidationErr(null);
    changePhoneNumber(event.target.value);
  };
  const handleCongregationChange = event => {
    if (congregationValidationErr) setCongregationValidationErr(null);
    changeCongregation(event.target.value);
  };
  const handleEmailChange = event => {
    if (emailValidationErr) setEmailValidationErr(null);
    changeEmail(event.target.value);
  };
  const handlePasswordChange = event => {
    if (passwordValidationErr) setPasswordValidationErr(null);
    changePassword(event.target.value);
  };

  // function onCaptchaChange(value) {
  //   const token = captchaRef.current.getValue();
  //   console.log("token1=", token);
  //   console.log("Captcha value:", value);
  //   // setSubmitEnabled(true);
  //  // https://www.google.com/recaptcha/api/siteverify
  //   try {
  //     const body = {
  //       "response": token,
  //       "secret": `${process.env.REACT_APP_SITE_CAPTCHA_KEY}`,
  //       // "remoteip": phoneNumber,
  //     }

  //     const request = {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //         'Access-Control-Allow-Origin': '*',
  //         'Access-Control-Allow-Methods': 'POST'
  //       },
  //       body: JSON.stringify(body)
  //     };

  //     fetch("https://www.google.com/recaptcha/api/siteverify", request).then((response) => {
  //       console.log("Google Verification response: ", response);
  //       if (response.ok) {
  //         setBackdropOpen(false);
  //         window.location = "/waiting-room";
  //       }
  //     }).catch(err => {
  //       setError(t("Sorry! Failed to connect server =("));
  //     });
  //   } catch(error) {
  //     setError(error)
  //   }
  // }

  const handleSubmit = async () => {
    setBackdropOpen(true);

    let errors = {
      firstName: firstNameValidationErr,
      lastName: lastNameValidationErr,
      phoneNumber: pnValidationErr,
      congregation: congregationValidationErr,
      email: emailValidationErr,
      password: passwordValidationErr,
    };

    if (firstName.length === 0) errors.firstName = t('Please provide your first name.');
    else if (!/^.{2,30}$/.test(firstName)) errors.firstName = t('Allowed 2-30 symbols');

    if (lastName.length === 0) errors.lastName = t('Please provide your last name.');
    else if (!/^.{2,30}$/.test(lastName)) errors.lastName = t('Allowed 2-30 symbols');

    if (phoneNumber.length === 0)
      errors.phoneNumber = t('Please provide your phone number that connected with messenger');
    if (!/^.{10,50}$/.test(phoneNumber)) errors.phoneNumber = t('Allowed 10-50 symbols');
    // else //if (!/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/.test(phoneNumber)) errors.phoneNumber = t("Please, provide valid phone number");
    // if (!/^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/.test(phoneNumber))
    //   errors.phoneNumber = t("Please, provide valid phone number");

    if (congregation.length === 0) errors.congregation = t('Please provide your congregation name');
    // else if (congregation.length < 3) errors.congregation = t("Please provide valid congregation name");
    else if (!/^.{10,50}$/.test(congregation)) errors.congregation = t('Allowed 10-50 symbols');

    if (email.length === 0) errors.email = t('Please provide your email');
    else if (
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
      )
    )
      //if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
      errors.email = t('Please, provide valid email');

    if (password.length === 0) errors.password = t('Please provide your password');
    //if      (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,50}$/.test(password))
    // else  if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/.test(password))
    // else  if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*#?&\u0400-\u04FF]{8,}$/u.test(password))
    else if (password.length < 8 || !/\d/.test(password))
      errors.password = t('PasswordMinimumParams');
    // else

    if (
      errors.firstName ||
      errors.lastName ||
      errors.phoneNumber ||
      errors.congregation ||
      errors.email ||
      errors.password
    ) {
      setBackdropOpen(false);

      setFirstNameValidationErr(errors.firstName);
      setLastNameValidationErr(errors.lastName);
      setPNValidationErr(errors.phoneNumber);
      setCongregationValidationErr(errors.congregation);
      setEmailValidationErr(errors.email);
      setPasswordValidationErr(errors.password);

      return;
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/signup`;
      const body = {
        firstName: firstName,
        lastName: lastName,
        phones: phoneNumber,
        congregation: congregation,
        username: email,
        password: password,
        captcha: captchaRef.current.getValue(),
      };

      const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
          if (response.ok) {
            setBackdropOpen(false);
            window.location = '/waiting-room';
          }
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <BackDrop open={backdropOpen} />
      <ErrorDialog open={Boolean(error)} text={error} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign up')}
          </Typography>

          <Box onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  autoComplete="given-name"
                  fullWidth
                  label={t('First Name')}
                  error={Boolean(firstNameValidationErr)}
                  helperText={firstNameValidationErr}
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  autoComplete="family-name"
                  label={t('Last Name')}
                  error={Boolean(lastNameValidationErr)}
                  helperText={lastNameValidationErr}
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <InputLabel>{t('Phone Number')}</InputLabel>
                  <OutlinedInput
                    fullWidth
                    label={t('Phone Number')}
                    autoComplete="phone"
                    inputComponent={TextMaskCustom}
                    value={phoneNumber}
                    onChange={handlePNChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Congregation')}
                  autoComplete="congregation"
                  error={Boolean(congregationValidationErr)}
                  helperText={congregationValidationErr}
                  value={congregation}
                  onChange={handleCongregationChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Email Address')}
                  autoComplete="email"
                  error={Boolean(emailValidationErr)}
                  helperText={emailValidationErr ? emailValidationErr : t('EmailUsername')}
                  value={email}
                  onChange={handleEmailChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t('Password')}
                  type="password"
                  autoComplete="new-password"
                  error={Boolean(passwordValidationErr)}
                  helperText={passwordValidationErr}
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid item>
                <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_CAPTCHA_KEY} ref={captchaRef} />
                {/* <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_CAPTCHA_KEY} ref={captchaRef} onChange={onCaptchaChange}/> */}
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              {t('Sign up')}
            </Button>

            <Grid container justifyContent="flex-end" style={{ textAlign: 'right' }}>
              <Grid item xs={12}>
                <Link href="/sign-up-congregation" variant="body2">
                  {t('Sign-Up-Congregation')}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Link href="/sign-in" variant="body2">
                  {t('Already have an account? Sign in')}
                </Link>
              </Grid>

              <Grid item xs={12}>
                <Link href="/contact-devs" variant="body2">
                  {t('Contact Developers')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
