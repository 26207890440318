import * as React from 'react';
import { useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { IMaskInput } from 'react-imask';

import ErrorDialog from '../common/error-dialog';
import BackDrop from '../common/backdrop';
import Snackbar from '../common/snackbar';

import { useTranslation } from 'react-i18next';
import { parseJwt } from '../common/parseJwt';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CustomDisableInput = styled(TextField)(() => ({
  '.MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000',
    color: '#000',
  },
}));

function CreateUser() {
  const { t } = useTranslation(); // i18n

  const jwtData = parseJwt(localStorage.jwt);
  const canEdit = jwtData.permissions.find(role => role === 'users_edit') !== undefined;
  if (!canEdit) window.location = '/users';

  const [email, changeEmail] = useState('');
  const [name, changeName] = useState('');
  const [surname, changeSurname] = useState('');
  const [password, changePassword] = useState('');
  const [phoneNumbers, changePhoneNumbers] = useState(['']);
  const [isAllowed4Stand, setIsAllowed4Stand] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [error, setError] = useState(null);

  const handleEmailChange = event => changeEmail(event.target.value);
  const handleNameChange = event => changeName(event.target.value);
  const handleSurnameChange = event => changeSurname(event.target.value);
  const handlePasswordChange = event => changePassword(event.target.value);
  const handleSetIsAllowed4Stand = () => setIsAllowed4Stand(!isAllowed4Stand);
  const handleSetIsComfirned = () => setIsConfirmed(!isConfirmed);
  const handlePNChange = (event, idx) => {
    const newPNs = phoneNumbers.map((pn, i) => {
      if (idx === i) pn = event.target.value;

      return pn;
    });

    changePhoneNumbers(newPNs);
  };

  const editPNs = i => {
    let newPNs = phoneNumbers;
    if (i === 0) newPNs.push('');
    else newPNs.splice(i, 1);

    changePhoneNumbers([...newPNs]);
  };

  const confirm = async () => {
    setBackdropOpen(true);

    // name surname phoneNumbers
    try {
      const url = `${process.env.REACT_APP_API_URL}/users`;
      const body = {
        email: email,
        firstName: name,
        lastName: surname,
        password: password,
        phones: phoneNumbers.join(';'),
        isAllowed4Stand: isAllowed4Stand,
        active: isConfirmed,
      };
      const request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.status === 400) {
            return response.json();
          } else if (response.ok) {
            setBackdropOpen(false);
            setSnackbar(true);
            return response.json();
          }
        })
        .then(data => {
          if (data.status === 'error') {
            console.log(data);

            if (data.message.startsWith('Duplicate entry')) {
              setError(data.message);
              // return window.location = `${process.env.REACT_APP_URL}/add-user`;
            }
          }
        })
        .catch(err => {
          console.error('err=', err);
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const goBack = () => window.history.back();
  const hideSnackbar = () => setSnackbar(false);

  if (error) return <ErrorDialog open={Boolean(error)} text={error} />;
  return (
    <Grid container style={{ width: '100%', padding: '32px', margin: '0px' }}>
      <BackDrop open={backdropOpen} />
      <Snackbar
        type={'success'}
        msg={'Successfuly updated profile data!'}
        open={snackbar}
        hideSnackbar={() => hideSnackbar()}
      />

      <Grid item xs={0} md={3}></Grid>

      <Grid container item xs={12} md={6}>
        <Paper elevation={5} style={{ width: '100%', padding: '12px', backgroundColor: '#FAFAFA' }}>
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom component="div" align="center">
              <AccountCircleIcon style={{ fontSize: '64px' }} />
              <br />
              {t('NewUser')}
            </Typography>
          </Grid>

          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12}>
              <CustomDisableInput
                label={t('Email Address')}
                variant="outlined"
                style={{ width: '100%' }}
                value={email}
                onChange={handleEmailChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomDisableInput
                label={t('First Name')}
                variant="outlined"
                style={{ width: '100%' }}
                value={name}
                onChange={handleNameChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CustomDisableInput
                label={t('Last Name')}
                variant="outlined"
                style={{ width: '100%' }}
                value={surname}
                onChange={handleSurnameChange}
              />
            </Grid>

            <Grid item xs={12}>
              <CustomDisableInput
                label={t('Password')}
                variant="outlined"
                style={{ width: '100%' }}
                type="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} item xs={12} style={{ paddingTop: '24px' }}>
            {phoneNumbers.map((pn, i) => {
              return (
                <Grid item xs={12} style={{ display: 'flex' }} key={i}>
                  <FormControl style={{ width: '100%' }}>
                    <CustomDisableInput
                      inputComponent={TextMaskCustom}
                      label={t('Phone Number')}
                      value={pn}
                      onChange={e => handlePNChange(e, i)}
                    />
                  </FormControl>

                  <IconButton size="small" onClick={() => editPNs(i)}>
                    {i === 0 ? <AddCircleIcon /> : <RemoveCircleIcon />}
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>

          <Grid
            container
            spacing={3}
            item
            xs={12}
            style={{ paddingTop: '24px', paddingLeft: '24px' }}
          >
            <FormControlLabel
              control={<Checkbox checked={isAllowed4Stand} onChange={handleSetIsAllowed4Stand} />}
              label={t('Is Allowed for Stand')}
            />
            <FormControlLabel
              control={<Checkbox checked={isConfirmed} onChange={handleSetIsComfirned} />}
              label={t('Is comfirmed user')}
            />
          </Grid>

          <Grid container spacing={3} item xs={12} style={{ paddingTop: '12px' }}>
            <Grid item xs={12} md={6}>
              <Button variant="contained" style={{ width: '100%' }} onClick={goBack}>
                {t('Back')}
              </Button>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button variant="contained" style={{ width: '100%' }} onClick={confirm}>
                {t('Confirm')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={0} md={3}></Grid>
    </Grid>
  );
}

export default CreateUser;
