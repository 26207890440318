import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ErrorState, Paths, ResponseData } from 'hooks';

export const useAll = <T extends ResponseData[]>(path: Paths) => {
  const url = `${process.env.REACT_APP_API_URL}${path}`;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<ErrorState>(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);

      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) {
            navigate('/sign-in');
          } else if (response.ok) {
            return response.json();
          } else {
            setError(`${response.status} ${response.statusText}`);
          }
        })
        .then(data => {
          setData(data);
        })
        .catch(() => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return { data, error, loading, refetch: fetchData };
};
