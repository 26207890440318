import * as React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

function WaitingRoom() {
  const { t } = useTranslation(); // i18n

  return (
    <Grid container style={{ padding: '64px' }}>
      <Grid>
        <Typography variant="h3">{t('Sign up explanation')}</Typography>
      </Grid>
    </Grid>
  );
}

export default WaitingRoom;
