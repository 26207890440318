import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  useRoutes,
  // useLocation
} from 'react-router-dom';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { NavBar } from './components';
import SignUp from './components/authorisation/sign-up/sign-up';
import SignUpCongr from './components/authorisation/sign-up/sign-up-congr';
import SignIn from './components/authorisation/sign-in/sign-in';
import Stand from './components/home/stand.jsx';
import Profile from './components/profile/profile.jsx';
// import NotFound from './components/not-found.jsx';
// import Table from './components/home/table.jsx';
//import TableOld from './components/home/2del_tableOld';
import StandOverview from './components/home/stand-overview';
import ReportMyMinistry from './components/reports/report-my-ministry';
import Admin from './components/users/users.jsx';
import SignInViaGuid from './components/authorisation/guid';
import User from './components/users/user-profile';
import WaitingRoom from './components/waiting-room';
import ContactDevs from './components/contact-devs';
import AddUser from './components/users/add-user';
import { GoogleOAuthProvider } from '@react-oauth/google';

import './App.css';
import TSTableN1 from './components/teoschool/TSTableN1';
// import TSTableN2 from "./components/teoschool/TSTableN2";
import UserRoles from './pages/UserRoles';
import { Territories } from './pages/Territories';
import { TerritoriesHistory } from './pages/TerritoriesHistory';
import { Territory } from './pages/Territory';
import { TerritoryCreate } from './pages/TerritoryCreate';
import { SnackbarProvider } from 'components';
import { territoryTableFilter } from 'constant';

const App = () => {
  const [territoryFilter, setTerritoryFilter] = useState(territoryTableFilter[0]);

  let routes = useRoutes([
    { path: '/sign-up', element: <SignUp /> },
    { path: '/sign-in', element: <SignIn /> },
    { path: '/sign-up-congregation', element: <SignUpCongr /> },
    { path: '/waiting-room', element: <WaitingRoom /> },
    { path: '/guid', element: <SignInViaGuid /> },

    { path: '/stand', element: <Stand /> },
    // { path: '/table', element: <Table /> },
    { path: '/table', element: <StandOverview /> },
    { path: '/report-my-ministry', element: <ReportMyMinistry /> },
    { path: '/profile', element: <Profile /> },

    { path: '/users', element: <Admin /> },
    { path: '/user', element: <User /> },
    { path: '/add-user', element: <AddUser /> },

    { path: '/contact-devs', element: <ContactDevs /> },

    { path: '/ts1', element: <TSTableN1 /> },
    { path: '/userroles', element: <UserRoles /> },
    // { path: "/ts2", element: <TSTableN2 /> },
    // { path: "/tr1", element: <TerritoriesHelper /> },

    {
      path: '/territories',
      element: (
        <Territories territoryFilter={territoryFilter} setTerritoryFilter={setTerritoryFilter} />
      ),
    },
    { path: '/territories/history', element: <TerritoriesHistory /> },
    { path: '/territory/:id', element: <Territory /> },
    { path: '/territory/create', element: <TerritoryCreate /> },

    { path: '/', element: <Navigate to="stand" /> },
    { path: '*', element: <Navigate to="stand" /> },
  ]);

  return routes;
};

const AppWrapper = () => {
  useEffect(() => {
    const lang = localStorage.lang;

    document.documentElement.lang = lang;
    document.title = 'JW Helper';
  }, []);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_RE_CAPTCHA_KEY}
        useRecaptchaNet="false"
        useEnterprise="false"
      >
        <main className="container">
          <Router>
            <SnackbarProvider>
              <NavBar />
              <App />
            </SnackbarProvider>
          </Router>
        </main>
      </GoogleReCaptchaProvider>
    </GoogleOAuthProvider>
  );
};

export default AppWrapper;
