import { getJwtData, isPermissionsIncludes } from 'helpers';
import { IconsNames } from 'legos/Icon';
import { TerritoryData, TerritoryFields, UserPermissions } from 'types';

const jwtData = getJwtData();

export type TerritoryFilterData = {
  value: string;
  eq: (territory: TerritoryData) => boolean;
  sort: (a: TerritoryData, b: TerritoryData) => number;
  icon?: IconsNames;
};

export const territoryTableFilter: TerritoryFilterData[] = [
  {
    value: 'my_territories',
    eq: (territory: TerritoryData) => territory[TerritoryFields.UserID] === jwtData.id,
    sort: (a: TerritoryData, b: TerritoryData) =>
      new Date(a[TerritoryFields.StartAt] ?? '').getTime() -
      new Date(b[TerritoryFields.StartAt] ?? '').getTime(),
  },
  {
    value: 'free',
    eq: (territory: TerritoryData) =>
      !territory[TerritoryFields.UserID] && !territory[TerritoryFields.StartAt],
    sort: (a: TerritoryData, b: TerritoryData) =>
      new Date(a[TerritoryFields.FinishAt] ?? '').getTime() -
      new Date(b[TerritoryFields.FinishAt] ?? '').getTime(),
  },
];

if (
  isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
  isPermissionsIncludes(UserPermissions.TerritoryAssist)
) {
  territoryTableFilter.push({
    value: 'processed',
    eq: (territory: TerritoryData) =>
      !!territory[TerritoryFields.UserID] && !!territory[TerritoryFields.StartAt],
    sort: (a: TerritoryData, b: TerritoryData) =>
      new Date(a[TerritoryFields.StartAt] ?? '').getTime() -
      new Date(b[TerritoryFields.StartAt] ?? '').getTime(),
  });
}

territoryTableFilter.push({
  value: 'favorite',
  eq: () => false,
  sort: (a: TerritoryData, b: TerritoryData) =>
    new Date(a[TerritoryFields.FinishAt] ?? '').getTime() -
    new Date(b[TerritoryFields.FinishAt] ?? '').getTime(),
  icon: 'favorite',
});
