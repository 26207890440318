import { useState, useEffect, ChangeEvent } from 'react';

type Values = Record<string, unknown>;
export type FormDataErrors<T> = Partial<Record<keyof T, string>>;
type ValidateFunction<T> = (values: T) => FormDataErrors<T>;

export const useFormData = <T extends Values>(
  initialValues: T,
  data?: T | null,
  validate?: ValidateFunction<T>
) => {
  const [values, setValues] = useState<T>(initialValues);
  const errors: FormDataErrors<T> = validate ? validate(values) : {};

  useEffect(() => {
    if (data)
      setValues(prevValues => {
        const updatedValues = { ...prevValues };

        Object.entries(updatedValues).forEach(([name]) => {
          updatedValues[name as keyof T] =
            data[name as keyof T] ?? initialValues[name as keyof T] ?? ('' as T[keyof T]);
        });

        return updatedValues;
      });
  }, [data, initialValues]);

  const setValue = (name: keyof T, value: T[keyof T]) => {
    setValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues(prevValues => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  return {
    values,
    errors,
    setValue,
    handleChange,
  };
};
