import { Scalars, UserPermissions } from 'types';

type JWTData = {
  id?: Scalars['Int'];
  permissions?: UserPermissions[];
  isAllowed4Stand?: Scalars['Switch'];
};

export const getJwtData = (): JWTData => {
  if (!localStorage) {
    console.log('-------------localStorage is not available')
    return {};
  }
  if (!localStorage.jwt) {
    console.log('--------------JWT token is not found in local storage')
    return {}; 
  }
  if (!localStorage.jwt) return {};

  
  const base64Url = localStorage.jwt.split('.')[1];
  
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};
