import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import TSSelect5 from './TSSelect5';
import TSEdit from './TSEdit';
import UserCard from './UserCard';

//import axios from "axios";
// import API from "../utils/API";

import { useTranslation } from 'react-i18next';
//import { withTranslate, useDataProvider, Loading, Error } from "react-admin";

//import { withStyles, createMuiTheme, MuiThemeProvider } from "@mui/styles";
import { addMonths, addDays, setDay, isBefore /*, getMonth*/ } from 'date-fns';
// import _ from "lodash";

import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MUIDataTable from 'mui-datatables';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// import { useDataProvider, Loading, Error } from "react-admin";
// import { useNotify, useRedirect, fetchStart, fetchEnd, Button } from "react-admin";
// import { useDispatch } from "react-redux";

const styles = theme => ({
  leftSideDrawer: {
    width: 'auto',
  },
  formControl: {
    margin: theme.spacing(),
  },
  meetingSection1: {
    background: '#606a70  ', //"linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)"
    width: '100%',
    color: 'white',
    padding: '5px',
  },
  meetingSection2: {
    background: '#BE8900',
    width: '100%',
    color: 'white',
    padding: '5px',
  },
  meetingSection3: {
    background: '#7E0024',
    width: '100%',
    color: 'white',
    padding: '5px',
  },
  meetingSection4: {
    background: '#4287f5',
    width: '100%',
    color: 'white',
    padding: '5px',
  },
  meetingWT1: {
    background: '#5B3256',
    width: '100%',
    color: 'white',
    padding: '5px',
  },
  meetingWT2: {
    background: '#875F9A',
    width: '100%',
    color: 'white',
    padding: '5px',
  },
});

const onlyMicrophones = [28, 29, 35, 36, 34];
const onlyEntrance = [30, 31, 32, 33, 34];
const simpleTasks = [17, 38];
const tasksWithPartner = [2, 4, 6, 8, 20, 22, 24, 26];
const assistantTasks = [3, 5, 7, 9, 21, 23, 25, 27];
// const sundayTasks = [33, 34, 35, 36, 37, 38, 39, 40];

const TICK = '\u2713';
const CROSS = '\u2715';
const SMALL_SCREEN = 500;
// function getTuesdays() {
//   let start = getClosestDayOfWeek("Tue", addMonths(new Date(), -2));
//   let end = getClosestDayOfWeek("Tue", addMonths(new Date(), 2));

//   let result = [];
//   while (isBefore((start = addDays(start, 7)), end)) {
//     result.push(start);
//   }
//   // console.log("getTuesday99", result.map(m => m));
//   return result;
// }

// function ISO8601_week_no(dt) {
//   var tdt = new Date(dt.valueOf());
//   var dayn = (dt.getDay() + 6) % 7;
//   tdt.setDate(tdt.getDate() - dayn + 3);
//   var firstThursday = tdt.valueOf();
//   tdt.setMonth(0, 1);
//   if (tdt.getDay() !== 4) {
//     tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
//   }
//   return 1 + Math.ceil((firstThursday - tdt) / 604800000);
// }

function getWeeksDates() {
  let start = setDay(addMonths(new Date(), -2), 1, { weekStartsOn: 1 }); // get Monday 2 monthes ago
  let end = setDay(addMonths(new Date(), 3), 1, { weekStartsOn: 1 }); // get Monday 3 monthes in future
  // let start = getClosestDayOfWeek("Mon", addMonths(new Date(), -2));
  // let end = getClosestDayOfWeek("Mon", addMonths(new Date(), 3));

  let result = [];
  while (isBefore((start = addDays(start, 7)), end)) {
    const endOfTheWeek = addDays(start, 6);
    result.push({ startOfTheWeek: start, endOfTheWeek: endOfTheWeek });
    // result.push(start);
  }
  // result.forEach(m => {
  //   console.log(
  //     "getWeeksDates99",
  //     m,
  //     ISO8601_week_no(m.startOfTheWeek),
  //     ISO8601_week_no(m.endOfTheWeek)
  //   );
  // });
  // console.log(
  //   "getWeeksDates99",
  //   result.map(m => m)
  // );
  return result;
}

/* usage console.log(getClosestDayOfWeek('Mon')); */
function getClosestDayOfWeek(dayOfWeek, fromDate = new Date()) {
  const dayOfWeekMap = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };
  return setDay(fromDate, dayOfWeekMap[dayOfWeek], { weekStartsOn: 1 });
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// function getNextMonday() {
//   let d = new Date();
//   d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7));
//   // console.log("getNextMonday", d.toISOString());
//   return d;
// }

// console.log("TSTable.fromCI_cid", window.fromCI_cid);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// const dispatch = useDispatch();
// const notify = useNotify();
const TSTable = props => {
  const { t } = useTranslation(); // i18n
  // const dataProvider = useDataProvider();
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState();

  const permissionGroupsArray = [];
  permissionGroupsArray.push('TSAttendantsEditor');
  permissionGroupsArray.push('TSEditor'); // replace everywhere to TSPart2Editor
  permissionGroupsArray.push('TSPart1Editor');
  permissionGroupsArray.push('TSPart2Editor');
  permissionGroupsArray.push('TSPart3Editor');
  permissionGroupsArray.push('WTMeetingEditor');

  const [permissionGroups, setPermissionGroups] = useState(permissionGroupsArray);
  const [tab, setTab] = useState(0);
  const [left, setLeft] = useState(false); // drawer
  // const [ts_day, setTSDay] = useState("Tue");
  const [tsDate, setTSDate] = useState(getClosestDayOfWeek('Mon').toISOString().slice(0, 10));
  const [tsTasks, setTSTasks] = useState([]);
  const [sunTalkInfo, setSunTalkInfo] = useState({ title: '', full_name: '' });
  const [prayerIsChairman, setPrayerIsChairman] = useState(false);
  const [candidates4TSTask, setCandidates4TSTask] = useState([]);
  const [tsCandidatesFulfiledTasks, setTSCandidatesFulfiledTasks] = useState([]);
  const [candidatesFiltered, setCandidatesFiltered] = useState([]);
  const [currentControl, setCurrentControl] = useState({ assignment_id: 1 });
  const [userId2Edit, setUserId2Edit] = useState(-1);
  const [openUserEditDialog, setOpenUserEditDialog] = useState(false);

  // let columnsUsed = [];

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'lastName',
      label: t('userCard.lastName'),
      searchable: true,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'firstName',
      label: t('userCard.firstName'),
      searchable: true,
      options: {
        display: true,
        filter: false,
        sort: true,
      },
    },
    {
      name: 'fullName',
      label: t('userCard.fullName'),
      searchable: true,
      options: {
        display: false,
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log("fullName", value, tableMeta);
          return (
            <span>
              {tableMeta.rowData[1] /* lastName */} {tableMeta.rowData[2] /* firstName */}
            </span>
          );
        },
      },
    },
    {
      name: 'lastDate',
      label: t('muitable.lastDate'),
      options: {
        hint: t('muitable.lastDateHint'),
        filter: false,
        sort: true,
        display: false,
        sortDirection: 'asc',
      },
    },
    {
      name: 'previous_assighments',
      label: t('muitable.previousAssighments'),
      options: {
        hint: t('muitable.previousAssighmentsHint'),
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log("previous_assighments", value);
          return (
            <small>
              {Array.isArray(value) ? value.map((item, idx) => <div key={idx}>{item}</div>) : value}
            </small>
            // <small>
            //   {value
            //     ? value
            //         .split("|")
            //         .map((item, idx) => <div key={idx}>{item}</div>)
            //     : ""}
            // </small>
          );
        },
      },
    },
    {
      name: 'rank',
      label: t('userCard.rank'),
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    // {
    //   name: "isBrother",
    //   label: t("userCard.isBrother"),
    //   options: {
    //     hint: t("userCard.isBrotherHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isBrother")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isAssistant",
    //   label: t("userCard.isAssistant"),
    //   options: {
    //     hint: t("userCard.isAssistantHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isAssistant")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isMainClass",
    //   label: t("userCard.isMainClass"),
    //   options: {
    //     hint: t("userCard.isMainClassHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isMainClass")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isAuxClass",
    //   label: t("userCard.isAuxClass"),
    //   options: {
    //     hint: t("userCard.isAuxClassHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isAuxClass")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isBibleReading",
    //   label: t("userCard.isBibleReading"),
    //   options: {
    //     hint: t("userCard.isBibleReadingHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isBibleReading")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isInitialCall",
    //   label: t("userCard.isInitialCall"),
    //   options: {
    //     hint: t("userCard.isInitialCallHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isInitialCall")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isReturnVisit",
    //   label: t("userCard.isReturnVisit"),
    //   options: {
    //     hint: t("userCard.isReturnVisitHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isReturnVisit")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isBibleStudy",
    //   label: t("userCard.isBibleStudy"),
    //   options: {
    //     hint: t("userCard.isBibleStudyHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isBibleStudy")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isTalk5m",
    //   label: t("userCard.isTalk5m"),
    //   options: {
    //     hint: t("userCard.isTalk5mHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isTalk5m")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value > 0 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isLiving1",
    //   label: t("userCard.isLiving1"),
    //   options: {
    //     hint: t("userCard.isLiving1Hint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isLiving1")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value > 0 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isLiving2",
    //   label: t("userCard.isLiving2"),
    //   options: {
    //     hint: t("userCard.isLiving2Hint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isLiving2")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value > 0 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isCBSChairman",
    //   label: t("userCard.isCBSChairman"),
    //   options: {
    //     hint: t("userCard.isCBSChairmanHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isCBSChairman")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isCBSReader",
    //   label: t("userCard.isCBSReader"),
    //   options: {
    //     hint: t("userCard.isCBSReaderHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isCBSReader")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isChairman",
    //   label: t("userCard.isChairman"),
    //   options: {
    //     hint: t("userCard.isChairmanHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isChairman")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isDiggingGems",
    //   label: t("userCard.isTreasureDig"),
    //   options: {
    //     hint: t("userCard.isTreasureDigHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isTreasureDig")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isPrayer",
    //   label: t("userCard.isPrayer"),
    //   options: {
    //     hint: t("userCard.isPrayerHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isPrayer")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isTreasureTalk",
    //   label: t("userCard.isTreasureTalk"),
    //   options: {
    //     hint: t("userCard.isTreasureTalkHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isTreasureTalk")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isSunChariman",
    //   label: t("userCard.isSunChariman"),
    //   options: {
    //     hint: t("userCard.isSunCharimanHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isSunChariman")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isSunTalk",
    //   label: t("userCard.isSunTalk"),
    //   options: {
    //     hint: t("userCard.isSunTalkHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isSunTalk")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isWT",
    //   label: t("userCard.isWT"),
    //   options: {
    //     hint: t("userCard.isWTHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isWT")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isWTReader",
    //   label: t("userCard.isWTReader"),
    //   options: {
    //     hint: t("userCard.isWTReaderHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isWTReader")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isUse4fillins",
    //   label: t("userCard.isUse4fillins"),
    //   options: {
    //     hint: t("userCard.isUse4fillinsHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isUse4fillins")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isMicrophoneSrvc",
    //   label: t("userCard.isMicrophoneSrvc"),
    //   options: {
    //     hint: t("userCard.isMicrophoneSrvcHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isMicrophoneSrvc")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    // {
    //   name: "isEntranceGuard",
    //   label: t("userCard.isEntranceGuard"),
    //   options: {
    //     hint: t("userCard.isEntranceGuardHint"),
    //     filter: true,
    //     filterType: "checkbox",
    //     customFilterListOptions: v => `${t("userCard.isEntranceGuard")} ${v}`,
    //     sort: true,
    //     display: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (value === 1 ? TICK : CROSS)
    //   }
    // },
    {
      name: 'edit',
      label: t('muitable.edit'),
      empty: true,
      options: {
        hint: t('muitable.editHint'),
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton
              aria-label="delete"
              onClick={() => handleEditUserDialogOpen(tableMeta.rowData[0])}
            >
              <EditIcon />
            </IconButton>
          );
        },
      },
    },
  ];
  let columnsUsed = columns;
  // Аналогично componentDidMount и componentDidUpdate:
  useEffect(() => {
    console.log('useEffect');
    getCandidates4TSTask();
    getMeeting4Date(tsDate);
  }, [tsDate]);

  const getCandidates4TSTask = async assignment_id => {
    console.log('getCandidates4TSTask');
    // fetch(process.env.REACT_APP_API_URL + "/tsGetPersons4Datatable", { method: "GET" })
    //   .then(function(json) {
    //     console.log("candidates4TSTask.tsGetPersons4Datatable.json:", JSON.stringify(json.json()));
    //   })
    //   // .then(response => response.json())
    //   .then(response => {
    //     const json = response.json();
    //     console.log("candidates4TSTask.tsGetPersons4Datatable.candidates4TSTask:", json);
    //     this.setState({ candidates4TSTask: json });
    //     // redirect("/comments");
    //   })
    //   .catch(e => {
    //     console.log("Error: tsGetPersons4Datatable #1 was not succeed", "warning");
    //   })
    //   .finally(() => {
    //     // setLoading(false);
    //     // dispatch(fetchEnd()); // stop the global loading indicator
    //   });

    // fetch(process.env.REACT_APP_API_URL + "/tsGetTSTasks4Datatable", { method: "GET" })
    //   .then(function(json) {
    //     console.log("candidates4TSTask.tsGetTSTasks4Datatable.json:", JSON.stringify(json.json()));
    //   })
    //   .then(response => {
    //     const json = response.json();
    //     console.log("candidates4TSTask.tsGetTSTasks4Datatable.tsCandidatesFulfiledTasks:", json);
    //     this.setState({ tsCandidatesFulfiledTasks: json });
    //     // redirect("/comments");
    //   })
    //   .catch(e => {
    //     console.log("Error: tsGetPersons4Datatable #2 was not succeed", "warning");
    //   })
    //   .finally(() => {
    //     // setLoading(false);
    //     // dispatch(fetchEnd()); // stop the global loading indicator
    //   });

    // const { data: candidates4TSTask1 } = await API.get(
    //   process.env.REACT_APP_API_URL + "/tsGetPersons4Datatable"
    // );
    // const { data: candidates4TSTask1 } = await fetch(
    const response1 = await fetch(process.env.REACT_APP_API_URL + '/tsGetPersons4Datatable', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.jwt}`,
      },
    });
    const candidates4TSTask1 = await response1.json();
    console.log('candidates4TSTask1', candidates4TSTask1);
    setCandidates4TSTask(candidates4TSTask1);

    // const { data: tsCandidatesFulfiledTasks1 } = await fetch(
    const response2 = await fetch(process.env.REACT_APP_API_URL + '/tsGetTSTasks4Datatable', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.jwt}`,
      },
    });
    const tsCandidatesFulfiledTasks1 = await response2.json();
    console.log('tsCandidatesFulfiledTasks1', tsCandidatesFulfiledTasks1);

    // console.log("candidates4TSTask:", candidates4TSTask);
    // console.log("tsCandidatesFulfiledTasks:", tsCandidatesFulfiledTasks);
    setTSCandidatesFulfiledTasks(tsCandidatesFulfiledTasks1);
  };

  const getMeeting4Date = async tsDate1 => {
    // fetch(
    //   process.env.REACT_APP_API_URL + `/tsDBHelperGetMeeting4Date_forTEST?weekstart=${tsDate}`,
    //   { method: "GET" }
    // )
    //   // .then(function(json) {
    //   //   console.log("getMeeting4Date.tsTasks.json:", JSON.stringify(json.json()));
    //   // })
    //   .then(response => {
    //     const json = response.json();
    //     console.log("getMeeting4Date.tsDBHelperGetMeeting4Date_forTEST.tsTasks:", json);
    //     this.setState({ tsTasks: json });
    //     // redirect("/comments");
    //   })
    //   .catch(e => {
    //     console.log("Error: getMeeting4Date #1 was not succeed", "warning");
    //   })
    //   .finally(() => {
    //     // setLoading(false);
    //     // dispatch(fetchEnd()); // stop the global loading indicator
    //   });

    // fetch(process.env.REACT_APP_API_URL + "/tsDBHelperSundayTalkInfo", {
    //   method: "GET",
    //   week_start: tsDate
    // })
    //   // .then(function(json) {
    //   //   console.log("getMeeting4Date.tsTasks.json:", JSON.stringify(json.json()));
    //   // })
    //   .then(response => {
    //     const json = response.json();
    //     console.log("getMeeting4Date.tsDBHelperSundayTalkInfo.sunTalkInfo:", json);
    //     this.setState({ sunTalkInfo: json });
    //     // redirect("/comments");
    //   })
    //   .catch(e => {
    //     console.log("Error: getMeeting4Date #2 was not succeed", "warning");
    //   })
    //   .finally(() => {
    //     // setLoading(false);
    //     // dispatch(fetchEnd()); // stop the global loading indicator
    //   });

    // console.log("getMeeting4Date.tsDate:", tsDate);

    let url =
      process.env.REACT_APP_API_URL + '/tsDBHelperGetMeeting4Date_forTEST?weekstart=' + tsDate1;

    // const { data: tsTasks1 } = await fetch(url,{
    const response1 = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.jwt}`,
      },
    });
    const tsTasks1 = await response1.json();
    console.log('tsTasks1', tsTasks1);
    setTSTasks(tsTasks1);

    // let { data: sunTalkInfo1 } =
    const response2 = await fetch(
      process.env.REACT_APP_API_URL + '/tsDBHelperSundayTalkInfo?week_start=' + tsDate1,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      }
    );
    let sunTalkInfo1 = await response2.json();
    console.log('sunTalkInfo1', sunTalkInfo1);

    console.log('getMeeting4Date.sunTalkInfo:', sunTalkInfo1, tsDate1);
    if (!sunTalkInfo1) {
      sunTalkInfo1 = { title: '', full_name: '' };
    }
    setSunTalkInfo(sunTalkInfo1);
    setTSDate(tsDate1);

    // console.log("getMeeting4Date.tsTasks:", tsTasks);
  };

  const tsUpdateTask = async tsTask => {
    const updateBody = {
      user_id: tsTask.user_id,
      assignment_id: tsTask.assignment_id,
      week_start: tsTask.week_start,
    };
    // console.log("tsUpdateTask.updateBody", updateBody, tsTask);

    let url = process.env.REACT_APP_API_URL + '/tsDBHelperChangeTask_forTEST';

    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(updateBody),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const json = await response.json();
      console.log('Success:', JSON.stringify(json));
      tsTask.ts_id = response.data;
    } catch (error) {
      console.error('Ошибка:', error);
    }
    // await API.put(url, updateBody)
    //   .then(function(response) {
    //     // handle success
    //     tsTask.ts_id = response.data;

    //     // console.log(tsTask);
    //   })
    //   .catch(function(error) {
    //     // handle error
    //     // console.log(error);
    //   });
  };

  const tsDeleteTask = async ts_id => {
    // console.log("tsDeleteTask", ts_id);
    let url = process.env.REACT_APP_API_URL + '/tsDBHelperChangeTask_forTEST?ts_id=' + ts_id;

    // await API.delete(url);
    try {
      const response = await fetch(url, { method: 'DELETE' });
      const json = await response.json();
      console.log('Success:', JSON.stringify(json));
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  const toggleDrawer = (side, open) => () => {
    // console.log("toggleDrawer", side, open);
    setLeft(open);
  };

  // handleOnTSSelect5AttendantsClick = (event, tsTask) => {
  //   // console.log("handleOnTSSelect5AttendantsClick currentControl1", currentControl);
  //   // console.log("handleOnTSSelect5AttendantsClick", tsTask, event);

  //   columnsUsed = columns;
  //   columnsUsed.forEach(col => {
  //     if (col.name === "isEntranceGuard" || col.name === "isMicrophoneSrvc") {
  //       col.options.display = true;
  //       col.options.filterList = [TICK];
  //     } else if (col.name.startsWith("is")) {
  //       col.options.display = false;
  //       col.options.filterList = [];
  //     }
  //   });

  //   const candidatesFiltered = filterOutParticipants(
  //     candidates4Attendants,
  //     tsTasks
  //   );

  //   this.setState({
  //     left: true,
  //     currentControl: tsTask,
  //     candidatesFiltered
  //   });
  // };

  const handleOnTSSelect5Click = (mode, tsTask1) => {
    // console.log("handleOnTSSelect5Click currentControl1", currentControl);
    // console.log("handleOnTSSelect5Click", tsTask1, mode);

    /* hide all "is..." coluns and clear out all filters */
    columnsUsed = columns;
    console.log('#916 columnsUsed', columnsUsed, mode, tsTask1);
    columnsUsed.forEach(col => {
      if (col.name.startsWith('is')) {
        col.options.display = false;
        col.options.filterList = [];
      }
    });
    console.log('#923 columnsUsed', columnsUsed);

    // console.log("handleOnTSSelect5Click.assignment_id=", tsTask1.assignment_id);
    let candidatesFiltered1 = filterOutParticipants(
      candidates4TSTask,
      tsTasks,
      tsTask1.assignment_id,
      mode
    );
    // console.log("#930candidatesFiltered1=", candidatesFiltered1);

    setLeft(true);
    setCurrentControl(tsTask1);
    setCandidatesFiltered(candidatesFiltered1);
  };

  const handleTSDateChange = event => {
    setTSDate(event.target.value); // it will trigger useEffect which will call getMeeting4Date
    // getMeeting4Date(event.target.value);
  };

  const handleChangeSelect = (event, tsTask) => {
    console.log('handleChangeSelect', tsTask, event.target.value);
    if (event && event.value >= 0) {
      // console.log("handleChangeSelect2", event.value);
      tsTask.user_id = event.value;
    } else {
      // console.log("handleChangeSelect3");
      tsTask.user_id = -1;
      tsTask.full_name = '';
    }
    changeAssignee(tsTask);
  };

  const handleEditUserDialogOpen = userId2Edit => {
    // console.log("handleEditUserDialogOpen.userId", userId2Edit);
    setUserId2Edit(userId2Edit);
    setOpenUserEditDialog(true);
  };

  const getGenderOfPartnerIsBrother = assignment_id => {
    let genderOfPartnerIsBrother = undefined;
    /* find partner's gender if it is TS task */
    if (tasksWithPartner.includes(assignment_id)) {
      const task = tsTasks.find(task => {
        return task.assignment_id === assignment_id + 1 && task.user_id > 0;
      });
      if (task !== undefined) {
        genderOfPartnerIsBrother = task.isBrother;
      }
    } else if (assistantTasks.includes(assignment_id)) {
      const task = tsTasks.find(task => {
        return task.assignment_id === assignment_id - 1 && task.user_id > 0;
      });
      if (task !== undefined) {
        genderOfPartnerIsBrother = task.isBrother;
      }
    }
    return genderOfPartnerIsBrother;
  };

  const handleEditUserDialogClose = () => {
    // console.log("handleEditUserDialogClose.");
    setOpenUserEditDialog(false);
  };

  const filterOutParticipants = (
    candidates4TSTask,
    tsTasks,
    assignment_id,
    mode = 'OnlyNotUsed'
  ) => {
    let participants = new Set();

    /* mode "SearchAll" - shows all available users from congregation;
     * "OnlyNotUsed" - only those who are not engaged in current meeting yet */
    if (mode !== 'SearchAll') {
      participants = findAllParticipants(assignment_id, tsTasks);
    }
    console.log('filterOutParticipants.participants:', participants);
    console.log('filterOutParticipants.assignment_id:', assignment_id);

    let is4BrothersOnly = 1;

    // console.log("Width: " + window.innerWidth);
    columnsUsed.forEach(c => {
      if (window.innerWidth > SMALL_SCREEN) {
        c.options.display =
          c.name === 'firstName' ||
          c.name === 'lastName' ||
          c.name === 'previous_assighments' ||
          c.name === 'edit'
            ? true
            : false;
      } else {
        c.options.display =
          c.name === 'fullName' || c.name === 'previous_assighments' ? true : false;
      }
    });
    console.log('#1022 columnsUsed', columnsUsed);

    switch (assignment_id) {
      case 1 /*label.assignment.bible_reading_S1*/:
        setColumnVisible('isMainClass');
        setColumnVisible('isBibleReading');
        break;
      case 2 /*label.assignment.task2_S1*/:
      case 4 /*label.assignment.task3_S1*/:
      case 6 /*label.assignment.task4_S1*/:
      case 8 /*label.assignment.task5_S1*/:
        is4BrothersOnly = 0;
        setColumnVisible('isMainClass');
        setColumnVisible('isInitialCall');
        setColumnVisible('isReturnVisit');
        setColumnVisible('isBibleStudy');
        setColumnVisible('isTalk5m');
        setColumnVisible('isBrother', assignment_id);
        break;
      case 3 /*label.assignment.task2assist_S1*/:
      case 5 /*label.assignment.task3assist_S1*/:
      case 7 /*label.assignment.task4assist_S1*/:
      case 9 /*label.assignment.task5assist_S1*/:
        is4BrothersOnly = 0;
        setColumnVisible('isMainClass');
        setColumnVisible('isAssistant');
        setColumnVisible('isBrother', assignment_id);
        break;
      case 10 /*label.assignment.chairman*/:
        setColumnVisible('isChairman');
        break;
      case 11 /*label.assignment.prayer_first*/:
        setColumnVisible('isPrayer');
        break;
      case 12 /*label.assignment.treas_talk*/:
        setColumnVisible('isTreasureTalk');
        break;
      case 13 /*label.assignment.treas_dig*/:
        setColumnVisible('isDiggingGems');
        break;
      case 14 /*label.assignment.living1*/:
        setColumnVisible('isLiving1');
        break;
      case 15 /*label.assignment.living2*/:
        setColumnVisible('isLiving2');
        break;
      case 16 /*label.assignment.bs*/:
        setColumnVisible('isCBSChairman');
        break;
      case 17 /*label.assignment.bs_read*/:
        setColumnVisible('isCBSReader');
        break;
      case 18 /*label.assignment.prayer_last*/:
        setColumnVisible('isPrayer');
        break;
      case 19 /*label.assignment.bible_reading_S2*/:
        setColumnVisible('isAuxClass');
        setColumnVisible('isBibleReading');
        break;
      case 20 /*label.assignment.task2_S2*/:
      case 22 /*label.assignment.task3_S2*/:
      case 24 /*label.assignment.task4_S2*/:
      case 26 /*label.assignment.task5_S2*/:
        is4BrothersOnly = 0;
        setColumnVisible('isAuxClass');
        setColumnVisible('isInitialCall');
        setColumnVisible('isReturnVisit');
        setColumnVisible('isBibleStudy');
        setColumnVisible('isTalk5m');
        setColumnVisible('isBrother', assignment_id);
        break;
      case 21 /*label.assignment.task2assist_S2*/:
      case 23 /*label.assignment.task3assist_S2*/:
      case 25 /*label.assignment.task4assist_S2*/:
      case 27 /*label.assignment.task5assist_S2*/:
        is4BrothersOnly = 0;
        setColumnVisible('isAuxClass');
        setColumnVisible('isAssistant');
        setColumnVisible('isBrother', assignment_id);
        break;
      case 28 /*label.assignment.microphone1*/:
      case 29 /*label.assignment.microphone2*/:
      case 35 /*label.assignment.microphonesunday1*/:
      case 36 /*label.assignment.microphonesunday2*/:
        setColumnVisible('isMicrophoneSrvc');
        break;
      case 30 /*label.assignment.entranceguard1*/:
      case 31 /*label.assignment.entranceguard2*/:
      case 32 /*label.assignment.entranceguard3*/:
      case 33 /*label.assignment.entrancesunday1*/:
      case 34 /*label.assignment.entrancesunday2*/:
        setColumnVisible('isEntranceGuard');
        break;
      case 37 /*label.assignment.SunChariman*/:
        setColumnVisible('isSunChariman');
        break;
      case 38 /*label.assignment.SunTalk*/:
        setColumnVisible('isSunTalk');
        break;
      case 39 /*label.assignment.WT*/:
        setColumnVisible('isWT');
        break;
      case 40 /*label.assignment.WTReader*/:
        setColumnVisible('isWTReader');
        break;
      default:
        console.log('WRONG assignment! filterOutParticipants.assignment_id=', assignment_id);
        return false;
    }

    console.log('candidates0', candidates4TSTask);
    let candidates = candidates4TSTask.filter(candidate => {
      // console.log(
      //   "candidate",
      //   candidate.isBrother,
      //   is4BrothersOnly,
      //   !participants.has(candidate.id) &&
      //     candidate.isBrother === is4BrothersOnly,
      //   candidate
      // );
      return (
        !participants.has(candidate.id) &&
        (!is4BrothersOnly || (is4BrothersOnly && candidate.isBrother === is4BrothersOnly))
      );
    });
    console.log('candidates1', candidates);

    /**
     * supplement candidates with their fullfilled tasks
     * */
    supplementCandidatesByTheirFullfilledTasks(candidates, assignment_id);
    console.log('candidates', candidates);
    return candidates;
  };

  /* create set of participant's User_IDs involved in the current meeting*/
  const findAllParticipants = (assignment_id, tsTasks) => {
    let participants = new Set();
    if (assignment_id >= 33) {
      /* sunday meeting tasks */
      tsTasks.map(task => {
        if (task.user_id > 0 && task.assignment_id >= 33) {
          participants.add(task.user_id);
        }
        return participants;
      });
    } else {
      /* TS meeting tasks */
      tsTasks.map(task => {
        if (task.user_id > 0 && task.assignment_id < 33) {
          participants.add(task.user_id);
        }
        return participants;
      });
    }
    return participants;
  };

  const supplementCandidatesByTheirFullfilledTasks = (candidates, assignment_id) => {
    candidates.forEach(candidate => {
      // console.log("candidate", candidate);
      let list;
      if (onlyEntrance.includes(assignment_id)) {
        // console.log("only Entrance", assignment_id);
        list = tsCandidatesFulfiledTasks.filter(
          task => task.user_id === candidate.id && onlyEntrance.includes(task.assignment_id)
        );
      } else if (onlyMicrophones.includes(assignment_id)) {
        // console.log("only Microphone", assignment_id);
        list = tsCandidatesFulfiledTasks.filter(
          task => task.user_id === candidate.id && onlyMicrophones.includes(task.assignment_id)
        );
      } else if (simpleTasks.includes(assignment_id)) {
        // console.log("simple Tasks", assignment_id);
        list = tsCandidatesFulfiledTasks.filter(
          task => task.user_id === candidate.id && simpleTasks.includes(task.assignment_id)
        );
      } else {
        list = tsCandidatesFulfiledTasks.filter(task => task.user_id === candidate.id);
      }
      candidate.lastDate = list[0] && list[0].week_start ? list[0].week_start : '';
      // console.log("list", list[0], list);
      candidate.previous_assighments = list.map(a => {
        return a.week_start + ' ' + a.assignment;
      });
    });
  };

  const setColumnVisible = (columnName, assignment_id) => {
    console.log('setColumnVisible', columnName, assignment_id);
    let col;
    col = columnsUsed.find(c => c.name === columnName);
    if (window.innerWidth > SMALL_SCREEN) {
      col.options.display = true;
    } else {
      col.options.display = false;
    }

    if (columnName === 'isBrother') {
      const isPartnerBrother = getGenderOfPartnerIsBrother(assignment_id);
      // console.log("isPartnerBrother", isPartnerBrother);
      if (isPartnerBrother !== undefined) {
        col.options.filterList = isPartnerBrother ? [TICK] : [CROSS];
      } else {
        col.options.filterList = [TICK, CROSS];
      }
    } else {
      col.options.filterList = [TICK];
    }
    console.log('setColumnVisible.col', col);
    return col;
  };

  const changeAssignee = async tsTask1 => {
    // console.log("changeAssignee.tsTask", tsTask);

    /* ----------- Call Update API ----------- */
    const updatedRecord = { ...tsTask1, week_start: tsDate };
    if (tsTask1.user_id === -1 && tsTask1.ts_id) {
      // console.log("changeAssignee.DELETE.ts_id", tsTask.ts_id);
      await tsDeleteTask(tsTask1.ts_id);
    } else {
      // console.log("changeAssignee.PUT.body", updatedRecord);
      await tsUpdateTask(updatedRecord);
    }

    /* update tsTasks */
    const tsTasks1 = [...tsTasks];
    // const index = tsTasks1.indexOf(tsTask);
    // const index = _.findIndex(tsTasks1, function(o) {
    //   return (
    //     // o.school === tsTask.school && o.assignment_id === tsTask.assignment_id
    //     o.assignment_id === tsTask.assignment_id
    //   );
    // });
    const index = tsTasks1.findIndex(task => {
      return task.assignment_id === tsTask1.assignment_id;
    });

    // console.log("changeAssignee.index", index);
    if (index === -1) {
      tsTasks1.push(tsTask1);
    } else {
      tsTasks1[index] = tsTask1;
    }

    // console.log(tsTasks1.map(task => task));

    /* update fullfiledTasks lists */
    const tsCandidatesFulfiledTasks1 = updateTSCandidatesFulfiledTasks(updatedRecord);
    // console.log("updated tsCandidatesFulfiledTasks", tsCandidatesFulfiledTasks);

    setTSTasks(tsTasks1);
    setCurrentControl(tsTask1);
    setTSCandidatesFulfiledTasks(tsCandidatesFulfiledTasks1);
  };

  // const getMuiTheme = () =>
  //   createMuiTheme({
  //     typography: {
  //       useNextVariants: true
  //     },
  //     overrides: {
  //       // MUIDataTableToolbar: {
  //       //   root: {
  //       //     backgroundColor: "#ffccdd"
  //       //   },
  //       //   actions: {
  //       //     backgroundColor: "#aabbcc"
  //       //   }
  //       // },
  //       MUIDataTableBodyCell: {
  //         root: {
  //           // backgroundColor: "LightBlue"
  //           // margin: "0px",
  //           // // width: "10px",
  //           // "&:nth-child(2)": {
  //           //   width: "520px"
  //           // }
  //         }
  //       },
  //       // MUIDataTableHeadCell: {
  //       //   root: {
  //       //     "&:nth-child(2)": {
  //       //       width: "520px"
  //       //     }
  //       //   }
  //       // },
  //       MuiTableCell: {
  //         root: {
  //           padding: "2px",
  //           margin: "0px",
  //           "&:nth-child(0)": {
  //             padding: "0px",
  //             margin: "0px"
  //           },
  //           "&:nth-child(1)": {
  //             padding: "0px",
  //             margin: "0px"
  //           },
  //           "&:nth-child(2)": {
  //             padding: "0px",
  //             margin: "0px"
  //           }
  //         }
  //       }
  //       //   MUIDataTable: {
  //       //     responsiveScroll: {
  //       //       maxHeight: "580px",
  //       //     }
  //       //   }
  //       // }
  //     }
  //   });

  const getTask = assignment_id => {
    // console.log("getTask", assignment_id, tsTasks);
    let tsTask1 = tsTasks.find(task => {
      return task.assignment_id === assignment_id;
    });

    if (tsTask1 === undefined) {
      tsTask1 = {
        user_id: 0,
        assignment_id: assignment_id,
      };
    }
    // console.log("getTask", assignment_id, tsTask1);
    return tsTask1;
  };

  const getLabel = (assignment_id, tsLabelDefault) => {
    let tsLabel;
    try {
      const tsTask1 = tsTasks.find(task => {
        return task.assignment_id === assignment_id;
      });
      tsLabel = tsTask1['description'] ? tsTask1['description'] : tsLabelDefault;
      // console.log(
      //   "getLabel2: ",
      //   tsLabel,
      //   assignment_id,
      //   tsLabelDefault,
      //   tsTasks[assignment_id]
      // );
    } catch (error) {
      tsLabel = tsLabelDefault;
    }
    return tsLabel;
  };

  const handleEqualChairmanChange = event => {
    const task10 = tsTasks.find(task => task.assignment_id === 10);
    // console.log("handleEqualChairmanChange1", this.state);
    // console.log("handleEqualChairmanChange2", task10);
    // console.log("handleEqualChairmanChange3", event);

    if (task10 !== undefined && task10.user_id > -1) {
      /* prayer */
      const task11 = tsTasks.find(task => task.assignment_id === 11);
      task11.user_id = task10.user_id;
      task11.full_name = task10.full_name;
      changeAssignee(task11);

      /* task2 */
      const task2 = tsTasks.find(task => task.assignment_id === 2);
      task2.user_id = task10.user_id;
      task2.full_name = task10.full_name;
      changeAssignee(task2);

      // this.setState({ prayerIsChairman: event.target.checked });
    }
  };

  const handleAfterUserSuccessfulSave = user => {
    console.log('handleAfterUserSuccessfulSave.user', user);
    /* update all lists with changed user to avoid get it from server */

    const candidates4TSTask1 = updateUserAmongCandidates(candidates4TSTask, user);
    console.log('candidates4TSTask', candidates4TSTask1);

    const candidatesFiltered1 = updateUserAmongCandidates(candidatesFiltered, user);
    console.log('candidatesFiltered', candidatesFiltered1);

    setOpenUserEditDialog(false);
    setCandidates4TSTask(candidates4TSTask1);
    setCandidatesFiltered(candidatesFiltered1);
  };

  /* update all lists with changed user to avoid get it from server */
  // const changeUserInLists = updatedUserWithTask => {
  //   const tsCandidatesFulfiledTasks1 = updateTSCandidatesFulfiledTasks(updatedUserWithTask);
  //   // console.log("updated tsCandidatesFulfiledTasks", tsCandidatesFulfiledTasks);

  //   setOpenUserEditDialog(false);
  //   setTSCandidatesFulfiledTasks(tsCandidatesFulfiledTasks1);
  // };

  const updateTSCandidatesFulfiledTasks = updatedUserWithTask => {
    const tsCandidatesFulfiledTasks1 = [...tsCandidatesFulfiledTasks];

    if (updatedUserWithTask.user_id === -1) {
      /* find by assignment_id&&week_start and remove the task from the list */
      for (var i = 0; i < tsCandidatesFulfiledTasks1.length; i++) {
        if (
          tsCandidatesFulfiledTasks1[i].assignment_id === updatedUserWithTask.assignment_id &&
          tsCandidatesFulfiledTasks1[i].week_start === updatedUserWithTask.week_start
        ) {
          // console.log(
          //   "updateTSCandidatesFulfiledTasks.removing",
          //   tsCandidatesFulfiledTasks1[i]
          // );
          tsCandidatesFulfiledTasks1.splice(i, 1);
          break;
        }
      }
    } else {
      /* add the task to the list */
      const tsCandidateFulfiledTask = {
        assignment: updatedUserWithTask.name.replace('label.assignment.', ''),
        assignment_id: updatedUserWithTask.assignment_id,
        user_id: updatedUserWithTask.user_id,
        week_start: updatedUserWithTask.week_start,
      };
      tsCandidatesFulfiledTasks1.unshift(tsCandidateFulfiledTask);
    }

    return tsCandidatesFulfiledTasks1;
  };

  /* --- TSEdit handlers --- start --- */
  const handleSundayTalkInfoChange = (control, value) => {
    // console.log("handleSundayTalkChange", control, value);
    let sunTalkInfo1 = sunTalkInfo;
    sunTalkInfo1[control] = value;
    // console.log("handleSundayTalkChange", sunTalkInfo[control], value, sunTalkInfo);
    setSunTalkInfo(sunTalkInfo1);
  };
  const handleSundayTalkInfoSave = async assignment_id => {
    // console.log(
    //   "handleSundayTalkInfoSave",
    //   sunTalkInfo.title,
    //   sunTalkInfo.full_name,
    //   assignment_id,
    //   tsDate
    // );

    // const response = await API.post(`/tsDBHelperSundayTalkInfo`, {
    //   assignment_id: assignment_id,
    //   week_start: tsDate,
    //   title: sunTalkInfo.title,
    //   full_name: sunTalkInfo.full_name
    // });
    // console.log("handleSundayTalkInfoSave.response", response);
    // if (!(response.data > 0) && response.statusText !== "OK") {
    //   alert("Not saved. Try again later, or contact Ihor Choriy. Respone=" + response);
    // }

    try {
      const response = await fetch('/tsDBHelperSundayTalkInfo', {
        method: 'POST',
        body: JSON.stringify({
          assignment_id: assignment_id,
          week_start: tsDate,
          title: sunTalkInfo.title,
          full_name: sunTalkInfo.full_name,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const json = await response.json();
      console.log('Success:', JSON.stringify(json));
    } catch (error) {
      console.error('Not saved. Try again later:', error);
    }
  };
  /* --- TSEdit handlers --- finish --- */

  // console.log("render launched");
  // console.log("permissionGroups", permissionGroups);
  const { classes, translate } = props;
  // console.log("translate=", translate);
  // console.log("translate=", t("muitable.NoRecords"));

  const options = {
    responsive: 'vertical', //vertical,standard,simple Old: "scroll", "stacked", scrollMaxHeight, scrollFullHeight
    filterType: 'multiselect',
    selectableRows: 'single',
    selectableRowsOnClick: false,
    // downloadOptions: true,
    download: true,
    filter: true,
    print: true,
    search: true,
    searchOpen: true,
    viewColumns: true,
    // customToolbar: false,
    // customToolbarSelect: false,
    pagination: true,
    textLabels: {
      body: {
        noMatch: t('muitable.NoRecords'),
        columnHeaderTooltip: column => t('muitable.sortFor', { str: column.label }),
      },
      pagination: {
        next: t('muitable.nextPage'),
        previous: t('muitable.prevPage'),
        rowsPerPage: t('muitable.rowsPerPage'),
        displayRows: t('muitable.of'),
      },
      toolbar: {
        search: t('muitable.search'),
        downloadCsv: t('muitable.downloadCSV'),
        print: t('muitable.print'),
        viewColumns: t('muitable.viewColumns'),
        filterTable: t('muitable.filterTable'),
      },
      viewColumns: {
        title: t('muitable.viewColumns'),
        titleAria: 'Show/Hide Table Columns',
      },
      filter: {
        all: t('muitable.all'),
        title: t('muitable.ourFilters'),
        reset: t('muitable.performReset'),
      },
      selectedRows: {
        text: 'rows has been deleted',
        delete: 'Delete Row',
        deleteAria: 'Deleted Selected Rows',
      },
    },
    onRowSelectionChange: (rowsSelected, allRows) => {
      // console.log("onRowSelectionChange");
      setLeft({
        left: false,
      });

      // console.log("onRowSelectionChange", rowsSelected, allRows);
      let candidate = candidatesFiltered[rowsSelected[0].dataIndex];
      // console.log("onRowSelectionChange.candidate", candidate);
      let tsTask = currentControl;
      // console.log("onRowSelectionChange.tsTask", tsTask);
      tsTask.user_id = candidate.id;
      tsTask.full_name = candidate.lastName + ' ' + candidate.firstName;
      changeAssignee(tsTask);
    },
    onCellClick: (cellData, cellMeta) => {
      // console.log("DoNothing.onCellClick", cellData, cellMeta);
      // console.log("DoNothing.onCellClick");
    },
    onRowClick: (rowData, rowState) => {
      // console.log("DoNothing.onRowClick", rowData, rowState);
      // console.log("DoNothing.onRowClick");
    },
    customSearch: (searchQuery, currentRow, columns) => {
      searchQuery = searchQuery.toLowerCase();
      for (var i = 0; i < 6; i++) {
        // console.log(
        //   "customSearch.currentRow[i]",
        //   currentRow[i],
        //   typeof currentRow[i],
        //   isNaN(currentRow[i])
        // );
        const typeOfCurrentRow = typeof currentRow[i];
        if (
          typeOfCurrentRow === 'number' &&
          currentRow[i].toString().toLowerCase().includes(searchQuery)
        ) {
          return true;
        } else if (
          typeOfCurrentRow === 'string' &&
          currentRow[i].toLowerCase().includes(searchQuery)
        ) {
          return true;
        } else if (
          typeOfCurrentRow === 'object' &&
          JSON.stringify(currentRow[i]).toLowerCase().includes(searchQuery)
        ) {
          return true;
        }
      }
      // }
      return false;
    },
  };

  // const columns111 = [
  //   {
  //    name: "name",
  //    label: "Name",
  //    options: {
  //     filter: true,
  //     sort: true,
  //    }
  //   },
  //   {
  //    name: "company",
  //    label: "Company",
  //    options: {
  //     filter: true,
  //     sort: false,
  //    }
  //   },
  //   {
  //    name: "city",
  //    label: "City",
  //    options: {
  //     filter: true,
  //     sort: false,
  //    }
  //   },
  //   {
  //    name: "state",
  //    label: "State",
  //    options: {
  //     filter: true,
  //     sort: false,
  //    }
  //   },
  //  ];

  //  const data111 = [
  //   { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
  //   { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
  //   { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
  //   { name: "James Houston", company: "Test Corp", city: "Dallas", state: "TX" },
  //  ];

  //  const options111 = {
  //    filterType: 'checkbox',
  //  };

  const columns111 = [
    {
      name: 'firstName',
      label: 'firstName',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'lastName',
      label: 'lastName',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'previous_assighments',
      label: 'previous_assighments',
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: 'pioneer',
      label: 'pioneer',
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const leftSideDrawer = (
    <div /*className={classes.leftSideDrawer}*/>
      {/* <MuiThemeProvider theme={getMuiTheme()}> */}
      {console.log('columnsUsed/candidatesFiltered/ ', columnsUsed /*,candidatesFiltered,options*/)}
      <MUIDataTable
        title={t('news.candidates4Task')}
        data={candidatesFiltered}
        columns={columnsUsed}
        options={options}
      />
      {/* <MUIDataTable
  title={"Employee List"}
  data={data111}
  columns={columns111}
  //options={options111}
/> */}
      {/* </MuiThemeProvider> */}
    </div>
  );

  const handleTabChange = (event, newValue) => {
    // console.log("handleTabChange", newValue);
    setTab(newValue);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openUserEditDialog}
        onClose={event => handleEditUserDialogClose()}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <UserCard
            userId={userId2Edit}
            onAfterSuccessfulSave={handleAfterUserSuccessfulSave}
            onCancel={event => handleEditUserDialogClose()}
          />
        </DialogContent>
      </Dialog>
      <SwipeableDrawer
        open={left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {leftSideDrawer}
      </SwipeableDrawer>
      <FormControl /*className={classes.formControl}*/>
        <InputLabel htmlFor="ts-date">{t('tstable.meetingDate')}</InputLabel>
        {/* <Select
            value={tsDate}
            onChange={handleTSDateChange}
            inputProps={{
              name: "tsDate",
              id: "ts-date"
            }}
          >
            {getTuesdays().map(date => (
              <MenuItem
                key={date.getTime()}
                value={date.toISOString().slice(0, 10)}
              >
                {date.toISOString().slice(0, 10)}
              </MenuItem>
            ))}
          </Select> */}
        <Select
          value={tsDate}
          onChange={handleTSDateChange}
          inputProps={{
            name: 'tsDate',
            id: 'ts-date',
          }}
        >
          {getWeeksDates().map(week => (
            <MenuItem
              key={week.startOfTheWeek.getTime()}
              value={week.startOfTheWeek.toISOString().slice(0, 10)}
            >
              {week.startOfTheWeek.getDate()}.{week.startOfTheWeek.getMonth() + 1}&nbsp;-&nbsp;
              {week.endOfTheWeek.getDate()}.{week.endOfTheWeek.getMonth() + 1}
              &nbsp;{week.startOfTheWeek.getFullYear()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <AppBar position="static">
        <Tabs value={tab} onChange={handleTabChange} aria-label="simple tabs example">
          <Tab label="TS" {...a11yProps(0)} />
          <Tab label="Sunday" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <Grid container>
          <Grid container>
            <Grid item xs={12}>
              <TSSelect5
                tsLabel={getLabel(10, t('tstable.labelChairman'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(10)} /* chairman */
                readOnly={!permissionGroups.includes('TSPart1Editor')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={prayerIsChairman}
                    onChange={handleEqualChairmanChange}
                    value={prayerIsChairman}
                  />
                }
                label={t('tstable.labelSetAsChairman')}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TSSelect5
              tsLabel={getLabel(11, t('tstable.labelPrayer'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(11)} /* prayer */
              readOnly={!permissionGroups.includes('TSPart1Editor')}
            />
          </Grid>
          <Typography /*className={classes.meetingSection1}*/>
            {t('tstable.meetingSection1')}
          </Typography>
          <Grid item xs={12}>
            <TSSelect5
              tsLabel={getLabel(12, t('tstable.labelTreasTalk'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(12)} /* treasures. talk */
              readOnly={!permissionGroups.includes('TSPart1Editor')}
            />
          </Grid>
          <Grid item xs={12}>
            <TSSelect5
              tsLabel={getLabel(13, t('tstable.labelTreasDig'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(13)} /* treasures. digging */
              readOnly={!permissionGroups.includes('TSPart1Editor')}
            />
          </Grid>
          {/* teo school  */}
          <hr />
          <Grid container spacing={0} direction="row" alignItems="flex-end" justify="flex-start">
            <Grid item xs={12} sm={6} lg={3}>
              <TSSelect5
                tsLabel={getLabel(1, t('tstable.labelBibleReading'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(1)} /* bible reading S1 */
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TSSelect5
                tsLabel={getLabel(19, t('tstable.labelBibleReadingS2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(19)} /* bible reading S2 */
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
          </Grid>
          <br></br>
          <Typography /*className={classes.meetingSection2}*/>
            {t('tstable.meetingSection2')}
          </Typography>
          <Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(2, t('tstable.labelTask2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(2)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(3, t('tstable.labelAssistant'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(3)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(20, t('tstable.labelTask2S2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(20)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(21, t('tstable.labelAssistantS2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(21)} /* Task2S2Assistant */
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(4, t('tstable.labelTask3'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(4)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(5, t('tstable.labelAssistant'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(5)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(22, t('tstable.labelTask3S2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(22)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(23, t('tstable.labelAssistantS2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(23)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(6, t('tstable.labelTask4'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(6)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(7, t('tstable.labelAssistant'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(7)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(24, t('tstable.labelTask4S2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(24)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(25, t('tstable.labelAssistantS2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(25)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0} direction="row" justify="flex-start" alignItems="flex-end">
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(8, t('tstable.labelTask5'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(8)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(9, t('tstable.labelAssistant'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(9)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(26, t('tstable.labelTask5S2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(26)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={3} item>
              <TSSelect5
                tsLabel={getLabel(27, t('tstable.labelAssistantS2'))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(27)}
                readOnly={!permissionGroups.includes('TSEditor')}
              />
            </Grid>
          </Grid>
          {/* <hr /> */}
          <br></br>
          <Typography /*className={classes.meetingSection3}*/>
            {t('tstable.meetingSection3')}
          </Typography>
          <Grid item xs={12}>
            <TSSelect5
              tsLabel={getLabel(14, t('tstable.labelLiving1'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(14)} /* living #1 */
              readOnly={!permissionGroups.includes('TSPart3Editor')}
            />
          </Grid>
          <Grid item xs={12}>
            <TSSelect5
              tsLabel={getLabel(15, t('tstable.labelLiving2'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(15)} /* living #2 */
              readOnly={!permissionGroups.includes('TSPart3Editor')}
            />
          </Grid>
          <hr />
          <Grid container spacing={0} direction="row" alignItems="flex-end" justify="flex-start">
            <TSSelect5
              tsLabel={getLabel(16, t('tstable.labelCBS'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(16)} /* bible study */
              readOnly={!permissionGroups.includes('TSPart3Editor')}
            />
            <TSSelect5
              tsLabel={getLabel(17, t('tstable.labelCBSReader'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(17)} /* bible study reader */
              readOnly={!permissionGroups.includes('TSPart3Editor')}
            />
          </Grid>
          <hr />
          <Grid item xs={12}>
            <TSSelect5
              tsLabel={getLabel(18, 'Молитва')}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(18)} /* prayer last */
              readOnly={!permissionGroups.includes('TSPart3Editor')}
            />
          </Grid>
          <hr />
          <hr />
          <Typography /*className={classes.meetingSection4}*/>
            {t('tstable.meetingSection4')}
          </Typography>
          <Grid container spacing={0} direction="row" alignItems="flex-end" justify="flex-start">
            <TSSelect5
              tsLabel={getLabel(28, t('tstable.labelMicrophone', { number: 1 }))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(28)} /* Microphone1 */
              readOnly={!permissionGroups.includes('TSAttendantsEditor')}
            />
            <TSSelect5
              tsLabel={getLabel(29, t('tstable.labelMicrophone', { number: 2 }))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(29)} /* Microphone2 */
              readOnly={!permissionGroups.includes('TSAttendantsEditor')}
            />
          </Grid>
          <Grid container spacing={0} direction="row" alignItems="flex-end" justify="flex-start">
            <TSSelect5
              tsLabel={getLabel(30, t('tstable.labelEntranceService', { number: 1 }))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(30)} /* EntranceService1 */
              readOnly={!permissionGroups.includes('TSAttendantsEditor')}
            />
            <TSSelect5
              tsLabel={getLabel(31, t('tstable.labelEntranceService', { number: 2 }))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(31)} /* EntranceService2 */
              readOnly={!permissionGroups.includes('TSAttendantsEditor')}
            />
            <TSSelect5
              tsLabel={getLabel(32, t('tstable.labelEntranceService', { number: 3 }))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(32)} /* EntranceService3 */
              readOnly={!permissionGroups.includes('TSAttendantsEditor')}
            />
          </Grid>
          <hr />
        </Grid>
      </TabPanel>

      <TabPanel value={tab} index={1}>
        <Grid container>
          {/* <Typography className={classes.meetingWT1}>
              {t("tstable.meetingWT1")}
            </Typography> */}

          <Grid item xs={12}>
            <TSSelect5
              tsLabel={getLabel(37, t('tstable.labelSunChariman'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(37)} /* WT Chariman */
              readOnly={!permissionGroups.includes('WTMeetingEditor')}
            />
          </Grid>
          <Grid item xs={12}>
            {/* <TSSelect5
                tsLabel={getLabel(38, t("tstable.labelSunTalk"))}
                onChange={handleChangeSelect}
                onMenuOpen={handleOnTSSelect5Click}
                tsTask={getTask(38)} / * WT Talk * /
                readOnly={
                  !permissionGroups.includes("WTMeetingEditor")
                }
              /> */}
            <TSEdit
              assignment_id={getTask(38).assignment_id}
              // week_start={tsDate}
              sunTalkInfo={sunTalkInfo}
              // tsTask={getTask(38)} /* WT Talk */
              // onChange={handleChangeSelect}
              // onMenuOpen={handleOnTSSelect5Click}
              readOnly={!permissionGroups.includes('WTMeetingEditor')}
              talkAssignees={candidates4TSTask
                .filter(candidate => {
                  return candidate.isBrother; //isSunTalk;
                })
                .map(candidate => {
                  return candidate.lastName + ' ' + candidate.firstName;
                })}
              onSundayTalkChange={handleSundayTalkInfoChange}
              onSave={handleSundayTalkInfoSave}
            />
          </Grid>

          <hr />
          <Typography /*className={classes.meetingWT2}*/>{t('tstable.meetingWT2')}</Typography>

          <Grid container spacing={0} direction="row" alignItems="flex-end" justify="flex-start">
            <TSSelect5
              tsLabel={getLabel(39, t('tstable.labelWT'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(39)} /* WT */
              readOnly={!permissionGroups.includes('WTMeetingEditor')}
            />
            <TSSelect5
              tsLabel={getLabel(40, t('tstable.labelWTReader'))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(40)} /* WT reader */
              readOnly={!permissionGroups.includes('WTMeetingEditor')}
            />
          </Grid>
          <hr />

          <hr />
          <Typography /*className={classes.meetingSection4}*/>
            {t('tstable.meetingSection4')}
          </Typography>
          <Grid container spacing={0} direction="row" alignItems="flex-end" justify="flex-start">
            <TSSelect5
              tsLabel={getLabel(35, t('tstable.labelMicrophone', { number: 1 }))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(35)} /* Sunday Microphone1 */
              readOnly={!permissionGroups.includes('TSAttendantsEditor')}
            />
            <TSSelect5
              tsLabel={getLabel(36, t('tstable.labelMicrophone', { number: 2 }))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(36)} /* Sunday Microphone2 */
              readOnly={!permissionGroups.includes('TSAttendantsEditor')}
            />
          </Grid>

          <Grid container spacing={0} direction="row" alignItems="flex-end" justify="flex-start">
            <TSSelect5
              tsLabel={getLabel(33, t('tstable.labelEntranceService', { number: 1 }))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(33)} /* Sunday EntranceService1 */
              readOnly={!permissionGroups.includes('TSAttendantsEditor')}
            />
            <TSSelect5
              tsLabel={getLabel(34, t('tstable.labelEntranceService', { number: 2 }))}
              onChange={handleChangeSelect}
              onMenuOpen={handleOnTSSelect5Click}
              tsTask={getTask(34)} /* Sunday EntranceService2 */
              readOnly={!permissionGroups.includes('TSAttendantsEditor')}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </React.Fragment>
  );
};

// export default withTranslation()(withStyles(styles)(TSTable));
//export default withTranslate(withStyles(styles)(TSTable));
export default TSTable;

function updateUserAmongCandidates(candidates, user) {
  let candidate = candidates.find(candidate => candidate.id === user.id);
  console.log('updateUserAmongCandidates1', candidate, user);
  const candidateUpdated = { ...candidate, ...user };
  console.log('updateUserAmongCandidates2', candidateUpdated);

  const foundIndex = candidates.findIndex(candidate => candidate.id === user.id);

  let result = [...candidates];
  result[foundIndex] = candidateUpdated;
  console.log('updateUserAmongCandidates3', foundIndex, result);

  return result;
}
