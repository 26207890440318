import { FormDataErrors } from 'hooks';
import { useTranslation } from 'react-i18next';
import { TerritoryData, TerritoryFields } from 'types';

export const useValidateTerritoryForm = (values: TerritoryData) => {
  const { t } = useTranslation();
  const errors: FormDataErrors<TerritoryData> = {};

  if (!values[TerritoryFields.UserID] && !!values[TerritoryFields.StartAt]) {
    errors[TerritoryFields.UserID] = t('FieldRequired');
  }

  if (!values[TerritoryFields.StartAt] && !!values[TerritoryFields.UserID]) {
    errors[TerritoryFields.StartAt] = t('FieldRequired');
  }

  if (
    !values[TerritoryFields.UserID] &&
    !values[TerritoryFields.StartAt] &&
    !values[TerritoryFields.FinishAt]
  ) {
    errors[TerritoryFields.FinishAt] = t('FieldRequired');
  }

  if (!values[TerritoryFields.Code]) {
    errors[TerritoryFields.Code] = t('FieldRequired');
  }

  if (!values[TerritoryFields.Street]) {
    errors[TerritoryFields.Street] = t('FieldRequired');
  }

  return errors;
};
