import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

export default function CircularIndeterminate() {
  return (
    <Grid
      justifyContent="center"
      container
      spacing={0}
      style={{ margin: '0px', paddingTop: '25%' }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}
