import * as React from 'react';
import { useEffect, useState } from 'react';

import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import ErrorDialog from '../common/error-dialog';
import BackDrop from '../common/backdrop';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const theme = createTheme();

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SignInViaGuid() {
  const { t } = useTranslation(); // i18n

  localStorage.setItem('admin', false);

  let queryParams = useQuery();

  const [backdropOpen, setBackdropOpen] = useState('');
  const [error, setError] = useState(null);

  const getStands = async jwt => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/stands`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.ok) return response.json();
          else setError(response);
        })
        .then(data => {
          localStorage.setItem('stands', JSON.stringify(data));

          setBackdropOpen(false);
          window.location = '/stand';
        })
        .catch(err => {
          console.log(err);
        });
    } catch (error) {
      setError(error);
    }
  };

  const apiCall = async () => {
    setBackdropOpen(true);

    try {
      const url = `${process.env.REACT_APP_API_URL}/guid`;
      const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ guid: queryParams.get('id') }),
      };

      await fetch(url, request)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else setError(response);
        })
        .then(data => {
          const jwtData = data.token;
          localStorage.setItem('jwt', jwtData);

          getStands(jwtData);
        })
        .catch(err => {
          console.log(err);
          setError(err);
        });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    apiCall();
  }, [queryParams]);

  if (error) return <ErrorDialog open={Boolean(error)} text={error} />;
  return (
    <ThemeProvider theme={theme}>
      <BackDrop open={backdropOpen} />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('Sign in via GUID, please wait')}
          </Typography>

          <div style={{ paddingTop: '50px' }}>
            <CircularProgress />
          </div>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
