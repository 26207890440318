export const userLogoutMenuItems = [
  {
    path: '/sign-in',
    translateString: 'Sign-In',
  },
  {
    path: '/sign-up',
    translateString: 'Sign-Up',
  },
];

export const userMenuItems = [
  {
    path: '/profile',
    translateString: 'Edit Profile Data',
  },
  {
    path: '/users',
    translateString: 'Users',
  },
  {
    path: '/table',
    translateString: 'Table',
  },
  {
    path: '/report-my-ministry',
    translateString: 'Ministry Report',
  },
];
