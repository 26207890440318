export const getSetLocalStorage = <T>(key: string, value?: T) => {
  if (!Object.prototype.hasOwnProperty.call(window.localStorage, key)) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  const storedValue = JSON.parse(`${window.localStorage.getItem(key)}`) as T;
  const setStorageValue = (newValue: T) => {
    window.localStorage.setItem(key, JSON.stringify(newValue));
  };

  return [storedValue, setStorageValue] as const;
};
