import * as React from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import ErrorDialog from './common/error-dialog';
import BackDrop from './common/backdrop';
import Snackbar from './common/snackbar';

import { useTranslation } from 'react-i18next';

const theme = createTheme();

export default function SignUp() {
  const { t } = useTranslation(); // i18n

  const [message, changeMessage] = useState('');

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState(false);

  const handleMessageChange = event => changeMessage(event.target.value);

  const handleSubmit = async () => {
    setBackdropOpen(true);

    if (message.length === 0) return setBackdropOpen(false);

    try {
      let url, headers;
      if (localStorage.jwt) {
        url = `${process.env.REACT_APP_API_URL}/contactauth`;
        headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        };
      } else {
        url = `${process.env.REACT_APP_API_URL}/contact`;
        headers = {
          'Content-Type': 'application/json',
        };
      }

      const body = {
        message: message,
      };

      const request = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
          if (response.ok) {
            setSnackbar(true);
            setBackdropOpen(false);
            changeMessage('');
          } else setError(response);
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const hideSnackbar = () => {
    setSnackbar(false);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <ThemeProvider theme={theme}>
      <BackDrop open={backdropOpen} />
      <ErrorDialog open={Boolean(error)} text={error} />
      <Snackbar
        type={'success'}
        msg={'Thank you for contacting us!'}
        open={snackbar}
        hideSnackbar={() => hideSnackbar()}
      />

      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            {t('Contact Developers')}
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t('Message2dev')}
                type="message"
                multiline
                maxRows={500}
                rows={5}
                helperText={t('ContactUsExplanation')}
                value={message}
                onChange={handleMessageChange}
              />
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              {t('Send')}
            </Button>

            <Button type="submit" fullWidth variant="contained" onClick={goBack}>
              {t('Back')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
