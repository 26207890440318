import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const headers = ['Та шо ж таке', 'Упс...', 'О це так!', 'Вот не задача...', 'Обана!'];

const header = headers[Math.floor(Math.random() * headers.length)];

export default function AlertDialog(props) {
  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>{header}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Схоже що хтось вже записався на цей час. Спробуйте записатись на інший час.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => window.location.reload()}>Ок</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
