import React, { FC } from 'react';
import { Container } from '@mui/system';

type Props = {
  children: React.ReactNode;
};

export const MainWrapper: FC<Props> = ({ children }) => {
  return <Container maxWidth="xl">{children}</Container>;
};
