export function getThisWeekMondayDate(parsableDate: string): Date {
  let d;
  if (parsableDate) d = new Date(parsableDate);
  else d = new Date();
  const day = d.getDay();
  const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
  const monday = new Date(d.setDate(diff));
  return monday;
}

export function getNextWeekMondayDate(): Date {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, and so on

  const daysUntilMonday = 1 + ((7 - dayOfWeek) % 7);

  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilMonday);

  return nextMonday;
}

export function getWeekDates(mondayDate: Date): Date[] {
  const week: Date[] = [new Date(mondayDate)];
  const date = new Date(mondayDate);

  for (let i = 0; i < 6; i++) {
    date.setDate(date.getDate() + 1);
    week.push(new Date(date)); // Create a new Date object for each day
  }

  return week;
}
