import { useAll } from 'hooks';
import { Maybe, Scalars, UserData } from 'types';

export const usePublishers = () => {
  const { data: publishers } = useAll<UserData[]>('/users');
  const getPublisher = (id: Maybe<Scalars['ID']>) =>
    publishers?.find(publisher => publisher.id === id);

  return { publishers, getPublisher };
};
