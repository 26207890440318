import * as React from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useTranslation } from 'react-i18next';

// dropdown
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function DateSelect(props) {
  const { t } = useTranslation(); // i18n
  let { daysOfWeek, standParams } = props;
  daysOfWeek = daysOfWeek.map((day, i) => ({
    name: day,
    count: i,
  }));

  if (daysOfWeek[0].name === 'Sunday') {
    daysOfWeek.push(daysOfWeek.shift()); // move sunday to the end of array
  }

  let [selectedDay, setSelectedDay] = useState(
    daysOfWeek.filter(day => day.name === props.openedDayOfWeek)[0].count
  ); // day id in daysOfWeek var
  let days = props.availableDays.filter(
    v => v.dayOfWeek === daysOfWeek[selectedDay === 0 ? 6 : selectedDay - 1].name
  );

  let dayDate = `${days[0].year}/${
    days[0].mounth.int < 10 ? `0${days[0].mounth.int}` : days[0].mounth.int
  }/${days[0].day < 10 ? `0${days[0].day}` : days[0].day}`;
  let [isCurrentWeek, setIsCurrentWeek] = useState(props.openedDay === dayDate);

  const { screenSize } = props;

  const selectDay = (dayId, thisWeek) => {
    setSelectedDay(dayId);
    setIsCurrentWeek(thisWeek);

    props.openDay(
      props.availableDays.filter(v => v.dayOfWeek === daysOfWeek[dayId === 0 ? 6 : dayId - 1].name)[
        thisWeek ? 0 : 1
      ]
    );
  };

  const selectDayDropdown = event => {
    selectDay(event.target.value, isCurrentWeek);
    // console.log(event.target.value);
  };

  daysOfWeek = daysOfWeek.filter(day => {
    if (day.name === 'Monday' && standParams.on_mon === 0) return false;
    else if (day.name === 'Tuesday' && standParams.on_tue === 0) return false;
    else if (day.name === 'Wednesday' && standParams.on_wed === 0) return false;
    else if (day.name === 'Thursday' && standParams.on_thu === 0) return false;
    else if (day.name === 'Friday' && standParams.on_fri === 0) return false;
    else if (day.name === 'Saturday' && standParams.on_sat === 0) return false;
    else if (day.name === 'Sunday' && standParams.on_sun === 0) return false;
    else return true;
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        orientation={screenSize.width < 600 ? 'vertical' : 'horizontal'}
      >
        <Button
          style={{ width: '300px' }}
          variant={isCurrentWeek ? 'contained' : 'outlined'}
          onClick={() => selectDay(selectedDay, !isCurrentWeek)}
        >
          {t('This Week')}
        </Button>

        <Button
          style={{ width: '300px' }}
          variant={!isCurrentWeek ? 'contained' : 'outlined'}
          onClick={() => selectDay(selectedDay, !isCurrentWeek)}
          color="error"
        >
          {t('Next Week')}
        </Button>
      </ButtonGroup>

      {screenSize.width < 900 ? (
        <FormControl sx={{ m: 1, width: 300 }} size="small">
          <InputLabel>{t('DayOfWeek')}</InputLabel>
          <Select
            value={selectedDay}
            onChange={selectDayDropdown}
            input={<OutlinedInput label={t('DayOfWeek')} />}
            MenuProps={MenuProps}
          >
            {daysOfWeek.map(day => (
              <MenuItem key={day.count} value={day.count}>
                {t(`${day.name}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <ButtonGroup
          variant="outlined"
          aria-label="outlined button group"
          orientation={screenSize.width < 900 ? 'vertical' : 'horizontal'}
        >
          {daysOfWeek.map((day, i) => {
            return (
              <Button
                key={i}
                style={{ width: '120px' }}
                variant={day.count === selectedDay ? 'contained' : 'outlined'}
                onClick={() => selectDay(day.count, isCurrentWeek)}
              >
                {t(day.name)}
              </Button>
            );
          })}
        </ButtonGroup>
      )}
    </Box>
  );
}

export default DateSelect;
