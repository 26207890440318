import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import TodayIcon from '@mui/icons-material/Today';

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 250,
  },
  // notchedOutline: {
  //   borderWidth: "3px",
  //   borderColor: "yellow !important"
  // },
  root: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 250,
    maxWidth: 250,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 2,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  label: {
    color: 'gray',
    // margin: theme.spacing(1)
  },
});

class TSSelect5 extends Component {
  render() {
    const {
      classes,
      tsTask,
      tsLabel,
      readOnly,
      onChange,
      onMenuOpen,
      // editable
    } = this.props;
    // console.log("TSSelect5.editable", editable);
    // console.log("TSSelect5.tsTask", tsTask);
    // console.log("TSSelect5.readOnly", readOnly);

    const tsTaskLabel = tsTask.description ? tsTask.description : tsLabel;

    const id = '_' + Math.random().toString(36).substr(2, 9);

    if (!tsTask.user_id) {
      tsTask.user_id = -1;
    }
    if (!tsTask.full_name) {
      tsTask.full_name = '';
    }

    return (
      <div /*className={classes.formControl}*/>
        <Typography /*className={classes.label}*/>&nbsp;&nbsp;{tsTaskLabel}</Typography>
        <Paper /*className={classes.root}*/>
          <InputBase
            key={tsTask.user_id}
            id={id}
            value={tsTask.full_name}
            /* className={classes.input} */
            placeholder=""
            inputProps={{ 'aria-label': 'input' }}
            onChange={event => onChange(event, tsTask)}
            readOnly
          />

          {readOnly ? (
            ''
          ) : (
            <IconButton
              /* className={classes.iconButton} */
              aria-label="clear"
              onClick={event => {
                tsTask.user_id = -1;
                onChange(event, tsTask);
              }}
            >
              <ClearIcon />
            </IconButton>
          )}

          {readOnly ? (
            ''
          ) : (
            <IconButton
              /* className={classes.iconButton} */
              aria-label="search"
              onClick={() => onMenuOpen('OnlyNotUsed', tsTask)}
            >
              <SearchIcon />
            </IconButton>
          )}

          {readOnly ? (
            ''
          ) : (
            <IconButton
              /* className={classes.iconButton} */
              aria-label="searchAll"
              onClick={() => onMenuOpen('SearchAll', tsTask)}
            >
              <TodayIcon />
            </IconButton>
          )}
        </Paper>
      </div>
    );
  }
}

TSSelect5.propTypes = {
  tsTask: PropTypes.object.isRequired,
};

// export default withStyles(styles)(TSSelect5);
export default TSSelect5;
