import React, { memo, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import { GoogleMapProps } from './types';

const GoogleMap = memo(({ lat, lng, info, zoom, polygonCoordinates }: GoogleMapProps) => {
  const { t } = useTranslation();
  const isSetLatLng = Boolean(lat && lng);
  const mapRef = useRef<HTMLDivElement>(null);

  const loader = new Loader({
    apiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    version: 'weekly',
  });

  if (isSetLatLng && mapRef.current) {
    loader.load().then(async () => {
      const { Map, InfoWindow, Polygon } = (await google.maps.importLibrary(
        'maps'
      )) as google.maps.MapsLibrary;
      const { AdvancedMarkerElement } = (await google.maps.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary;

      const map = new Map(mapRef.current as HTMLElement, {
        center: { lat, lng },
        zoom: Number(zoom),
        mapId: 'TERRITORY_MAP_ID',
      });

      const marker = new AdvancedMarkerElement({
        position: { lat, lng },
        map,
      });

      const infoWindow = new InfoWindow({
        content: info,
      });

      marker.addListener('click', () => {
        infoWindow.open(map, marker);
      });

      if (polygonCoordinates?.length) {
        new Polygon({
          paths: polygonCoordinates,
          strokeColor: '#1976d2',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: '#1976d2',
          fillOpacity: 0.05,
          map,
        });
      }
    });
  }

  return (
    <Stack
      ref={mapRef}
      width="100%"
      height={isSetLatLng ? 600 : 300}
      justifyContent="center"
      alignItems="center"
    >
      <Typography>{t('NoMapInfo')}</Typography>
    </Stack>
  );
});

GoogleMap.displayName = 'GoogleMap';
export { GoogleMap };
