import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
// import { withTranslate } from "react-admin";

// import { withStyles } from "@mui/styles";
import YesNo from './YesNo';
import MySelect from './MySelect';
// import axios from "axios";

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const styles = theme => ({
  // button: {
  //   margin: theme.spacing(3, 0, 2)
  // },
  // form: {
  //   width: "95%", // Fix IE 11 issue.
  //   marginTop: theme.spacing(2),
  //   marginLeft: theme.spacing(2),
  //   marginRight: theme.spacing(2)
  // },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // maxWidth: 300
  },
  datePickField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // maxWidth: 300
  },
  yesNo: {
    // width: "95%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(3)
  },
  mySelect: {
    // width: "95%", // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
    // marginLeft: theme.spacing(1)
    margin: theme.spacing(1),
    minWidth: 150,
    // marginRight: theme.spacing(3)
  },
  // publisheSelect: {
  //   width: "200px",
  //   backgroundColor: "black"
  // },
  //   container: {
  //     display: "flex",
  //     flexWrap: "wrap"
  //   },
  formControl: {
    margin: theme.spacing(1),
  },
});

class UserCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: this.props.userId,
      user: { user_id: 0 },
      isChanged: false,
      ranks: [
        { id: 10, value: 'Publisher' },
        { id: 30, value: 'Ministeral Servant' },
        { id: 50, value: 'Elder' },
        { id: 51, value: 'Not Baptised' },
      ],
      groupLeaders: [
        { id: 1691, value: 'Литвин В.' },
        { id: 1657, value: 'Березовський А.' },
        { id: 1721, value: 'Шутяк Р.' },
        { id: 1772, value: 'Кравчук О.' },
        { id: 1775, value: 'Клим Ю.' },
        { id: 1647, value: 'Дуда М.' },
        { id: 1667, value: 'Градус О.' },
        { id: 1735, value: 'Петрик А.' },
      ],
      pioneerStatuses: [
        { id: 1, value: 'Publisher' },
        { id: 30, value: 'Auxiliary 30' },
        { id: 40, value: 'Auxiliary 50' },
        { id: 50, value: 'Auxiliary Constant' },
        { id: 70, value: 'Full Time' },
        { id: 80, value: 'Special' },
        { id: 90, value: 'Missioner' },
      ],
    };
  }

  async componentDidMount() {
    this.getUser(this.state.user_id);
  }

  getUser = async user_id => {
    let url = process.env.REACT_APP_API_URL + '/user?id=' + user_id;

    const { data: user } = await fetch(url);

    user.id = user_id;

    // console.log("getUser:", user);
    this.setState({ user });
  };

  render() {
    const { classes, t, onAfterSuccessfulSave, onCancel } = this.props;
    //    const { classes, translate, onAfterSuccessfulSave, onCancel } = this.props;

    const handleIntegerChange = event => {
      // console.log("UserCard.handleMySelectChange", event.target);
      let user = this.state.user;
      user[event.target.name] = Number.parseInt(event.target.value);
      this.setState({ user, isChanged: true });
    };

    const handleChange = e => {
      // console.log("handleChange", e.target.value, e.target);
      let user = this.state.user;
      user[e.target.name] = e.target.value;
      this.setState({ user, isChanged: true });
    };

    const handleDateChange = (date, field) => {
      // console.log("handleDateChange", date, field);
      let user = this.state.user;
      if (date && Object.prototype.toString.call(date) === '[object Date]') {
        user[field] = date.toISOString().slice(0, 10);
      } else {
        user[field] = null;
      }
      // console.log("handleStartDateChange", user);
      this.setState({ user, isChanged: true });
    };

    const handleOnSave = async () => {
      // console.log("handleOnSave", user);

      let url = process.env.REACT_APP_API_URL + '/user';

      // await axios.put(url, this.state.user);
      try {
        const response = await fetch(url, {
          method: 'PUT', // или 'POST'
          body: JSON.stringify(this.state.user), // данные могут быть 'строкой' или {объектом}!
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const json = await response.json();
        console.log('Успех:', JSON.stringify(json));
        onAfterSuccessfulSave(json);
      } catch (error) {
        console.error('Ошибка:', error);
      }

      // onAfterSuccessfulSave(this.state.user);
    };

    return (
      <React.Fragment>
        {this.state.isChanged ? (
          <Button
            onClick={() => {
              handleOnSave(this.state.user);
            }}
            color="primary"
          >
            Зберегти
          </Button>
        ) : (
          ''
        )}
        <Button onClick={onCancel} color="default">
          Закрити
        </Button>

        {/* <Typography variant="h6" gutterBottom>
          Територія
        </Typography> */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              id="firstName"
              name="firstName"
              value={this.state.user.firstName || ''}
              inputProps={{ maxLength: 255 }}
              label={t('userCard.firstName')}
              required
              autoComplete="firstName"
              // className={classes.textField}
              onChange={handleChange}
            />
            <TextField
              id="lastName"
              name="lastName"
              value={this.state.user.lastName || ''}
              inputProps={{ maxLength: 255 }}
              label={t('userCard.lastName')}
              required
              autoComplete="lastName"
              // className={classes.textField}
              onChange={handleChange}
            />
            <TextField
              id="phones"
              name="phones"
              value={this.state.user.phones || ''}
              inputProps={{ maxLength: 255 }}
              label={t('userCard.phones')}
              autoComplete="phones"
              // className={classes.textField}
              onChange={handleChange}
            />
            <TextField
              id="address"
              name="address"
              value={this.state.user.address || ''}
              inputProps={{ maxLength: 255 }}
              label={t('userCard.address')}
              autoComplete="address"
              // className={classes.textField}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <MySelect
              // classes={classes}
              name="groupLeader_id"
              label={t('userCard.groupLeader')}
              value={this.state.user.groupLeader_id}
              options={this.state.groupLeaders}
              onChange={handleIntegerChange}
            />
            <MySelect
              // classes={classes}
              name="rank_id"
              label={t('userCard.rank')}
              value={this.state.user.rank_id}
              options={this.state.ranks}
              onChange={handleIntegerChange}
            />
            <MySelect
              // classes={classes}
              name="pioneer_id"
              label={t('userCard.pioneer')}
              value={this.state.user.pioneer_id}
              options={this.state.pioneerStatuses}
              onChange={handleIntegerChange}
            />
          </Grid>

          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('userCard.BirthDate')}
                value={this.state.user.birthDate}
                onChange={event => handleDateChange(event, 'BirthDate')}
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('userCard.BaptiseDate')}
                value={this.state.user.BaptiseDate}
                onChange={event => handleDateChange(event, 'BaptiseDate')}
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant='inline'
                format='yyyy-MM-dd'
                margin='normal'
                id='BirthDate'
                label={t("userCard.BirthDate")}
                value={this.state.user.birthDate}
                onChange={event => handleDateChange(event, "BirthDate")}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                className={classes.datePickField}
              />
              <KeyboardDatePicker
                autoOk
                disableToolbar
                variant='inline'
                format='yyyy-MM-dd'
                margin='normal'
                id='BaptiseDate'
                label={t("userCard.BaptiseDate")}
                value={this.state.user.baptiseDate}
                onChange={event => handleDateChange(event, "BaptiseDate")}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                className={classes.datePickField}
              />
            </MuiPickersUtilsProvider> */}
          </Grid>

          <Grid item xs={12}>
            <YesNo
              // classes={classes}
              name="active"
              label={t('userCard.active')}
              hint={t('userCard.activeHint')}
              value={this.state.user.active}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isBrother"
              label={t('userCard.isBrother')}
              hint={t('userCard.isBrotherHint')}
              value={this.state.user.isBrother}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isGroupLeader"
              label={t('userCard.isGroupLeader')}
              hint={t('userCard.isGroupLeaderHint')}
              value={this.state.user.isGroupLeader}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isAllowed4Stand"
              label={t('userCard.isAllowed4Stand')}
              hint={t('userCard.isAllowed4StandHint')}
              value={this.state.user.isAllowed4Stand}
              onChange={handleIntegerChange}
            />
          </Grid>

          <Grid item xs={12}>
            <YesNo
              // classes={classes}
              name="isMainClass"
              label={t('userCard.isMainClass')}
              hint={t('userCard.isMainClassHint')}
              value={this.state.user.isMainClass}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isAuxClass"
              label={t('userCard.isAuxClass')}
              hint={t('userCard.isAuxClassHint')}
              value={this.state.user.isAuxClass}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isAssistant"
              label={t('userCard.isAssistant')}
              hint={t('userCard.isAssistantHint')}
              value={this.state.user.isAssistant}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isUse4fillins"
              label={t('userCard.isUse4fillins')}
              hint={t('userCard.isUse4fillinsHint')}
              value={this.state.user.isUse4fillins}
              onChange={handleIntegerChange}
            />
          </Grid>

          <Grid item xs={12}>
            <YesNo
              // classes={classes}
              name="isBibleReading"
              label={t('userCard.isBibleReading')}
              hint={t('userCard.isBibleReadingHint')}
              value={this.state.user.isBibleReading}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isInitialCall"
              label={t('userCard.isInitialCall')}
              hint={t('userCard.isInitialCallHint')}
              value={this.state.user.isInitialCall}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isReturnVisit"
              label={t('userCard.isReturnVisit')}
              hint={t('userCard.isReturnVisitHint')}
              value={this.state.user.isReturnVisit}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isBibleStudy"
              label={t('userCard.isBibleStudy')}
              hint={t('userCard.isBibleStudyHint')}
              value={this.state.user.isBibleStudy}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isTalk5m"
              label={t('userCard.isTalk5m')}
              hint={t('userCard.isTalk5mHint')}
              value={this.state.user.isTalk5m}
              onChange={handleIntegerChange}
            />
          </Grid>

          <Grid item xs={12}>
            <YesNo
              // classes={classes}
              name="isPrayer"
              label={t('userCard.isPrayer')}
              hint={t('userCard.isPrayerHint')}
              value={this.state.user.isPrayer}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isChairman"
              label={t('userCard.isChairman')}
              hint={t('userCard.isChairmanHint')}
              value={this.state.user.isChairman}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isTreasureTalk"
              label={t('userCard.isTreasureTalk')}
              hint={t('userCard.isTreasureTalkHint')}
              value={this.state.user.isTreasureTalk}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isDiggingGems"
              label={t('userCard.isTreasureDig')}
              hint={t('userCard.isTreasureDigHint')}
              value={this.state.user.isDiggingGems}
              onChange={handleIntegerChange}
            />
          </Grid>

          <Grid item xs={12}>
            <YesNo
              // classes={classes}
              name="isLiving1"
              label={t('userCard.isLiving1')}
              hint={t('userCard.isLiving1Hint')}
              value={this.state.user.isLiving1}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isLiving2"
              label={t('userCard.isLiving2')}
              hint={t('userCard.isLiving2Hint')}
              value={this.state.user.isLiving2}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isCBSChairman"
              label={t('userCard.isCBSChairman')}
              hint={t('userCard.isCBSChairmanHint')}
              value={this.state.user.isCBSChairman}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isCBSReader"
              label={t('userCard.isCBSReader')}
              hint={t('userCard.isCBSReaderHint')}
              value={this.state.user.isCBSReader}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isEntranceGuard"
              label={t('userCard.isEntranceGuard')}
              hint={t('userCard.isEntranceGuardHint')}
              value={this.state.user.isEntranceGuard}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isMicrophoneSrvc"
              label={t('userCard.isMicrophoneSrvc')}
              hint={t('userCard.isMicrophoneSrvcHint')}
              value={this.state.user.isMicrophoneSrvc}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isSunChariman"
              label={t('userCard.isSunChariman')}
              hint={t('userCard.isSunCharimanHint')}
              value={this.state.user.isSunChariman}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isSunTalk"
              label={t('userCard.isSunTalk')}
              hint={t('userCard.isSunTalkHint')}
              value={this.state.user.isSunTalk}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isWT"
              label={t('userCard.isWT')}
              hint={t('userCard.isWTHint')}
              value={this.state.user.isWT}
              onChange={handleIntegerChange}
            />
            <YesNo
              // classes={classes}
              name="isWTReader"
              label={t('userCard.isWTReader')}
              hint={t('userCard.isWTReaderHint')}
              value={this.state.user.isWTReader}
              onChange={handleIntegerChange}
            />
          </Grid>
        </Grid>

        {this.state.isChanged ? (
          <Button
            onClick={() => {
              handleOnSave(this.state.user);
            }}
            color="primary"
          >
            Зберегти
          </Button>
        ) : (
          ''
        )}
        <Button onClick={onCancel} color="default">
          Закрити
        </Button>
      </React.Fragment>
    );
  }
}

UserCard.propTypes = {
  // classes: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
};

// export default withTranslation()(withStyles(styles)(UserCard));
//export default withTranslate(withStyles(styles)(UserCard));
// export default UserCard;
export default withTranslation()(UserCard);
