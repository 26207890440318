import MUIDataTable from 'mui-datatables';
import { styled } from '@mui/material';
import { red, orange } from '@mui/material/colors';

export const StyledTerritoriesTable = styled(MUIDataTable)`
  & .MuiTableBody-root .MuiTableRow-root {
    cursor: pointer;
    transition: all 0.07s ease-in-out;
  }

  & .MuiTableBody-root .MuiTableRow-root.MuiTableRow-Expired {
    background-color: ${red[50]};

    &:hover {
      background-color: ${red[100]};
    }
  }

  & .MuiTableBody-root .MuiTableRow-root.MuiTableRow-Warn {
    background-color: ${orange[50]};

    &:hover {
      background-color: ${orange[100]};
    }
  }
`;
