export function getJsonFromUrl(url?: string): Record<string, string> {
  url = url ?? window.location.search; // Use nullish coalescing for optional URL
  const query = url.substring(1);
  const result: Record<string, string> = {};

  query.split('&').forEach(part => {
    const [key, value] = part.split('=');
    result[key] = decodeURIComponent(value).replace(/-/g, '/');
  });

  return result;
}
