import * as React from 'react';
import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import BackDrop from '../common/backdrop';
import Loading from '../common/loading';
import ErrorDialog from '../common/error-dialog';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';

import { parseJwt } from '../common/parseJwt';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const mounthsNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function getMonday(parsableDate) {
  const d = new Date(parsableDate);
  const day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
}

// function getWeekDates(mondayDate) {
// 	let week = [new Date(mondayDate)];
// 	let date = new Date(mondayDate)

// 	for (let i = 0; i < 6; i++) {
// 		week.push(date);
// 		date = new Date(date.setDate(date.getDate() + 1));
// 	}

// 	return week
// }

// function getJsonFromUrl(url) {
// 	if (!url) url = window.location.search;
// 	var query = url.substr(1);
// 	var result = {};
// 	query.split("&").forEach(function(part) {
// 	  var item = part.split("=");
// 	  result[item[0]] = decodeURIComponent(item[1]);
// 	});
// 	return result;
// }

// stand dropdown
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function createData(time, monday, tuesday, wednesday, thursday, friday, saturday, sunday) {
//   return { time, monday, tuesday, wednesday, thursday, friday, saturday, sunday };
// }

let rows = [];

function ReportMyMinistry() {
  const { t } = useTranslation(); // i18n

  const nowDate = new Date();
  // const [dateRange, setDateRange] = React.useState([new Date(nowDate.getFullYear(), nowDate.getMonth(), 1), nowDate]);
  const [startDate, setStartDate] = React.useState(
    new Date(nowDate.getFullYear(), nowDate.getMonth(), 1)
  );
  const [finishDate, setFinishDate] = React.useState(nowDate);
  const [totalHours, setTotalHours] = React.useState(0);
  const [totalTalks, setTotalTalks] = React.useState(0);
  const [totalPublications, setTotalPublications] = React.useState(0);

  if (!localStorage.jwt) window.location = `${process.env.REACT_APP_URL}/sign-in`;

  const parsedJWT = parseJwt(localStorage.jwt);
  // console.log("parsedJWT: ", parsedJWT.id, parsedJWT);
  const currUserId = parsedJWT.id;

  let isCurUserAdmin = localStorage.admin ? JSON.parse(localStorage.admin) : false;

  if (parsedJWT && parsedJWT.isAllowed4Stand === 0)
    window.location = `${process.env.REACT_APP_URL}/users`;

  if (!localStorage.stands || localStorage.stands == 'undefined') {
    alert(t('NoAnyStandData'));
    localStorage.clear();
    window.location = '/sign-in';
  }

  const stands = JSON.parse(localStorage.stands);

  if (stands.length === 0) {
    alert(t('NoStands4Congregation'));
    window.location = `${process.env.REACT_APP_URL}/users`;
  }

  const thisWeekMonday = getMonday(new Date());
  // const weekDates = [...getWeekDates(thisWeekMonday), ...getWeekDates(thisWeekMonday.setDate(thisWeekMonday.getDate()+7))];
  thisWeekMonday.setDate(thisWeekMonday.getDate() - 7);

  const [data, updateData] = useState(null);
  // const [availablePeople, updateAvailablePeople] = useState(null);
  const [standParams, updateStandParams] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  // const [r, selectStand] = useState(stands[0].id);

  const [selectedStand, selectStand] = useState(stands[0].id);

  const [backdropOpen, setBackdropOpen] = useState('');
  const [error, setError] = useState(null);

  let callsSent = {
    records: false,
    // people: false,
    stand: false,
  };

  //  --- date parse functions --->
  const getDateObj = date => {
    date = new Date(date);

    return {
      day: date.getDate(),
      mounth: {
        int: date.getMonth() + 1,
        str: mounthsNames[date.getMonth()],
      },
      year: date.getFullYear(),
      dayOfWeek: daysOfWeek[date.getDay()],
    };
  };

  const makeDate = date => {
    // returns yyyy-mm-dd string
    date = getDateObj(date);

    return `${date.year}/${date.mounth.int < 10 ? `0${date.mounth.int}` : date.mounth.int}/${
      date.day < 10 ? `0${date.day}` : date.day
    }`;
  };

  // --- API calls --->
  const getRecords = async id => {
    setBackdropOpen(true);

    const stand = stands.find(stand => {
      return id === stand.id;
    });

    try {
      const url = `${process.env.REACT_APP_API_URL}/ministry?standId=${
        stand.id
      }&startDate=${`${makeDate(startDate)}`}&finishDate=${`${makeDate(finishDate)}`}`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) window.location = '/sign-in';
          else if (response.ok) return response.json();
          else setError(`${response.status} ${response.statusText}`);
        })
        .then(data => {
          setBackdropOpen(false);

          updateData(data);
          //   console.error("Data:", data);
          rows = [];
          let totalHours1 = 0,
            totalTalks1 = 0,
            totalPublications1 = 0;
          data.forEach(row => {
            let user1 = '',
              user2 = '',
              talks = '',
              publications = '',
              notes = '';

            //if (row.start_at == '2022/12/19 11:00:00') console.error(row)
            if (
              (!row.user1_id && !row.user2_id) ||
              (!isCurUserAdmin && row.user1_id !== currUserId && row.user2_id !== currUserId)
            ) {
              //console.error(isCurUserAdmin + " NULL row: ", row);
              return;
              // } else {
              // 	console.log(isCurUserAdmin + " row: ", row);
            }

            if (isCurUserAdmin) {
              if (row.user1_id) {
                user1 = row.u1_firstName + ' ' + row.u1_lastName;
                //console.log("1isCurUserAdmin: ", row);
              }
              if (row.user2_id) {
                user2 = row.u2_firstName + ' ' + row.u2_lastName;
                //console.log("2isCurUserAdmin: ", row);
              }
            } else if (row.user1_id === currUserId || row.user2_id === currUserId) {
              if (row.user1_id) {
                user1 = row.u1_firstName + ' ' + row.u1_lastName;
                //console.log("1currUserId: ", currUserId, "notAdmin: ", row);
              }
              if (row.user2_id) {
                user2 = row.u2_firstName + ' ' + row.u2_lastName;
                //console.log("c2urrUserId: ", currUserId, "notAdmin: ", row);
              }
            }

            if (row.notes) {
              try {
                let mNotesFromDB;
                if (typeof row.notes === 'string') {
                  mNotesFromDB = JSON.parse(row.notes);
                } else {
                  mNotesFromDB = row.notes;
                }
                talks = mNotesFromDB && mNotesFromDB.talks ? mNotesFromDB.talks : 0;
                publications =
                  mNotesFromDB && mNotesFromDB.publications ? mNotesFromDB.publications : 0;
                notes = mNotesFromDB && mNotesFromDB.notes ? mNotesFromDB.notes : '';
              } catch (error) {
                console.error(error);
                console.error('row.notes=', row.notes);
                notes = row.notes;
              }
            }

            if (row.duration) totalHours1 += row.duration;
            if (row.talks) totalTalks1 += row.talks;
            if (row.publications) totalPublications1 += row.publications;

            rows.push({
              id: row.id,
              start_at: row.start_at.replaceAll('/', '-'),
              duration: row.duration,
              standname: row.standname,
              user1,
              user2,
              talks,
              publications,
              notes,
            });
          });

          setTotalHours(totalHours1 / 60);
          setTotalTalks(totalTalks1);
          setTotalPublications(totalPublications1);
          //   console.error("totalHours:", totalHours, "totalTalks:", totalTalks, "totalPublications:", totalPublications);

          //   console.error("rows:", rows);
          //return data
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const getStandParams = async id => {
    setBackdropOpen(true);

    const stand = stands.find(stand => {
      return id === stand.id;
    });

    try {
      const url = `${process.env.REACT_APP_API_URL}/stands/${stand.id}`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) window.location = '/sign-in';
          else if (response.ok) return response.json();
          else setError(`${response.status} ${response.statusText}`);
        })
        .then(data => {
          // console.log("Data:", data);
          setBackdropOpen(false);
          updateStandParams(data);

          return data;
        })
        .catch(err => {
          console.error('Data:', data);
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      console.error('Data:', data);
      setError(error);
    }
  };

  // const getAvailablePeople = async () => {
  // 	try {
  // 		const url = `${process.env.REACT_APP_API_URL}/getAllowed4StandPublishers`;
  // 		const headers = {
  // 			method: 'GET',
  // 			headers: {
  // 			  'Content-Type': 'application/json',
  // 			  'Authorization': `Bearer ${localStorage.jwt}`
  // 			}
  // 		};

  // 		await fetch(url, headers).then((response) => {
  // 			if (response.status === 401) {
  // 				window.location = "/sign-in"
  // 			} else if (response.ok) {
  // 				return response.json()
  // 			} else {
  // 				setError(`${response.status} ${response.statusText}`);
  // 			}
  // 		}).then(data => {
  // 			updateAvailablePeople(data);
  // 		}).catch(err => {
  // 			setError(t("Sorry! Failed to connect server =("));
  // 		});
  // 	} catch(error) {
  // 		setError(error);
  // 	}
  // };
  // --- --- --- ---

  useEffect(() => {
    if (data && standParams) {
      setDataLoaded(true);

      // formCells(data, standParams)
    }

    // callsSent prevents double api calling
    if (data === null && !callsSent.records) {
      getRecords(selectedStand);
      callsSent.records = true;
    }

    // if (availablePeople === null && !callsSent.people) {
    // 	// getAvailablePeople()
    // 	callsSent.people = true;
    // };

    if (standParams === null && !callsSent.stand) {
      getStandParams(selectedStand);
      callsSent.stand = true;
    }
  }, [data, standParams]);

  // const thisWeekDates = [...getWeekDates(thisWeekMonday)];

  // let cells = [];

  // const formCells = (data, standParams) => {

  // if (cells.length > 0) {
  // 	cells = [];
  // 	rows = [];
  // }

  // let hour = { // TODO take data from standParams
  // 	from: standParams.start_hour,
  // 	to: standParams.end_hour
  // }

  // thisWeekDates.forEach(wdate => {
  // 	let dayobj = []
  // 	for (let i = hour.from; i <= hour.to; i++) {
  // 		let pushitem = {
  // 			time: `${i}:00`,
  // 			first: null,
  // 			second: null,
  // 			date: makeDate(wdate),
  // 			id: null,
  // 		};
  // 		dayobj.push(pushitem);
  // 	}
  // 	cells.push(dayobj)
  // });

  // cells.map(day => {
  // 	day.map(hour => {
  // 		// console.log(day, hour)
  // 		let dataForCell = data.filter(record => {
  // 			let time = new Date(record.start_at);
  // 			time = `${makeDate(time)} ${time.getHours()}:00`;

  // 			return time === `${hour.date} ${hour.time}`
  // 		})[0];

  // 		if (dataForCell) {
  // 			hour.id = dataForCell.id;

  // 			let u1id = dataForCell.user1_id ?
  // 				dataForCell.user1_id === 0
  // 					? null
  // 					: dataForCell.user1_id
  // 				: null;
  // 			let u2id = dataForCell.user2_id
  // 				? dataForCell.user2_id === 0
  // 					? null
  // 					: dataForCell.user2_id
  // 				: null;

  // 			// if (u1id) {
  // 			// 	let u = availablePeople.filter(user => user.id === u1id)[0];
  // 			// 	u1id = u ? `${u.firstName} ${u.lastName}` : `id:${u1id}`;
  // 			// };
  // 			// if (u2id) {
  // 			// 	let u = availablePeople.filter(user => user.id === u2id)[0];
  // 			// 	u2id = u2id = u ? `${u.firstName} ${u.lastName}` : `id:${u2id}`;
  // 			// }

  // 			hour.first = u1id;
  // 			hour.second = u2id;
  // 		}

  // 		return hour
  // 	});

  // 	return day
  // });

  // if (rows.length === 0 && cells.length !== 0) {
  // 	let data = [[], [], [], [], [], [], [], []];

  // 	for (let i = hour.from; i <= hour.to; i++) {
  // 		data[0].push(`${i}:00`);
  // 	}

  // 	cells.forEach((day, i) => {
  // 		day.forEach(hour => {
  // 			let line;
  // 			if (!hour.first && !hour.second) {
  // 				line = "_______";
  // 			} else {
  // 				line = `${hour.first ? hour.first : "___"} - ${hour.second ? hour.second : "___"}`
  // 			}
  // 			data[i+1].push(line)
  // 		})
  // 	})

  // 	for (let i = 0; i < data[0].length; i++) {
  // 		rows.push(createData(data[0][i], data[1][i], data[2][i], data[3][i], data[4][i], data[5][i], data[6][i], data[7][i]));
  // 	}
  // }
  // }

  const handleSelectStand = event => {
    const id = event.target.value;
    selectStand(id);

    // getRecords(id);
    // getStandParams(id);

    // formCells(getRecords(id), getStandParams(id))
  };

  const startSearch = (dayId, thisWeek) => {
    // setSelectedDay(dayId)
    // setIsCurrentWeek(thisWeek);

    //   props.openDay(props.availableDays.filter(v => v.dayOfWeek === daysOfWeek[dayId === 0 ? 6 : dayId-1].name)[thisWeek ? 0 : 1]);

    getRecords(selectedStand);
    callsSent.records = true;
  };

  if (error) return <ErrorDialog open={Boolean(error)} text={error} />;
  else if (!dataLoaded) return <Loading />;
  return (
    <Grid>
      <BackDrop open={backdropOpen} />

      <FormControl sx={{ m: 1 }} size="small">
        <InputLabel>{t('Stand')}</InputLabel>
        <Select
          value={selectedStand}
          onChange={handleSelectStand}
          input={<OutlinedInput label={t('Stand')} />}
          MenuProps={MenuProps}
        >
          {stands.map(stand => (
            <MenuItem key={stand.id} value={stand.id}>
              {stand.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1 }} size="small">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={t('StartDate')}
            value={startDate}
            views={['year', 'month', 'day']}
            onChange={newValue => {
              setStartDate(newValue);
            }}
            // onChange={event => handleDateChange(event, "StartDate")}
            renderInput={params => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </FormControl>

      <FormControl sx={{ m: 1 }} size="small">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={t('FinishDate')}
            value={finishDate}
            onChange={newValue => {
              setFinishDate(newValue);
            }}
            // onChange={event => handleDateChange(event, "FinishDate")}
            renderInput={params => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </FormControl>

      {/* <LocalizationProvider
				dateAdapter={AdapterDateFns}
				localeText={{ start: 'Start-date', end: 'End-date' }}
				>
				<DateRangePicker
					value={dateRange}
					onChange={(newValue) => {
					setDateRange(newValue);
					}}
					renderInput={(startProps, endProps) => (
					<React.Fragment>
						<TextField {...startProps} />
						<Box sx={{ mx: 2 }}> to </Box>
						<TextField {...endProps} />
					</React.Fragment>
					)}
				/>
				</LocalizationProvider> */}
      <FormControl sx={{ m: 1 }} size="small">
        <Button style={{ width: '300px' }} variant="outlined" onClick={() => startSearch()}>
          {t('Search')}
        </Button>
      </FormControl>

      <TableContainer component={Paper}>
        {/* <Typography
            variant="h5"
            gutterBottom
            component="div"
            align="center"
            style={{ paddingTop: "16px" }}
          >
            {`${t("Week")} ${makeDate(thisWeekDates[0])} - ${makeDate(
              thisWeekDates[6]
            )}`}
          </Typography> */}
        {/* <Typography
            variant="h5"
            gutterBottom
            component="div"
            align="center"
            style={{ paddingBottom: "10px" }}
          >
            {`${standParams.name}`}
          </Typography> */}

        <Table sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('start_at')}</TableCell>
              <TableCell>{t('duration')}</TableCell>
              <TableCell>{t('Publisher') + ' #1'}</TableCell>
              <TableCell>{t('Publisher') + ' #2'}</TableCell>
              <TableCell>{t('talks')}</TableCell>
              <TableCell>{t('publications')}</TableCell>
              <TableCell>{t('Notes')}</TableCell>
              <TableCell>{t('Stand')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => {
              // let user1 = "",
              //   user2 = "",
              //   talks = "",
              //   publications = "",
              //   notes = "";

              // //if (row.start_at == '2022/12/19 11:00:00') console.error(row)
              // if (!row.user1_id && !row.user2_id) {
              // 	console.log("NULL row: ", row);
              //   return null;
              // }

              // if (isCurUserAdmin) {

              //   if (row.user1_id) {
              //     user1 = row.u1_firstName + " " + row.u1_lastName;
              // 	//console.log("1isCurUserAdmin: ", row);
              //   }
              //   if (row.user2_id) {
              //     user2 = row.u2_firstName + " " + row.u2_lastName;
              // 	//console.log("2isCurUserAdmin: ", row);
              //   }

              // } else if (
              //   row.user1_id === currUserId ||
              //   row.user2_id === currUserId
              // ) {

              //   if (row.user1_id) {
              //     user1 = row.u1_firstName + " " + row.u1_lastName;
              // 	//console.log("1currUserId: ", currUserId, "notAdmin: ", row);
              //   }
              //   if (row.user2_id) {
              //     user2 = row.u2_firstName + " " + row.u2_lastName;
              // 	//console.log("c2urrUserId: ", currUserId, "notAdmin: ", row);
              //   }
              // }

              // if (row.notes) {
              //   try {
              //     let mNotesFromDB;
              //     if (typeof row.notes === "string") {
              //       mNotesFromDB = JSON.parse(row.notes);
              //     } else {
              //       mNotesFromDB = row.notes;
              //     }
              //     talks =
              //       mNotesFromDB && mNotesFromDB.talks
              //         ? mNotesFromDB.talks
              //         : 0;
              //     publications =
              //       mNotesFromDB && mNotesFromDB.publications
              //         ? mNotesFromDB.publications
              //         : 0;
              //     notes =
              //       mNotesFromDB && mNotesFromDB.notes
              //         ? mNotesFromDB.notes
              //         : "";
              //   } catch (error) {
              //     console.error(error);
              //     console.error("row.notes=", row.notes);
              //     notes = row.notes;
              //   }
              // }
              // console.log(row)
              // if (!row)
              // 	return null;
              // else
              return (
                <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{row.start_at.replaceAll('/', '-')}</TableCell>
                  <TableCell>{row.duration}</TableCell>
                  <TableCell>{row.user1}</TableCell>
                  <TableCell>{row.user2}</TableCell>
                  <TableCell>{row.talks}</TableCell>
                  <TableCell>{row.publications}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                  <TableCell>{row.standname}</TableCell>
                </TableRow>
              );
            })}
            <TableRow key="total" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>Total</TableCell>
              <TableCell>
                {totalHours}&nbsp;{t('hours')}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>{totalTalks}</TableCell>
              <TableCell>{totalPublications}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

export default ReportMyMinistry;
