import { useAll } from './useAll';
import {
  TerritoryData,
  TerritoryFields,
  TerritoryHistoryData,
  TerritoryHistoryFields,
} from 'types';

export const useTerritoriesHistory = () => {
  const { data: territories } = useAll<TerritoryData[]>('/territories');
  const { data: territoriesHistory, refetch } = useAll<TerritoryHistoryData[]>('/territoryhistory');

  const data = territories
    ?.map(territory => ({
      territory,
      id: territory[TerritoryFields.ID],
      territory_number: territory[TerritoryFields.Code],
      territory_history:
        territoriesHistory
          ?.filter(({ territory_id }) => territory_id === territory[TerritoryFields.ID])
          .sort(
            (a, b) =>
              new Date(b[TerritoryHistoryFields.FinishAt] ?? '').getTime() -
              new Date(a[TerritoryHistoryFields.FinishAt] ?? '').getTime()
          ) ?? [],
    }))
    .sort((a, b) => Number(a.territory_number) - Number(b.territory_number))
    .map(th => {
      const territory_history = th.territory_history;

      if (th.territory[TerritoryFields.UserID]) {
        territory_history.unshift({
          [TerritoryHistoryFields.ID]: null,
          [TerritoryHistoryFields.TerritoryID]: th.territory[TerritoryFields.ID],
          [TerritoryHistoryFields.UserID]: th.territory[TerritoryFields.UserID],
          [TerritoryHistoryFields.StartAt]: th.territory[TerritoryFields.StartAt],
          [TerritoryHistoryFields.FinishAt]: null,
        });
      }

      return {
        ...th,
        territory_history,
      };
    });

  return { territoriesHistory: data, refetch };
};
