import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';

import { Icon } from 'legos';

import { useTranslation } from 'react-i18next';

import { AreaPolygon, TerritoryFields } from 'types';
import { Props } from './type';
import { getAreaPolygonLikeArray } from 'helpers';

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'none',
  '&::before': {
    height: 0,
  },
  '&.Mui-expanded': {
    margin: 0,
  },
  '.MuiAccordionSummary-root': {
    padding: 0,
  },
  '.MuiAccordionDetails-root': {
    padding: 0,
  },
}));

export const GoogleMapPolygonCoordinatesRepeater: React.FC<Props> = ({
  area,
  zoom,
  setValue,
  handleChange,
}) => {
  const { t } = useTranslation();

  const onChangeCoordinate = (value: string, index: number, i: number) => {
    const polygonCoordinates = value.split(/[ ,]+/);
    const idx = index + index + i;
    const points = area?.lineStrings?.at(0)?.points;
    const newArea: AreaPolygon = {
      lineStrings: [
        {
          points: [
            ...(points?.map((point, i) => {
              if (i === idx) {
                return {
                  x: Number(polygonCoordinates.at(0) ?? 0),
                  y: Number(polygonCoordinates.at(1) ?? 0),
                };
              }
              return point;
            }) ?? []),
          ],
        },
      ],
    };

    setValue(TerritoryFields.Area, newArea);
  };

  const handleAddPairOfCoordinates = () => {
    const point = { x: 0, y: 0 };
    const points = area?.lineStrings?.at(0)?.points;
    const newArea: AreaPolygon = {
      lineStrings: [
        { points: points ? [...points, { ...point }, { ...point }] : [{ ...point }, { ...point }] },
      ],
    };

    setValue(TerritoryFields.Area, newArea);
  };

  const handleDeletePairOfCoordinates = () => {
    const points = area?.lineStrings?.at(0)?.points;
    points?.splice(-2);
    const newArea: AreaPolygon = { lineStrings: [{ points }] };

    setValue(TerritoryFields.Area, newArea);
  };

  return (
    <StyledAccordion>
      <AccordionSummary expandIcon={<Icon icon="expandMore" />}>
        <Typography>{t('additional_coordinates')}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack gap={2}>
          {!!area?.lineStrings?.at(0)?.points?.length &&
            getAreaPolygonLikeArray(area).map((coordinates, index) => (
              <Stack
                key={index}
                gap={2}
                direction={{ xs: 'column', sm: 'row' }}
                justifyContent="center"
                alignItems="center"
              >
                {coordinates.map((coordinate, i) => (
                  <TextField
                    key={i}
                    fullWidth
                    label={`${t('coordinates')} ${index + index + i + 1}`}
                    value={coordinate.at(0)}
                    onChange={e => onChangeCoordinate(e.target.value, index, i)}
                  />
                ))}
              </Stack>
            ))}

          <Stack gap={2} direction="row" justifyContent="space-between" alignItems="center">
            <TextField
              type="number"
              inputProps={{ min: 14, max: 20 }}
              label="Zoom"
              name={TerritoryFields.Zoom}
              value={zoom}
              onChange={handleChange}
            />
            <Stack gap={2} direction="row">
              <IconButton color="primary" size="small" onClick={handleAddPairOfCoordinates}>
                <Icon icon="add" />
              </IconButton>
              <IconButton
                color="error"
                size="small"
                disabled={!area?.lineStrings?.at(0)?.points?.length}
                onClick={handleDeletePairOfCoordinates}
              >
                <Icon icon="delete" />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
};
