import { addMonths, subDays } from 'date-fns';

export const getWarnEndTerritoryDates = (date: Date) => {
  const endDate = addMonths(date, 4);
  const warnDate = subDays(endDate, 7);

  return {
    warnDate,
    endDate,
  };
};
