import React from 'react';
import { TextField, styled } from '@mui/material';

type Color = 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';

type Props = {
  color: Color;
  value: string;
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

const StyledButtonTextField = styled(TextField)(({ theme, color, disabled }) => ({
  '& .MuiInputBase-root': {
    background: disabled
      ? theme.palette.action.disabledBackground
      : theme.palette[color as Color].main,
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  ...(!disabled && {
    '& .MuiInputBase-root:hover': {
      background: theme.palette[color as Color].dark,
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    },
  }),
  '& .MuiInputBase-input': {
    textTransform: 'uppercase',
    fontSize: theme.typography.button.fontSize,
    textAlign: 'center',
    padding: '9.95px 8px',
    color: 'white',
    cursor: disabled ? 'auto' : 'pointer',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: theme.palette[color as Color].main,
  },
}));

export const ButtonTextField: React.FC<Props> = ({ color, value, disabled, onClick }) => {
  return (
    <StyledButtonTextField
      variant="outlined"
      size="small"
      color={color}
      value={value}
      disabled={disabled}
      inputProps={{ readOnly: disabled }}
      onFocus={e => e.target.blur()}
      onClick={onClick}
    />
  );
};
