import React, { FC } from 'react';
import { MainWrapper, TerritoriesTable } from 'components';
import { TerritoryFilterData } from 'constant';

type Props = {
  territoryFilter: TerritoryFilterData;
  setTerritoryFilter: React.Dispatch<React.SetStateAction<TerritoryFilterData>>;
};

export const Territories: FC<Props> = ({ territoryFilter, setTerritoryFilter }) => {
  return (
    <MainWrapper>
      <TerritoriesTable filter={territoryFilter} setFilter={setTerritoryFilter} />
    </MainWrapper>
  );
};
