export function replaceObjectInArrayByKeyField(arrayOfObjects, newObject, keyField2identify, insertIfAbsent) {
  // Replaces an object in an array of objects based on the "start_at" property.

  // Args:
  //   arrayOfObjects: The array of objects to modify.
  //   newObject: The object to replace with.
  //   keyField2identify: The key in each object that identifies the property. Example "start_at".

  // Returns:
  //   A new array with the replaced object.

  let found = false;
  for (let i = 0; i < arrayOfObjects.length; i++) {
    if (arrayOfObjects[i][keyField2identify] === newObject[keyField2identify]) {
      arrayOfObjects[i] = newObject;
      found = true;
      break;
    }
  }
  if (!found && insertIfAbsent) {
    arrayOfObjects.push(newObject);
  }

  return arrayOfObjects;
}
