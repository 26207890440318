import { getAreaPolygonString } from 'helpers';
import { TerritoryData, TerritoryFields } from 'types';

export const getPrepareTerritoryData = (values: TerritoryData) => ({
  ...values,
  [TerritoryFields.Area]: getAreaPolygonString(values[TerritoryFields.Area]),
  [TerritoryFields.UserID]: values[TerritoryFields.UserID] ? values[TerritoryFields.UserID] : null,
  [TerritoryFields.Map]: values[TerritoryFields.Map]?.split(', ').join(' '),
  [TerritoryFields.Zoom]: Number(values[TerritoryFields.Zoom]),
});
