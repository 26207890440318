import * as React from 'react';
import { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Form from './form';
import AlertDialog from './alert-dialog';
//import AdminForm from './admin-form';
import DateSelect from './date-select';

import BackDrop from '../common/backdrop';
import Loading from '../common/loading';
import ErrorDialog from '../common/error-dialog';

import { useTranslation } from 'react-i18next';
import { parseJwt } from '../common/parseJwt';
import { replaceObjectInArrayByKeyField } from '../../helpers/replaceObjectInArrayByKeyField';
import { getThisWeekMondayDate, getWeekDates } from '../../helpers/DatesUtilities';
import { getJsonFromUrl } from '../../helpers/URLparams2JSON';

const colorOptions = ['primary', 'secondary', 'error', 'info', 'success', 'warning'];

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const mounthsNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const numberNames = ['first', 'second', 'third', 'fourth'];

const logout = () => {
  localStorage.removeItem('jwt');
  localStorage.removeItem('stands');
  localStorage.removeItem('admin');
  window.location = '/sign-in';
};

// stand dropdown
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function Stand() {
  const { t } = useTranslation(); // i18n

  const parsedJWT = parseJwt(localStorage.jwt);

  const thisWeekMonday = getThisWeekMondayDate();
  const weekDates = [
    ...getWeekDates(thisWeekMonday),
    ...getWeekDates(thisWeekMonday.setDate(thisWeekMonday.getDate() + 7)),
  ];
  thisWeekMonday.setDate(thisWeekMonday.getDate() - 7);

  const [data, updateData] = useState(null);
  const [availablePeople, updateAvailablePeople] = useState(null);
  const [standParams, updateStandParams] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  let [availableDays, setAvailableDays] = useState(null);
  let [recordsForSelectedDay, setRecordsForSelectedDay] = useState(null);
  let [lastRefresh, setLastRefresh] = useState(new Date()); // If the user just loaded the page you don't want to refresh either

  let stands, selectedStandId;
  if (!localStorage.stands || localStorage.stands == 'undefined') {
    stands = {};
    selectedStandId = null;
  } else {
    stands = JSON.parse(localStorage.stands);
    if (stands[0] && stands[0].id) {
      selectedStandId = stands[0].id;
    } else {
      console.error(stands[0]);
      stands = {};
      selectedStandId = null;
    }
  }
  const [selectedStand, selectStand] = useState(selectedStandId);

  const [backdropOpen, setBackdropOpen] = useState('');
  const [error, setError] = useState(null);
  const [displayAlert, setDisplayAlert] = useState(false);

  const [currColor, setCurrColor] = useState(colorOptions[0]);

  let callsSent = {
    records: false,
    people: false,
    stand: false,
  };

  const handleSelectStand = event => {
    const id = event.target.value;
    selectStand(id);

    getRecords(id);
    getStandParams(id);
  };

  //  --- date parse functions --->
  const getDateObj = date => {
    date = new Date(date);

    return {
      day: date.getDate(),
      mounth: {
        int: date.getMonth() + 1,
        str: mounthsNames[date.getMonth()],
      },
      year: date.getFullYear(),
      dayOfWeek: daysOfWeek[date.getDay()],
    };
  };

  const makeDate = date => {
    // returns yyyy-mm-dd string
    date = getDateObj(date);

    return `${date.year}/${date.mounth.int < 10 ? `0${date.mounth.int}` : date.mounth.int}/${
      date.day < 10 ? `0${date.day}` : date.day
    }`;
  };

  const makeDateStrOfOpenedDay = date => {
    let dateObj = getDateObj(date);
    return `${dateObj.day} ${t(dateObj.mounth.str)}, ${t(dateObj.dayOfWeek)}`;
  };
  // --- --- --- --- --- --- ---

  const setDateToUrl = () => {
    let date = makeDate(new Date());
    window.history.replaceState(
      { additionalInformation: '' },
      '',
      `${window.location}?date=${date}`
    );

    return date;
  };

  let [openedDay, setOpenedDay] = useState(
    new Date(getJsonFromUrl().date ? getJsonFromUrl().date.replace(/-/g, '/') : setDateToUrl())
  ); // Date object

  // --- API calls --->
  const getRecords = async id => {
    setBackdropOpen(true);

    const stand = stands.find(stand => {
      return id === stand.id;
    });

    try {
      const url = `${process.env.REACT_APP_API_URL}/get2WeeksOfMinistry4Stands?standId=${
        stand.id
      }&startDate=${`${makeDate(getThisWeekMondayDate())}`}`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) window.location = '/sign-in';
          else if (response.ok) return response.json();
          else setError(`${response.status} ${response.statusText}`);
        })
        .then(data => {
          setBackdropOpen(false);

          updateData(data);

          setLastRefresh(new Date());
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  function countOccurrences(string, symbol) {
    return (string.match(new RegExp(symbol, 'g')) || []).length;
  }

  const getStandParams = async id => {
    setBackdropOpen(true);

    const stand = stands.find(stand => {
      return id === stand.id;
    });

    const standIndexInArray = stands.findIndex(stand => id === stand.id);
    setCurrColor(colorOptions[standIndexInArray]);

    try {
      const url = `${process.env.REACT_APP_API_URL}/stands/${stand.id}`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) window.location = '/sign-in';
          else if (response.ok) return response.json();
          else setError(`${response.status} ${response.statusText}`);
        })
        .then(data => {
          setBackdropOpen(false);

          updateStandParams(data);
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const getAvailablePeople = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/getAllowed4StandPublishers`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            return response.json();
          } else {
            setError(`${response.status} ${response.statusText}`);
          }
        })
        .then(data => {
          updateAvailablePeople(data);
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };
  // --- --- --- ---

  const whenDataLoaded = () => {
    // console.log("whenDataLoaded");
    // get all records with a selected day
    // availableDays = data.map(record => makeDate(record.start_at));

    setAvailableDays(weekDates);
    setRecordsForSelectedDay(
      data.filter(record => makeDate(record.start_at) === makeDate(openedDay))
    );
  };

  // --- child functions --->
  const openDay = dayObj => {
    // select a new day in DateSelect component
    // console.log("openDay=", dayObj);

    /* refresh records/data if it is older than 1 hour (1000 * 60 * 60 = 3600000 ms) */
    if (new Date() - lastRefresh > 3600000) {
      getRecords(selectedStand);
    }

    // let newDate = new Date(`${dayObj.year}-${dayObj.mounth.int}-${dayObj.day}`);
    let newDate = new Date(dayObj.year, dayObj.mounth.int - 1, dayObj.day);
    let date = makeDate(newDate);
    let url = `${window.location}`.split('?');

    window.history.replaceState({ additionalInformation: '' }, '', `${url[0]}?date=${date}`);
    openedDay = newDate;
    setOpenedDay(newDate);
    whenDataLoaded();
  };

  const updateDataFromFormV2 = row => {
    row.start_at = row.start_at.replace(/-/g, '/').replace('T', ' '); //transform date 2023-12-31 -> 2023/12/31
    if (countOccurrences(row.start_at, ':') == 1) {
      row.start_at += ':00';
    }

    const newData = replaceObjectInArrayByKeyField(data, row, 'start_at', true);

    updateData(newData);
  };

  // const updateDataFromForm = (cell, cellId) => {
  //   console.log("updateDataFromForm=", cell, cellId);
  //   let newCellData = {
  //     id: cellId,
  //     user1_id: cell.first === true ? parsedJWT.id : cell.first,
  //     user2_id: cell.second === true ? parsedJWT.id : cell.second,
  //     start_at: cell.date,
  //     duration: standParams.duration,
  //     stand_id: standParams.stand_id,
  //   };
  //   console.log("updateDataFromForm.newData=", newCellData);
  //   let newData = data;

  //   let isNewData = false;
  //   newData = newData.map(c => {
  //     if (c.start_at === newCellData.start_at) {
  //       isNewData = true;

  //       c = newCellData;
  //     }

  //     return c;
  //   });

  //   if (isNewData === false) {
  //     newData.push(newCellData);
  //   }
  //   console.log("updateDataFromForm.newData=", newData);
  //   updateData(newData);
  // };
  // --- --- --- --- --- ---

  // get dynamic screen size
  // https://www.positronx.io/react-get-dynamic-window-height-width-using-react-hooks/ --->
  let [screenSize, getDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const setDimension = () => {
    getDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', setDimension);

    return () => {
      window.removeEventListener('resize', setDimension);
    };
  }, [screenSize]);
  // --------------------

  useEffect(() => {
    // console.log("useEffect.2data=", data);
    if (data && availablePeople && standParams) {
      setDataLoaded(true);
      whenDataLoaded();
    }

    // callsSent prevents double api calling
    if (data === null && !callsSent.records) {
      getRecords(selectedStand);
      callsSent.records = true;
    }

    if (availablePeople === null && !callsSent.people) {
      getAvailablePeople();
      callsSent.people = true;
    }

    if (standParams === null && !callsSent.stand) {
      getStandParams(selectedStand);
      callsSent.stand = true;
    }
  }, [availablePeople, data, standParams]);

  let adminForm = localStorage.admin ? JSON.parse(localStorage.admin) : false;
  const openedDayOfWeek = getDateObj(openedDay).dayOfWeek;

  if (!localStorage.jwt) {
    // console.log ("1window.location=",`${process.env.REACT_APP_URL}/sign-in`);
    logout();
    return;
  }
  if (parsedJWT && parsedJWT.isAllowed4Stand === 0) {
    // console.log ("2window.location=",`${process.env.REACT_APP_URL}/users`);
    window.location = `${process.env.REACT_APP_URL}/users`;
    return;
  }

  if (!localStorage.stands || localStorage.stands == 'undefined') {
    // console.log ("3window.location=","/sign-in");
    alert(t('NoAnyStandData'));
    logout();
    return;
  }
  if (stands.length === 0) {
    // console.log ("4window.location=",`${process.env.REACT_APP_URL}/users`);
    alert(t('NoStands4Congregation'));
    window.location = `${process.env.REACT_APP_URL}/users`;
    return;
  }

  if (error) return <ErrorDialog open={Boolean(error)} text={error} />;
  else if (!dataLoaded) return <Loading />;
  
  return (
    <Grid container spacing={0} style={{ margin: '0px' }}>
      <AlertDialog open={displayAlert} />
      <BackDrop open={backdropOpen} />

      <Grid item xl={3} lg={2} sm={1} xs={0}></Grid>

      <Grid item container xl={6} lg={8} sm={10} xs={12} style={{ paddingTop: '32px' }}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom component="div" align="center" color={currColor}>
            {`${makeDateStrOfOpenedDay(openedDay)}`}
          </Typography>

          <Grid style={{ display: stands.length >= 2 ? '' : 'none' }} container item xs={12}>
            <Grid style={{ margin: 'auto' }}>
              <FormControl sx={{ m: 1, width: 300 }} size="small" color={currColor}>
                <InputLabel>{t('Stand')}</InputLabel>
                <Select
                  value={selectedStand}
                  onChange={handleSelectStand}
                  input={<OutlinedInput label={t('Stand')} />}
                  MenuProps={MenuProps}
                >
                  {stands.map(stand => (
                    <MenuItem key={stand.id} value={stand.id}>
                      {stand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <DateSelect
            availableDays={availableDays.map(v => getDateObj(v))}
            openedDay={makeDate(openedDay)}
            openedDayOfWeek={getDateObj(openedDay).dayOfWeek}
            standParams={standParams}
            openDay={openDay}
            screenSize={screenSize}
            daysOfWeek={daysOfWeek}
          />
        </Grid>

        <Grid item xs={12}>
          <Form
            adminMode={adminForm}
            dataToDisplay={recordsForSelectedDay}
            daysOfWeek={daysOfWeek}
            standParams={standParams}
            standId={selectedStand}
            uid={parsedJWT.id}
            openedDay={makeDate(openedDay)}
            makeDate={makeDate}
            openedDayOfWeek={openedDayOfWeek}
            updateDataFromForm={updateDataFromFormV2}
            alert={() => setDisplayAlert(true)}
            setError={setError}
            availablePeople={availablePeople}
          />
        </Grid>
      </Grid>

      <Grid item xl={3} lg={2} sm={1} xs={0}></Grid>
    </Grid>
  );
}

export default Stand;
