import { createContext } from 'react';
import { AlertColor } from '@mui/material';

type SnackbarContextType = {
  openSnackbar: (message: string, variant?: AlertColor, showTitle?: boolean) => void;
  closeSnackbar: () => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openSnackbar: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeSnackbar: () => {},
});
