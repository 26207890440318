import * as React from 'react';
import { useState, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { IMaskInput } from 'react-imask';

import ErrorDialog from '../common/error-dialog';
import Loading from '../common/loading';
import BackDrop from '../common/backdrop';
import Snackbar from '../common/snackbar';

import { useTranslation } from 'react-i18next';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      // mask="(#00) 000-0000"

      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={value => onChange({ target: { firstName: props.firstName, value } })}
      overwrite
    />
  );
});

function parseJwt(token) {
  if (token)
    try {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error(e);
      return '';
    }
  console.log('token', token);
  return '';
}

const udata = parseJwt(localStorage.jwt);

let oldData = [];

function Profile() {
  const { t } = useTranslation(); // i18n

  const [email, changeEmail] = useState(null);
  const [firstName, changeName] = useState(null);
  const [lastName, changeSurname] = useState(null);
  const [phoneNumbers, changePhoneNumbers] = useState(null);
  const [password, changePassword] = useState('');
  const [confirmPassword, changeConfirmPassword] = useState('');

  const [firstNameValidationErr, setFirstNameValidationErr] = useState(null);
  const [lastNameValidationErr, setLastNameValidationErr] = useState(null);
  const [pnValidationErr, setPNValidationErr] = useState(null);
  const [emailValidationErr, setEmailValidationErr] = useState(null);
  const [passwordValidationErr, setPasswordValidationErr] = useState(null);
  const [confirmPasswordValidationErr, setConfirmPasswordValidationErr] = useState(null);

  const [dataEdited, setDataEdited] = useState(false);

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState(false);

  const [data, setData] = useState(null);

  const handleFirstNameChange = event => {
    if (firstNameValidationErr) setFirstNameValidationErr(null);
    changeName(event.target.value);
  };
  const handleLastNameChange = event => {
    if (lastNameValidationErr) setLastNameValidationErr(null);
    changeSurname(event.target.value);
  };
  // const handlePNChange = event => {
  //     if (pnValidationErr) setPNValidationErr(null);
  //     changePhoneNumbers(event.target.value);
  // }
  const handleEmailChange = event => {
    if (emailValidationErr) setEmailValidationErr(null);
    changeEmail(event.target.value);
  };
  const handlePasswordChange = event => {
    if (passwordValidationErr) setPasswordValidationErr(null);
    changePassword(event.target.value);
  };
  const handleConfirmPasswordChange = event => {
    if (confirmPasswordValidationErr) setConfirmPasswordValidationErr(null);
    changeConfirmPassword(event.target.value);
  };
  const handlePNChange = (event, idx) => {
    if (pnValidationErr) setPNValidationErr(null);

    const newPNs = phoneNumbers.map((pn, i) => {
      if (idx === i) pn = event.target.value;

      return pn;
    });

    changePhoneNumbers(newPNs);
  };

  const editPNs = i => {
    let newPNs = phoneNumbers;
    if (i === 0) newPNs.push('');
    else newPNs.splice(i, 1);

    changePhoneNumbers([...newPNs]);
  };

  const getData = async uid => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${uid}`;
      const headers = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
      };

      await fetch(url, headers)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            return response.json();
          } else {
            setError(`${response.status} ${response.statusText}`);
          }
        })
        .then(data => {
          const phones = data.phones ? data.phones.split(';') : [];

          oldData = [data.email, data.firstName, data.lastName, [...phones], password];

          setData(data);

          changeEmail(data.email);

          changeName(data.firstName);
          changeSurname(data.lastName);

          changePhoneNumbers(phones);
        })
        .catch(err => {
          console.error(err);
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const confirm = async () => {
    setBackdropOpen(true);

    let errors = {
      firstName: firstNameValidationErr,
      lastName: lastNameValidationErr,
      phoneNumber: pnValidationErr,
      email: emailValidationErr,
      password: passwordValidationErr,
      confirmPassword: confirmPasswordValidationErr,
    };

    if (firstName.length === 0) errors.firstName = t('Please provide your first firstName.');
    else if (!/^.{2,30}$/.test(firstName)) errors.firstName = t('Allowed 2-30 symbols');
    if (lastName.length === 0) errors.lastName = t('Please provide your last firstName.');
    else if (!/^.{2,30}$/.test(lastName)) errors.lastName = t('Allowed 2-30 symbols');
    if (email.length === 0) errors.email = t('Please provide your email');
    else if (
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        email
      )
    )
      errors.email = t('Please, provide valid email');
    if (password.length > 0) {
      console.log('password:', password, password.length);
      if (!/^(?=.*[А-ЯA-Z])(?=.*[а-яa-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/.test(password))
        errors.password = t('PasswordMinimumParams');

      if (confirmPassword !== password) errors.confirmPassword = t('Passwords do not match.');
      else errors.confirmPassword = null;
    } else {
      errors.password = null;
      errors.confirmPassword = null;
    }

    if (
      errors.firstName ||
      errors.lastName ||
      errors.phoneNumber ||
      errors.email ||
      errors.password ||
      errors.confirmPassword
    ) {
      setBackdropOpen(false);

      setFirstNameValidationErr(errors.firstName);
      setLastNameValidationErr(errors.lastName);
      setPNValidationErr(errors.phoneNumber);
      setEmailValidationErr(errors.email);
      setPasswordValidationErr(errors.password);
      setConfirmPasswordValidationErr(errors.confirmPassword);

      return;
    }

    // firstName lastName phoneNumbers
    try {
      const url = `${process.env.REACT_APP_API_URL}/users/${udata.id}`;
      const body = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        phones: phoneNumbers.join(';'),
        password: password,
      };
      const request = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(body),
      };

      await fetch(url, request)
        .then(response => {
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            oldData = [email, firstName, lastName, [...phoneNumbers], password];

            setDataEdited(false);
            setBackdropOpen(false);
            setSnackbar(true);
          }
        })
        .catch(err => {
          setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      setError(error);
    }
  };

  const hideSnackbar = () => setSnackbar(false);

  useEffect(() => {
    getData(udata.id);
  }, []);

  useEffect(() => {
    const isEqual =
      JSON.stringify(oldData) ===
      JSON.stringify([email, firstName, lastName, phoneNumbers, password]);
    if (!isEqual) {
      setDataEdited(true);
    } else {
      setDataEdited(false);
    }
  }, [email, firstName, lastName, phoneNumbers, password]);

  if (error) return <ErrorDialog open={Boolean(error)} text={error} />;
  else if (!data) return <Loading />;
  return (
    <Grid container style={{ width: '100%', padding: '32px', margin: '0px' }}>
      <BackDrop open={backdropOpen} />
      <Snackbar
        type={'success'}
        msg={'Successfuly updated profile data!'}
        open={snackbar}
        hideSnackbar={() => hideSnackbar()}
      />

      <Grid item xs={0} md={3} lg={4}></Grid>

      <Grid container item xs={12} md={6} lg={4}>
        <Paper elevation={5} style={{ width: '100%', padding: '12px', backgroundColor: '#FAFAFA' }}>
          <Grid item xs={12}>
            <Typography variant="h3" gutterBottom component="div" align="center">
              <AccountCircleIcon style={{ fontSize: '64px' }} />
            </Typography>
          </Grid>

          <Grid container spacing={3} item xs={12}>
            <Grid item xs={12}>
              <TextField
                label={t('Email')}
                variant="outlined"
                style={{ width: '100%' }}
                error={Boolean(emailValidationErr)}
                helperText={emailValidationErr}
                value={email}
                onChange={handleEmailChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label={t('First Name')}
                variant="outlined"
                style={{ width: '100%' }}
                error={Boolean(firstNameValidationErr)}
                helperText={firstNameValidationErr}
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label={t('Last Name')}
                variant="outlined"
                style={{ width: '100%' }}
                error={Boolean(lastNameValidationErr)}
                helperText={lastNameValidationErr}
                value={lastName}
                onChange={handleLastNameChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} item xs={12} style={{ paddingTop: '24px' }}>
            {phoneNumbers.map((pn, i) => {
              return (
                <Grid item xs={12} style={{ display: 'flex' }} key={i}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel>{t('Phone Number')}</InputLabel>
                    <OutlinedInput
                      label={t('Phone Number')}
                      value={pn}
                      onChange={e => handlePNChange(e, i)}
                    />
                  </FormControl>

                  <IconButton size="small" onClick={() => editPNs(i)}>
                    {i === 0 ? <AddCircleIcon /> : <RemoveCircleIcon />}
                  </IconButton>
                </Grid>
              );
            })}
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '24px' }}>
            <TextField
              label={t('New Password')}
              variant="outlined"
              style={{ width: '100%' }}
              type="password"
              error={Boolean(passwordValidationErr)}
              helperText={passwordValidationErr}
              value={password}
              onChange={handlePasswordChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              paddingTop: '24px',
              display: password.length !== 0 ? 'block' : 'none',
            }}
          >
            <TextField
              label={t('Confirm Password')}
              variant="outlined"
              style={{ width: '100%' }}
              type="password"
              error={Boolean(confirmPasswordValidationErr)}
              helperText={confirmPasswordValidationErr}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Grid>

          <Grid container spacing={3} item xs={12} style={{ paddingTop: '48px' }}>
            <Grid item xs={0} md={6} lg={8}></Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="contained"
                style={{ width: '100%' }}
                onClick={confirm}
                disabled={!dataEdited}
              >
                {t('Confirm')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item xs={0} md={3} lg={4}></Grid>
    </Grid>
  );
}

export default Profile;
