import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useTranslation } from 'react-i18next';
import ErrorDialog from '../common/error-dialog';

export default function MinistryNotesDialog(props) {
  const { t } = useTranslation(); // i18n
  // const [open, setOpen] = React.useState(false);
  const [publications, setPublications] = React.useState(0);
  const handlePublicationsChange = event => {
    // console.log("Publications: ", event.target.value, typeof event.target.value)
    setPublications(Number(event.target.value));
  };
  const [talks, setTalks] = React.useState(0);
  const handleTalksChange = event => {
    // console.log("talks: ", event.target.value, typeof event.target.value)
    setTalks(Number(event.target.value));
  };
  const [notes, setNotes] = React.useState('');
  const handleNotesChange = event => {
    // console.log("Notes: ", event.target.value)
    setNotes(event.target.value);
  };
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    // console.log("useEffect.props1 ", props);
    if (props.params.open) {
      // console.log("useEffect.Open! ", props.params.cell);

      try {
        const url = `${process.env.REACT_APP_API_URL}/ministryNotes/${props.params.cell.id}`;
        const headers = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.jwt}`,
          },
        };

        fetch(url, headers)
          .then(response => {
            // console.log("response: ", response);
            if (response.status === 401) window.location = '/sign-in';
            else if (response.ok) return response.json();
            else alert(`${response.status} ${response.statusText}`);
          })
          .then(data => {
            // console.log("data: ", data, typeof data.notes)
            if (data && data.notes) {
              let mNotesFromDB;
              try {
                if (typeof data.notes === 'string') {
                  mNotesFromDB = JSON.parse(data.notes);
                } else {
                  mNotesFromDB = data.notes;
                }
                // console.log("json", mNotesFromDB);
                setTalks(mNotesFromDB.talks ? mNotesFromDB.talks : 0);
                setPublications(mNotesFromDB.publications ? mNotesFromDB.publications : 0);
                setNotes(mNotesFromDB.notes ? mNotesFromDB.notes : '');
              } catch (error) {
                console.log('e#69 error ', error);
                alert(error);
              }
            } else {
              setTalks(0);
              setPublications(0);
              setNotes('');
            }
          })
          .catch(err => {
            console.error('E#78: ', err);
            alert(t('Sorry! Failed to connect server =('));
          });
      } catch (error) {
        console.error('E#82: ', error);
        alert(error);
      }
    }
  }, [props.params.open]);

  const handleAddOrUpdateMinistryNotesSave = async event => {
    // const id = event.target.value;
    // console.log("#71", event, props.params.cell)

    try {
      const url = `${process.env.REACT_APP_API_URL}/ministryNotes/${props.params.cell.id}`;
      // console.log("#75 url", url, talks)
      // console.log("#75 url", url, talks, publications, notes)
      // console.log("#75 url", url, talks, this.talks, publications, this.publications)

      let body = {
        talks: talks,
        publications: publications,
        notes: notes,
      };

      // console.log("#83 body", body)

      // if (cellNum === 1) {
      //     if (cell.first === true) {
      //         body.user1_id = 0; // handle cancel record
      //         cell.first = null;
      //     } else {
      //         body.user1_id = props.uid // handle record
      //         cell.first = true;
      //     }
      // } else if (cellNum === 2) {
      //     if (cell.second === true) {
      //         body.user2_id = 0; // handle cancel record
      //         cell.second = null;
      //     } else {
      //         body.user2_id = props.uid // handle record
      //         cell.second = true;
      //     }
      // }

      const headers = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.jwt}`,
        },
        body: JSON.stringify(body),
      };

      await fetch(url, headers)
        .then(response => {
          // console.log("response", response)
          if (response.status === 401) {
            window.location = '/sign-in';
          } else if (response.ok) {
            return response.json();
          } else if (response.status === 409) {
            console.error('#238 Duplicate entry. Respons', response);

            props.alert();
          }
        })
        .then(data => {
          // console.log("#124 data", data)
          //updateDom(cell, data.updatedId);
          // updateDom(cell, cell.id);
          // setBackdropOpen(false);
        })
        .catch(err => {
          // console.error("#130 err", err)
          props.setError(t('Sorry! Failed to connect server =('));
        });
    } catch (error) {
      // console.error("#134 err", error)
      setError(error);
    }
  };

  // console.log("root.props2 ", props);
  return (
    <div>
      <ErrorDialog open={Boolean(error)} text={error} />
      <Dialog open={props.params.open}>
        <DialogTitle>{t('Notes')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('MinistryNotes')}</DialogContentText>
          <Grid item xs={12}>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              id="talks"
              label={t('talks')}
              // type="number"
              type="text"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onInput={e => {
                e.target.value = isNaN(parseInt(e.target.value))
                  ? 0
                  : Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
              }}
              value={talks}
              onChange={handleTalksChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoFocus
              variant="outlined"
              margin="dense"
              id="publications"
              label={t('publications')}
              type="text"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              onInput={e => {
                e.target.value = isNaN(parseInt(e.target.value))
                  ? 0
                  : Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
              }}
              value={publications}
              onChange={handlePublicationsChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              variant="contained"
              minRows={3}
              maxRows={15}
              aria-label={t('Notes')}
              placeholder={t('Notes')}
              // style={{ width: 200 }}
              style={{ width: '100%' }}
              // fullWidth
              value={notes}
              onChange={handleNotesChange}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // console.log("Save");
              handleAddOrUpdateMinistryNotesSave();
              props.func({ open: false });
            }}
          >
            {t('Confirm')}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              // console.log("Closed");
              props.func({ open: false });
            }}
          >
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
