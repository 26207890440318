import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function MySelect({ classes, name, label, value, options, onChange }) {
  // console.log("MySelect.begining", classes, name, label, value, typeof value);

  return (
    <FormControl className={classes.mySelect}>
      <InputLabel htmlFor="age-simple">{label}</InputLabel>
      <Select
        value={value || ''}
        name={name}
        onChange={onChange}
        // inputProps={{
        //   name: { name1 },
        //   id: { name1 }
        // }}
      >
        {options.map(o => (
          <MenuItem
            key={'_' + Math.random().toString(36).substr(2, 9)}
            value={o.id}
            // style={getStyles(name, personName, theme)}
          >
            {o.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
