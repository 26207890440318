import * as React from 'react';
import { useState, useEffect } from 'react';
import {
  Stack,
  Typography,
  Chip,
  Divider,
  TextField,
  styled,
} from '@mui/material';

import Backdrop from '../common/backdrop';
import ErrorDialog from '../common/error-dialog';

import { useTranslation } from 'react-i18next';
import MinistryNotesDialog from './ministry-notes-dialog';

const CustomDisableInput = styled(TextField)(() => ({
  '.MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000',
    color: '#000',
    paddingLeft: '8px',
    paddingRight: '8px',
    textAlign: 'center',
  },
}));

/**
 * Supported:
 * 
 * sunday_intervals =[
	{
		"from": "08:00",
		"to": "10:30",
		"duration": 30,
		"allowedusers": 2
	},
	{
		"from": "11:00",
		"to": "15:00",
		"duration": 60,
		"allowedusers": 4
	},
	{
		"from": "16:00",
		"to": "19:00",
		"duration": 30,
		"allowedusers": 2
	}
]
 * @param
 * @returns 
 */
function Form4standOverview(props) {
  const { t } = useTranslation();

  const {
    dataToDisplay: data,
    uid: currentUserId,
    openedDay: ministryDate,
    availablePeople,
    openedDayOfWeek,
    standParams,
  } = props;

  const dayIntervals = JSON.parse(standParams[openedDayOfWeek.toLowerCase() + '_intervals']);

  const [ministryRecords, setMinistryRecords] = useState([]);

  const [error, setError] = useState(null);

  // Ministry Notes Dialog
  const [displayMinistryNotesDialog, setDisplayMinistryNotesDialog] = useState({
    open: false,
  });
  
  useEffect(() => {
    let ministryCellsNew = [];

    for (let i = 0; i < dayIntervals.length; i++) {
      let fromTime = new Date(`2000-01-01T${dayIntervals[i].from}:00`);
      let toTime = new Date(`2000-01-01T${dayIntervals[i].to}:00`);
      const duration = dayIntervals[i].duration ? parseInt(dayIntervals[i].duration) : 60;
      const allowedusers = dayIntervals[i].allowedusers ? parseInt(dayIntervals[i].allowedusers) : 2;
      while (fromTime <= toTime) {
        let ministryId = null,
          ministryUsers = [];

        const ministryTime = fromTime.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });

        // Check if there is a matching entry in dataStands for the current time
        const matchingMinistry = data.find(ministry => {
          const ministryStartAt = new Date(ministry.start_at);
          return (
            ministryStartAt.getDate() === ministryDate.getDate() &&
            ministryStartAt.getHours() === fromTime.getHours() &&
            ministryStartAt.getMinutes() === fromTime.getMinutes()
          );
        });

        if (matchingMinistry) {
          ministryId = matchingMinistry.id;

          for (let j = 0; j < allowedusers; j++) {
            const userId = matchingMinistry[`user${j + 1}_id`];
            if (userId) {
              ministryUsers[j] = availablePeople.find(
                user => user.id === matchingMinistry[`user${j + 1}_id`]
              );
            } else {
              ministryUsers[j] = {};
            }
          }
        }

        ministryCellsNew.push({ ministryId, ministryDate, ministryTime, ministryUsers, duration, allowedusers});

        fromTime.setMinutes(fromTime.getMinutes() + duration);
      }
    }

    setMinistryRecords(ministryCellsNew);
  }, [data]);

  function convertMinistryDateTime2start_at(ministryRecord) {
    return `${ministryRecord.ministryDate} ${ministryRecord.ministryTime}:00`;
  }

  return (
    <Stack my={1}>
      <ErrorDialog open={Boolean(error)} text={error} />
      <MinistryNotesDialog
        params={displayMinistryNotesDialog}
        func={setDisplayMinistryNotesDialog}
      />
      {/* <span>{ministryDate.toISOString().slice(0, 10)}</span> */}
      <Stack divider={<Divider orientation="horizontal" flexItem />} >
        {ministryRecords.map((ministry, i) => {
          const disabled =
            new Date(convertMinistryDateTime2start_at(ministry)) <
            new Date(
              new Date().setHours(new Date().getHours() - standParams.cancelBeforHours)
            ).setDate(new Date().getDate() - standParams.cancelBeforDays);

          const ministryUsersLength = ministry.ministryUsers.length;

          const emptyMinistryUsers = Array(ministry.allowedusers);
          return (
            <React.Fragment key={'ReactFragment' + ministry.id + i}>
              <Stack flexDirection="row" flexWrap="wrap" alignItems="center" spacing={1}>
                <Typography variant="body2">{ministry.ministryTime}</Typography>

                {ministry.ministryUsers.map((user, index) => {
                  const hasName = user && (user.lastName || user.firstName);
                  const firstNameShort = user.firstName ? user.firstName.slice(0, 1) + '.' : '';
                  return hasName ? (
                    <Chip 
                      key={'User' + ministry.ministryTime + index} 
                      label={`${user.lastName || ''} ${firstNameShort}`.trim()} 
                      size="small" 
                    />
                  ) : null
                })}
                {ministryUsersLength < ministry.allowedusers &&
                  [...emptyMinistryUsers].map((_, index) => (
                    <div key={'Empty' + ministry.ministryTime + index}>&nbsp;</div>
                  ))
                }
              </Stack>
            </React.Fragment>            
            // <React.Fragment key={'ReactFragment' + ministry.id + i}>
            //   <Stack flexDirection="row" flexWrap="wrap" alignItems="center">
            //     <div>{ministry.ministryTime}&nbsp;</div>

            //         {ministry.ministryUsers.map((user, index) => user 
            //           ? (<div key={'User' + ministry.ministryTime + index}>{user.lastName} {user.firstName}&nbsp;</div>) 
            //           : ""
            //         )}
            //         {ministryUsersLength < ministry.allowedusers &&
            //           [...emptyMinistryUsers].map((_, index) => (
            //               <div key={'Empty' + ministry.ministryTime + index}>&nbsp;</div>
            //           ))}

            //   </Stack>
            // </React.Fragment>
          );
        })}
      </Stack>
    </Stack>
  );
}

export default Form4standOverview;
