import { AreaArray, AreaPolygon, Maybe } from 'types';

export const getAreaPolygonLikeArray = (areaPolygon?: Maybe<AreaPolygon>): AreaArray => {
  const points = areaPolygon?.lineStrings?.at(0)?.points;
  const firstPoint = points?.at(0);
  const lastPoint = points?.at(-1);

  if (firstPoint?.x === lastPoint?.x && firstPoint?.y === lastPoint?.y) {
    points?.pop();
  }

  if (points && points?.length % 2 === 1) {
    points.push({ x: 0, y: 0 });
  }

  return (
    points?.reduce((areaArray: AreaArray, { x, y }) => {
      if (areaArray.length === 0 || areaArray.at(-1)?.length === 2) {
        areaArray.push([]);
        areaArray.at(-1)?.push([`${x}, ${y}`]);
      } else {
        areaArray.at(-1)?.push([`${x}, ${y}`]);
      }

      return areaArray;
    }, []) ?? []
  );
};
