import { Add, Delete, ExpandMore, Favorite, FavoriteBorder } from '@mui/icons-material';

import { IconsNamesMapType } from './types';

export const IconsMap: IconsNamesMapType = {
  add: Add,
  delete: Delete,
  expandMore: ExpandMore,
  favorite: Favorite,
  favoriteBorder: FavoriteBorder,
};
