import React, { FC } from 'react';
import { Button, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AddHomeWorkOutlined, History } from '@mui/icons-material';
import { MUIDataTableOptions } from 'mui-datatables';
import { isBefore } from 'date-fns';

import { Link, useNavigate } from 'react-router-dom';
import { StyledTerritoriesTable } from './StyledTerritoriesTable';
import { useAll, usePublishers } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Maybe, Scalars, TerritoryData, UserFields, UserPermissions } from 'types';
import { TerritoryFilterData, territoryTableFilter } from 'constant';
import { getSetLocalStorage, getWarnEndTerritoryDates, isPermissionsIncludes } from 'helpers';
import { Icon } from 'legos';

type Props = {
  filter: TerritoryFilterData;
  setFilter: React.Dispatch<React.SetStateAction<TerritoryFilterData>>;
};

export const TerritoriesTable: FC<Props> = ({ filter, setFilter }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [favorite] = getSetLocalStorage<Array<Maybe<Scalars['ID']> | undefined>>('favorite', []);

  const { data: territories } = useAll<TerritoryData[]>('/territories');
  const { getPublisher } = usePublishers();

  const filteredTerritories = territories
    ?.filter(territory =>
      filter.value === 'favorite' ? favorite.includes(territory.id) : filter.eq(territory)
    )
    .sort((a, b) => filter.sort(a, b));

  const handleChange = (e: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue !== null) {
      const newFilterIndex = territoryTableFilter.findIndex(({ value }) => value === newValue);
      setFilter(territoryTableFilter[newFilterIndex]);
    }
  };

  const columns = [
    {
      name: 'id',
      label: t('id'),
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'congregation_id',
      label: t('congregation_id'),
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'code',
      label: t('code'),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'street',
      label: t('street'),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'house',
      label: t('house'),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'entrance',
      label: t('entrance'),
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'user_id',
      label: t('Publisher'),
      options: {
        filter: true,
        sort: true,
        display:
          isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
          isPermissionsIncludes(UserPermissions.TerritoryAssist),
        customBodyRender: (id: Maybe<Scalars['ID']>) => {
          const publisher = getPublisher(id);
          return publisher
            ? `${publisher[UserFields.LastName]} ${publisher[UserFields.FirstName]}`
            : '';
        },
      },
    },
    {
      name: 'start_at',
      label: t('start_at2'),
      options: {
        filter: false,
        sort: true,
        display:
          isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
          isPermissionsIncludes(UserPermissions.TerritoryAssist),
      },
    },
    {
      name: 'finish_at',
      label: t('finish_at'),
      options: {
        filter: false,
        sort: true,
        display:
          isPermissionsIncludes(UserPermissions.TerritoryEditor) ||
          isPermissionsIncludes(UserPermissions.TerritoryAssist),
      },
    },
  ];

  const options: MUIDataTableOptions = {
    responsive: 'vertical',
    selectableRows: 'none',
    filterType: 'multiselect',
    onRowClick: row => navigate(`/territory/${row[0]}`),
    setRowProps: row => {
      const today = new Date();
      const startDate = new Date(row[7]);
      const { warnDate, endDate } = getWarnEndTerritoryDates(startDate);

      if (endDate && isBefore(endDate, today)) {
        return {
          className: 'MuiTableRow-Expired',
        };
      }

      if (warnDate && isBefore(warnDate, today)) {
        return {
          className: 'MuiTableRow-Warn',
        };
      }

      return {};
    },
    textLabels: {
      pagination: {
        next: t('MUITablePaginationNext'),
        previous: t('MUITablePaginationPrevious'),
        rowsPerPage: t('MUITablePaginationRowsPerPage'),
        displayRows: t('MUITablePaginationDisplayRows'),
      },
    },
  };

  return (
    <>
      <Stack mt={1} direction="row" justifyContent="space-between" gap={1} flexWrap="wrap">
        {isPermissionsIncludes(UserPermissions.TerritoryEditor) && (
          <Stack direction="row" gap={1}>
            <Link to="/territory/create">
              <Button variant="outlined">
                <AddHomeWorkOutlined fontSize="large" />
              </Button>
            </Link>
            <Link to="/territories/history">
              <Button variant="outlined">
                <History fontSize="large" />
              </Button>
            </Link>
          </Stack>
        )}
        <ToggleButtonGroup color="primary" value={filter.value} exclusive onChange={handleChange}>
          {territoryTableFilter.map(({ value, icon }) => (
            <ToggleButton key={value} value={value}>
              {icon ? <Icon icon={icon} /> : t(value)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      {filteredTerritories && (
        <Stack my={1}>
          <StyledTerritoriesTable
            title={t('territories')}
            data={filteredTerritories}
            columns={columns}
            options={options}
          />
        </Stack>
      )}
    </>
  );
};
