import React, { Component } from 'react';
import PropTypes from 'prop-types';

// import { withTranslation } from "react-i18next";
// import { withTranslate } from "react-admin";

//import Typography from "@mui/material/Typography";
import Typography from '@mui/material/Typography';

// import Button from "@mui/material/Button";
import Button from '@mui/material/Button';

import Autocomplete from '@mui/material/Autocomplete';

// import Box from "@mui/material/Box";
import Box from '@mui/material/Box';
// import Paper from "@mui/material/Paper";
// import InputBase from "@mui/material/InputBase";
// import IconButton from "@mui/material/IconButton";
// import SearchIcon from "@mui/icons-material/Search";
// import ClearIcon from "@mui/icons-material/Clear";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import TSSelect5 from "./TSSelect5";
// import MenuItem from "@mui/material/MenuItem";
// import TextField from "@mui/material/TextField";
import TextField from '@mui/material/TextField';
// import API from "../utils/API";

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 250,
  },
  root: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 250,
    maxWidth: 650,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 2,
  },
  label: {
    color: 'gray',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});
const boxProps = {
  bgcolor: 'background.paper',
  //   borderColor: "text.primary",
  p: 1,
  m: 1,
  boxShadow: 3,
  borderRadius: 'borderRadius',
  //   border: 1,
  style: { width: '550' }, //, height: "5rem"
};

class TSEdit extends Component {
  constructor(props) {
    super(props);
    // var taskTitle = "";
    // var taskAssignee: "";
    // this.state = {
    //   //   classes: props.classes,
    //   //   talkAssignees: props.talkAssignees,
    //   //   readOnly: props.readOnly,
    //   //   week_start: props.week_start,
    //   //   tsTask: props.tsTask,
    //   taskTitle: "",
    //   taskAssignee: ""
    // };
    console.log('constructor', props, this.state);

    this.onAutcompleteTitleChange = this.onAutcompleteTitleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // async componentDidMount() {
  //   console.log("componentDidMount");
  //   try {
  //     const { data: sunTalkInfo } = await API.get("/tsDBHelperSundayTalkInfo", {
  //       params: {
  //         week_start: this.state.week_start
  //       }
  //     });
  //     console.log("sunTalkInfo1:", sunTalkInfo);
  //     // console.log("sunTalkInfo2:", sunTalkInfo["full_name"]);
  //     // console.log("sunTalkInfo3:", sunTalkInfo["title"]);

  //     if (sunTalkInfo !== null) {
  //       if (sunTalkInfo.full_name) {
  //         this.setState({ taskAssignee: sunTalkInfo.full_name });
  //       }
  //       if (sunTalkInfo.title) {
  //         this.setState({ taskTitle: sunTalkInfo.title });
  //       }
  //     }
  //   } catch (e) {
  //     const msg = `😱 Request to the server has failed: ${e}`;
  //     console.log(msg);
  //     alert(msg);
  //   }
  // }

  onAutcompleteTitleChange = (event, value) => {
    this.props.onSundayTalkChange('title', value);
    // console.log("onAutcompleteTitleChange", event, value);
    // this.setState({ taskTitle: value });
  };
  onAutcompleteInputTitleChange = (event, value, reason) => {
    // console.log("onAutcompleteInputTitleChange", event, value, reason);
    if (reason === 'input') {
      // console.log("onAutcompleteInputTitleChange!!!!", event, value, reason);
      this.props.onSundayTalkChange('title', value);
      // this.setState({ taskTitle: value });
    }
  };
  onAutcompleteAssigneeChange = (event, value) => {
    this.props.onSundayTalkChange('full_name', value);
    // console.log("onAutcompleteAssigneeChange", event, value);
    // this.setState({ taskAssignee: value });
  };
  onAutcompleteInputAssigneeChange = (event, value, reason) => {
    // console.log(
    //   "onAutcompleteInputTitleChange",
    //   // event,
    //   value,
    //   reason
    // );
    if (reason === 'input') {
      // console.log("onAutcompleteInputTitleChange!!!!", event, value, reason);
      this.props.onSundayTalkChange('full_name', value);
      // this.setState({ taskAssignee: value });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log("handleSubmit", this.state.taskTitle, this.state.taskAssignee);

    // console.log("handleSubmit", this.state.taskTitle, this.state.taskAssignee);
    this.props.onSave(
      // this.state.taskTitle,
      // this.state.taskAssignee,
      this.props.assignment_id
    );
    // const response = await API.post(`/tsDBHelperSundayTalkInfo`, {
    //   assignment_id: this.state.tsTask.assignment_id,
    //   week_start: this.state.week_start,
    //   title: this.state.taskTitle,
    //   full_name: this.state.taskAssignee
    // });
    // if (response.data !== 1 || response.statusText !== "OK") {
    //   alert("Not saved. Try again later, or contact Ihor Choriy.");
    // }
    // console.log(response);
    // console.log(response.data);
  };

  render() {
    const { classes, sunTalkInfo, talkAssignees, readOnly } = this.props;
    // classes: props.classes,
    // talkAssignees: props.talkAssignees,
    // readOnly: props.readOnly,
    // week_start: props.week_start,
    // tsTask: props.tsTask,
    // taskTitle: "",
    // taskAssignee: ""
    // this.setState({
    //   taskTitle: sunTalkInfo.taskTitle,
    //   taskAssignee: sunTalkInfo.taskAssignee
    // });
    // let taskTitle = sunTalkInfo.taskTitle;
    // let taskAssignee = sunTalkInfo.taskAssignee;

    // console.log("TSEdit.render", this.props);

    // console.log("TSEdit.render.talkSpeakers", talkSpeakers1);

    const id = '_' + Math.random().toString(36).substr(2, 9);

    return (
      <div>
        <Box {...boxProps}>
          <div style={{ width: 550 }}>
            <Typography className={classes.label}>
              {this.props.translate('tsedit.labelWTTalkTitle')}
            </Typography>
            <Autocomplete
              id={'taskTitle' + id}
              onChange={this.onAutcompleteTitleChange}
              onInputChange={this.onAutcompleteInputTitleChange}
              inputValue={sunTalkInfo.title || ''}
              includeInputInList={true}
              freeSolo
              disabled={readOnly}
              options={talksTopics.map(option => option.title)}
              renderInput={params => (
                <TextField
                  {...params}
                  // label="Назва Промови"
                  // margin="normal"
                  // placeholer="Назва Промови"
                  // variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <br></br>
          <div style={{ width: 250 }}>
            <Typography className={classes.label}>
              {this.props.translate('tsedit.labelWTTalkSpeaker')}
            </Typography>
            <Autocomplete
              id={'taskAssignee' + id}
              onChange={this.onAutcompleteAssigneeChange}
              onInputChange={this.onAutcompleteInputAssigneeChange}
              inputValue={sunTalkInfo.full_name || ''}
              includeInputInList={true}
              freeSolo
              disabled={readOnly}
              options={talkAssignees}
              renderInput={params => (
                <TextField
                  {...params}
                  // label="Назва Промови"
                  // margin="normal"
                  // placeholer="Промовець"
                  // variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
          <Button onClick={this.handleSubmit} color="default" disabled={readOnly}>
            {this.props.translate('tsedit.saveBtn')}
          </Button>
        </Box>
      </div>
    );
  }
}

TSEdit.propTypes = {
  tsDate: PropTypes.string,
  // tsTask: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};

// export default withTranslation()(withStyles(styles)(TSEdit));
// export default withTranslate(TSEdit);
export default TSEdit;

const talksTopics = [
  { title: 'Наскільки добре ви знаєте Бога?' },
  { title: 'Чи ви переживете останні дні?' },
  { title: 'Рухайтесь вперед з об’єднаною організацією Єгови' },
  { title: 'Докази існування Бога у навколишньому світі' },
  { title: 'Надійна допомога для сім’ї' },
  { title: 'Що Потоп за днів Ноя означає для вас' },
  { title: 'Наслідуйте «Батька глибокого співчуття»' },
  { title: 'Живіть не для себе, а щоб виконувати Божу волю' },
  { title: 'Будьте слухачами і виконавцями Божого Слова' },
  { title: 'Завжди говоріть правду і поводьтеся чесно' },
  { title: 'Вони, як і Христос, «не належать до світу»' },
  { title: 'Чи для Бога має значення наше ставлення до влади?' },
  { title: 'Божий погляд на статеве життя і шлюб' },
  { title: 'Чистий народ прославляє Єгову' },
  { title: '«Чинімо добро всім»' },
  { title: 'Розвивайте дружбу з Богом' },
  { title: 'Прославляймо Бога усім, що маємо' },
  { title: 'Робіть Єгову своєю твердинею' },
  { title: 'Як дізнатися про своє майбутнє?' },
  { title: 'Чи вже настав час Богові правити світом?' },
  { title: 'Цінуйте можливість бути підданими Царства' },
  { title: 'Чи ви користуєтеся дарами Єгови?' },
  { title: 'Життя дійсно має мету' },
  { title: 'Те, що Боже правління може зробити для нас' },
  { title: 'Як протистояти духові світу' },
  { title: 'Як забезпечити добрий початок у подружньому житті' },
  { title: 'Виявляйте у подружжі повагу і любов' },
  { title: 'Обов’язок і нагорода батьків' },
  { title: 'Обмін думками в сімейному колі та з Богом' },
  { title: 'Чи ви усвідомлюєте свої духовні потреби?' },
  { title: 'Як справлятися з життєвими турботами?' },
  { title: 'Що зумовлює бунтарський дух?' },
  { title: 'Чи ви можете жити вічно? Чи житимете вічно?' },
  { title: 'Чи ми маємо лише це життя?' },
  { title: 'Чи Божі дороги ведуть до благословень?' },
  { title: 'Поводьтеся мудро тому, що настає кінець' },
  { title: 'Будьте впевнені в божественній перемозі!' },
  { title: '«Станьте твердо… й побачите, що перемога Господня буде з вами»' },
  { title: 'Як Боже Царство впливає на твоє життя?' },
  { title: 'Чи ти робиш те, чого Бог вимагає від тебе?' },
  { title: 'Невпинно шукайте Боже Царство' },
  { title: 'Тримайтеся дороги життя' },
  { title: 'Будьмо впевнені у своїй вірі аж до кінця' },
  { title: 'Успішно зустрічати випробування християнської вірності' },
  { title: 'Чи ти доживеш до очищеної землі?' },
  { title: 'Як ти приймаєш рішення?' },
  { title: 'Чи правда змінює твоє життя?' },
  { title: 'Хто є твоїм Богом?' },
  { title: 'Чи ваш спосіб мислення узгоджується з думками Бога?' },
  { title: 'Зміцняйте свою віру у Творця людини' },
  { title: 'Яке ім’я ви здобуваєте у Бога?' },
  { title: 'Витривалість під час переслідування' },
  { title: 'Хто є справжніми послідовниками Христа?' },
  { title: 'Ви пожнете те, що сієте' },
  { title: 'Наскільки змістовним є ваше життя?' },
  { title: 'На чиї обітниці ви покладаєтесь?' },
  { title: 'Єдині ліки для хворого людства' },
  { title: 'Чи ви маєте дух благовісника?' },
  { title: 'Чи ми любимо розваги, чи Бога?' },
  { title: 'Як сприяти миру у злому світі' },
  { title: 'Служіть Господареві жнива' },
  { title: 'Виділяйте час на роздумування про духовні справи' },
  { title: 'Чи ви ображаєтеся, чи прощаєте?' },
  { title: 'Відновлення духу самопожертви' },
  { title: 'Покладайтеся на Єгову' },
  { title: 'Як залишатися духовно пильними' },
  { title: 'Правдивий християнський збір пізнається по любові' },
  { title: 'Як набути серце мудре' },
  { title: 'Очі Єгови на нас' },
  { title: 'Чи ви визнаєте суверенітет Єгови у своєму особистому житті?' },
  {
    title: 'Чи можуть біблійні принципи допомогти нам справлятися з сьогоднішніми проблемами?',
  },
  { title: 'Дбайте про гостинність' },
  { title: 'Служіть Єгові з радісним серцем' },
  { title: 'Дружба з Богом. Дружба зі світом. Що ви вибираєте?' },
  { title: 'Основою вашої надії є наука чи Біблія?' },
  { title: 'Хто кваліфікований бути служителем Бога?' },
  { title: 'Чи Єгова і Христос є частиною Трійці?' },
  { title: 'Час вироку для релігії' },
  { title: 'Чи ви уникнете долі цього світу?' },
  { title: 'Добра новина у насильницькому світі' },
  { title: 'Молитви, які вислуховує Бог' },
  { title: 'Які у тебе стосунки з Богом?' },
  { title: 'Чому треба жити згідно з біблійними нормами?' },
  { title: 'Прийдіть, ви, хто прагне правди!' },
  { title: 'Докладай зусиль, щоб досягнути справжнього життя!' },
  { title: 'Присутність Месії і його правління' },
  { title: 'Роль релігії у світових справах' },
  { title: 'Як дізнатись, чи Бог винен у стихійних лихах?' },
  { title: 'Правдива релігія задовольняє потреби людського суспільства' },
  { title: 'Погляд Біблії на спіритизм' },
  { title: 'Яке майбутнє релігії?' },
  { title: 'Залишайтесь бездоганними серед лукавого покоління' },
  { title: '«Сцена цього світу змінюється»' },
  { title: 'Чому ви можете довіряти Біблії' },
  { title: 'Справжня дружба з Богом і ближнім' },
  { title: 'Єгова – Величний Творець' },
  { title: 'Звертаймо увагу на пророче слово' },
  { title: 'Ви можете знайти радість у служінні Богу' },
  { title: 'Батьки, чи ви будуєте з вогнестійких матеріалів?' },
  { title: 'Де знайти втіху від усіх наших скорбот' },
  { title: 'Нищення землі принесе Божу відплату' },
  { title: 'Зберігайте добре сумління в грішному світі' },
  { title: 'Як подолати страх перед майбутнім' },
  { title: 'Боже Царство наблизилось' },
  { title: 'Бог стоїть на першому місці в успішному сімейному житті' },
  { title: 'Що буде досягнуто зціленням народів?' },
  { title: 'Як виявляти любов у беззаконному світі' },
  { title: 'Як молодим справлятися з сьогоднішньою кризою?' },
  { title: 'Вдячність за чудеса Божого творення' },
  { title: 'Як захищатися від пасток Сатани' },
  { title: 'Мудро вибирай собі друзів!' },
  { title: 'Як перемагати зло добром' },
  { title: 'Дивіться на молодь з погляду Єгови' },
  { title: 'Чому корисна християнська відокремленість від світу' },
  { title: 'Чому підпорядковуватися Божому правлінню тепер' },
  { title: 'Усесвітнє братство врятоване від лиха' },
  { title: 'Звідки прийде всесвітній мир?' },
  { title: 'Чому християни мусять бути інакшими' },
  { title: 'Підстави для впевненості в тому, що автор Біблії – Бог' },
  { title: 'Чому людство потребує викупу' },
  { title: 'Хто може бути спасенний?' },
  { title: 'Що стається, коли ми помираємо?' },
  { title: 'Чи дійсно пекло є місцем вогняних мук?' },
  { title: 'Чи вчення про Трійцю є біблійним?' },
  { title: 'Земля залишиться назавжди' },
  { title: 'Чи справді існує Диявол?' },
  { title: 'Воскресіння – перемога над смертю!' },
  { title: 'Чи ваш погляд про походження людини має якесь значення?' },
  { title: 'Чи християни повинні дотримуватися суботи?' },
  { title: 'Святість життя та крові' },
  { title: 'Чи Бог схвалює використання зображень у поклонінні?' },
  { title: 'Чи біблійні чуда справді відбувалися?' },
  { title: 'Живіть розсудливо в зіпсованому світі' },
  { title: 'Побожна мудрість у добу наукового прогресу' },
  { title: 'Ким насправді є Ісус Христос?' },
  { title: 'Коли припиняться зітхання людських створінь?' },
  { title: 'Чому потрібно шукати притулку в Єгови' },
  { title: 'Покладайтеся на Бога потіхи всілякої' },
  { title: 'Відданий збір під Христовим керівництвом' },
  { title: 'Хто подібний до Єгови, нашого Бога?' },
  { title: 'Використовуйте освіту на хвалу Єгові' },
  { title: 'Покладайтеся на рятівну силу Єгови' },
  { title: 'Чи ви поділяєте Божий погляд на життя?' },
  { title: 'Чи ви ходите з Богом?' },
  { title: 'Чи цей світ приречений на загибель?' },
  { title: 'Єгова є «твердинею» для свого народу' },
  { title: 'Чому і коли відбудеться справжній Армагеддон?' },
  { title: 'Твердо пам’ятайте про страшний день!' },
  { title: 'Людське правління зважене на вазі' },
  { title: 'Чи настала година суду над Вавилоном?' },
  { title: 'Судний день – час страху чи надії?' },
  { title: 'Як правдиві християни прикрашають божественне навчання' },
  { title: 'Будьте мужні і покладайтеся на Єгову' },
  { title: 'Знаходьте безпеку в небезпечному світі' },
  { title: 'Завжди пам’ятай, що ти християнин!' },
  { title: 'Заради чого страждав і помер Ісус?' },
  { title: 'Визволення від світу темряви' },
  { title: 'Чому треба боятися правдивого Бога?' },
  { title: 'Чи Бог усе ще править?' },
  { title: 'Чиї цінності для вас найважливіші?' },
  { title: 'Дивіться в майбутнє з вірою та мужністю' },
  { title: 'Поводьтеся мудро в безбожному світі' },
  { title: 'Ви можете знайти безпеку в неспокійному світі' },
  { title: 'Чому варто слухатися керівництва Біблії?' },
  { title: 'Хто є найліпшим правителем для людства?' },
  { title: 'Ви можете жити в мирі тепер і вічно!' },
  { title: 'Яку репутацію ви маєте в Божих очах?' },
  { title: 'Яка релігія є правдивою в очах Бога?' },
  { title: 'Хто гідний жити в Божому новому світі?' },
  { title: 'Чому варто довіряти Біблії?' },
  { title: 'Коли настане справжній мир і безпека?' },
  { title: 'Де знайти допомогу в час лиха?' },
  { title: 'Ходіть у непорочності' },
  { title: 'Відкидайте світські фантазії і пов’язуйте своє життя з Царством' },
  { title: 'Чому варто всім серцем надіятись на воскресіння?' },
  { title: 'Кінець може бути ближче, ніж здається' },
  { title: 'Що Боже Царство робить для нас вже тепер' },
  { title: 'Відверни свої очі від марноти!' },
  { title: 'Чи смерть – це кінець усього?' },
  { title: 'Як правда впливає на ваше життя?' },
  { title: 'Приєднайтесь до щасливого народу Бога' },
  { title: 'Чому люблячий Бог допускає зло?' },
  { title: 'Чи ти покладаєшся на Єгову?' },
  { title: 'Ходіть з Богом, і він благословлятиме вас тепер і цілу вічність' },
  { title: 'Досконале щастя в сім’ї — це реальність' },
  { title: 'Як любов і віра перемагають світ' },
  { title: 'Чи ви йдете дорогою, яка веде до вічного життя?' },
  { title: 'Порятунок під час всесвітнього лиха' },
  { title: 'Яку користь приносить нам мудрість від Бога' },
];
// { value: 1, title: "Наскільки добре ви знаєте Бога?" },
// { value: 2, title: "Чи ви переживете останні дні?" },
// { value: 3, title: "Рухайтесь вперед з об’єднаною організацією Єгови" },
// { value: 4, title: "Докази існування Бога у навколишньому світі" },
// { value: 5, title: "Надійна допомога для сім’ї" },
// { value: 6, title: "Що Потоп за днів Ноя означає для вас" },
// { value: 7, title: "Наслідуйте «Батька глибокого співчуття»" },
// { value: 8, title: "Живіть не для себе, а щоб виконувати Божу волю" },
// { value: 9, title: "Будьте слухачами і виконавцями Божого Слова" },
// { value: 10, title: "Завжди говоріть правду і поводьтеся чесно" },
// { value: 11, title: "Вони, як і Христос, «не належать до світу»" },
// { value: 12, title: "Чи для Бога має значення наше ставлення до влади?" },
// { value: 13, title: "Божий погляд на статеве життя і шлюб" },
// { value: 14, title: "Чистий народ прославляє Єгову" },
// { value: 15, title: "«Чинімо добро всім»" },
// { value: 16, title: "Розвивайте дружбу з Богом" },
// { value: 17, title: "Прославляймо Бога усім, що маємо" },
// { value: 18, title: "Робіть Єгову своєю твердинею" },
// { value: 19, title: "Як дізнатися про своє майбутнє?" },
// { value: 20, title: "Чи вже настав час Богові правити світом?" },
// { value: 21, title: "Цінуйте можливість бути підданими Царства" },
// { value: 22, title: "Чи ви користуєтеся дарами Єгови?" },
// { value: 23, title: "Життя дійсно має мету" },
// { value: 24, title: "Те, що Боже правління може зробити для нас" },
// { value: 25, title: "Як протистояти духові світу" },
// { value: 27, title: "Як забезпечити добрий початок у подружньому житті" },
// { value: 28, title: "Виявляйте у подружжі повагу і любов" },
// { value: 29, title: "Обов’язок і нагорода батьків" },
// { value: 30, title: "Обмін думками в сімейному колі та з Богом" },
// { value: 31, title: "Чи ви усвідомлюєте свої духовні потреби?" },
// { value: 32, title: "Як справлятися з життєвими турботами?" },
// { value: 33, title: "Що зумовлює бунтарський дух?" },
// { value: 35, title: "Чи ви можете жити вічно? Чи житимете вічно?" },
// { value: 36, title: "Чи ми маємо лише це життя?" },
// { value: 37, title: "Чи Божі дороги ведуть до благословень?" },
// { value: 38, title: "Поводьтеся мудро тому, що настає кінець" },
// { value: 39, title: "Будьте впевнені в божественній перемозі!" },
// {
//   value: 41,
//   title: "«Станьте твердо… й побачите, що перемога Господня буде з вами»"
// },
// { value: 42, title: "Як Боже Царство впливає на твоє життя?" },
// { value: 43, title: "Чи ти робиш те, чого Бог вимагає від тебе?" },
// { value: 44, title: "Невпинно шукайте Боже Царство" },
// { value: 45, title: "Тримайтеся дороги життя" },
// { value: 46, title: "Будьмо впевнені у своїй вірі аж до кінця" },
// {
//   value: 48,
//   title: "Успішно зустрічати випробування християнської вірності"
// },
// { value: 49, title: "Чи ти доживеш до очищеної землі?" },
// { value: 50, title: "Як ти приймаєш рішення?" },
// { value: 51, title: "Чи правда змінює твоє життя?" },
// { value: 52, title: "Хто є твоїм Богом?" },
// { value: 53, title: "Чи ваш спосіб мислення узгоджується з думками Бога?" },
// { value: 54, title: "Зміцняйте свою віру у Творця людини" },
// { value: 55, title: "Яке ім’я ви здобуваєте у Бога?" },
// { value: 57, title: "Витривалість під час переслідування" },
// { value: 58, title: "Хто є справжніми послідовниками Христа?" },
// { value: 59, title: "Ви пожнете те, що сієте" },
// { value: 60, title: "Наскільки змістовним є ваше життя?" },
// { value: 61, title: "На чиї обітниці ви покладаєтесь?" },
// { value: 62, title: "Єдині ліки для хворого людства" },
// { value: 63, title: "Чи ви маєте дух благовісника?" },
// { value: 64, title: "Чи ми любимо розваги, чи Бога?" },
// { value: 65, title: "Як сприяти миру у злому світі" },
// { value: 66, title: "Служіть Господареві жнива" },
// { value: 67, title: "Виділяйте час на роздумування про духовні справи" },
// { value: 68, title: "Чи ви ображаєтеся, чи прощаєте?" },
// { value: 69, title: "Відновлення духу самопожертви" },
// { value: 70, title: "Покладайтеся на Єгову" },
// { value: 71, title: "Як залишатися духовно пильними" },
// { value: 72, title: "Правдивий християнський збір пізнається по любові" },
// { value: 73, title: "Як набути серце мудре" },
// { value: 74, title: "Очі Єгови на нас" },
// {
//   value: 75,
//   title: "Чи ви визнаєте суверенітет Єгови у своєму особистому житті?"
// },
// {
//   value: 76,
//   title:
//     "Чи можуть біблійні принципи допомогти нам справлятися з сьогоднішніми проблемами?"
// },
// { value: 77, title: "Дбайте про гостинність" },
// { value: 78, title: "Служіть Єгові з радісним серцем" },
// { value: 79, title: "Дружба з Богом. Дружба зі світом. Що ви вибираєте?" },
// { value: 80, title: "Основою вашої надії є наука чи Біблія?" },
// { value: 81, title: "Хто кваліфікований бути служителем Бога?" },
// { value: 82, title: "Чи Єгова і Христос є частиною Трійці?" },
// { value: 83, title: "Час вироку для релігії" },
// { value: 84, title: "Чи ви уникнете долі цього світу?" },
// { value: 85, title: "Добра новина у насильницькому світі" },
// { value: 86, title: "Молитви, які вислуховує Бог" },
// { value: 87, title: "Які у тебе стосунки з Богом?" },
// { value: 88, title: "Чому треба жити згідно з біблійними нормами?" },
// { value: 89, title: "Прийдіть, ви, хто прагне правди!" },
// { value: 90, title: "Докладай зусиль, щоб досягнути справжнього життя!" },
// { value: 91, title: "Присутність Месії і його правління" },
// { value: 92, title: "Роль релігії у світових справах" },
// { value: 93, title: "Як дізнатись, чи Бог винен у стихійних лихах?" },
// {
//   value: 94,
//   title: "Правдива релігія задовольняє потреби людського суспільства"
// },
// { value: 95, title: "Погляд Біблії на спіритизм" },
// { value: 96, title: "Яке майбутнє релігії?" },
// { value: 97, title: "Залишайтесь бездоганними серед лукавого покоління" },
// { value: 98, title: "«Сцена цього світу змінюється»" },
// { value: 99, title: "Чому ви можете довіряти Біблії" },
// { value: 100, title: "Справжня дружба з Богом і ближнім" },
// { value: 101, title: "Єгова – Величний Творець" },
// { value: 102, title: "Звертаймо увагу на пророче слово" },
// { value: 103, title: "Ви можете знайти радість у служінні Богу" },
// { value: 104, title: "Батьки, чи ви будуєте з вогнестійких матеріалів?" },
// { value: 105, title: "Де знайти втіху від усіх наших скорбот" },
// { value: 106, title: "Нищення землі принесе Божу відплату" },
// { value: 107, title: "Зберігайте добре сумління в грішному світі" },
// { value: 108, title: "Як подолати страх перед майбутнім" },
// { value: 109, title: "Боже Царство наблизилось" },
// {
//   value: 110,
//   title: "Бог стоїть на першому місці в успішному сімейному житті"
// },
// { value: 111, title: "Що буде досягнуто зціленням народів?" },
// { value: 112, title: "Як виявляти любов у беззаконному світі" },
// { value: 113, title: "Як молодим справлятися з сьогоднішньою кризою?" },
// { value: 114, title: "Вдячність за чудеса Божого творення" },
// { value: 115, title: "Як захищатися від пасток Сатани" },
// { value: 116, title: "Мудро вибирай собі друзів!" },
// { value: 117, title: "Як перемагати зло добром" },
// { value: 118, title: "Дивіться на молодь з погляду Єгови" },
// { value: 119, title: "Чому корисна християнська відокремленість від світу" },
// { value: 120, title: "Чому підпорядковуватися Божому правлінню тепер" },
// { value: 121, title: "Усесвітнє братство врятоване від лиха" },
// { value: 122, title: "Звідки прийде всесвітній мир?" },
// { value: 123, title: "Чому християни мусять бути інакшими" },
// {
//   value: 124,
//   title: "Підстави для впевненості в тому, що автор Біблії – Бог"
// },
// { value: 125, title: "Чому людство потребує викупу" },
// { value: 126, title: "Хто може бути спасенний?" },
// { value: 127, title: "Що стається, коли ми помираємо?" },
// { value: 128, title: "Чи дійсно пекло є місцем вогняних мук?" },
// { value: 129, title: "Чи вчення про Трійцю є біблійним?" },
// { value: 130, title: "Земля залишиться назавжди" },
// { value: 131, title: "Чи справді існує Диявол?" },
// { value: 132, title: "Воскресіння – перемога над смертю!" },
// {
//   value: 133,
//   title: "Чи ваш погляд про походження людини має якесь значення?"
// },
// { value: 134, title: "Чи християни повинні дотримуватися суботи?" },
// { value: 135, title: "Святість життя та крові" },
// { value: 136, title: "Чи Бог схвалює використання зображень у поклонінні?" },
// { value: 137, title: "Чи біблійні чуда справді відбувалися?" },
// { value: 138, title: "Живіть розсудливо в зіпсованому світі" },
// { value: 139, title: "Побожна мудрість у добу наукового прогресу" },
// { value: 140, title: "Ким насправді є Ісус Христос?" },
// { value: 141, title: "Коли припиняться зітхання людських створінь?" },
// { value: 142, title: "Чому потрібно шукати притулку в Єгови" },
// { value: 143, title: "Покладайтеся на Бога потіхи всілякої" },
// { value: 144, title: "Відданий збір під Христовим керівництвом" },
// { value: 145, title: "Хто подібний до Єгови, нашого Бога?" },
// { value: 146, title: "Використовуйте освіту на хвалу Єгові" },
// { value: 147, title: "Покладайтеся на рятівну силу Єгови" },
// { value: 148, title: "Чи ви поділяєте Божий погляд на життя?" },
// { value: 149, title: "Чи ви ходите з Богом?" },
// { value: 150, title: "Чи цей світ приречений на загибель?" },
// { value: 151, title: "Єгова є «твердинею» для свого народу" },
// { value: 152, title: "Чому і коли відбудеться справжній Армагеддон?" },
// { value: 153, title: "Твердо пам’ятайте про страшний день!" },
// { value: 154, title: "Людське правління зважене на вазі" },
// { value: 155, title: "Чи настала година суду над Вавилоном?" },
// { value: 156, title: "Судний день – час страху чи надії?" },
// {
//   value: 157,
//   title: "Як правдиві християни прикрашають божественне навчання"
// },
// { value: 158, title: "Будьте мужні і покладайтеся на Єгову" },
// { value: 159, title: "Знаходьте безпеку в небезпечному світі" },
// { value: 160, title: "Завжди пам’ятай, що ти християнин!" },
// { value: 161, title: "Заради чого страждав і помер Ісус?" },
// { value: 162, title: "Визволення від світу темряви" },
// { value: 163, title: "Чому треба боятися правдивого Бога?" },
// { value: 164, title: "Чи Бог усе ще править?" },
// { value: 165, title: "Чиї цінності для вас найважливіші?" },
// { value: 166, title: "Дивіться в майбутнє з вірою та мужністю" },
// { value: 167, title: "Поводьтеся мудро в безбожному світі" },
// { value: 168, title: "Ви можете знайти безпеку в неспокійному світі" },
// { value: 169, title: "Чому варто слухатися керівництва Біблії?" },
// { value: 170, title: "Хто є найліпшим правителем для людства?" },
// { value: 171, title: "Ви можете жити в мирі тепер і вічно!" },
// { value: 172, title: "Яку репутацію ви маєте в Божих очах?" },
// { value: 173, title: "Яка релігія є правдивою в очах Бога?" },
// { value: 174, title: "Хто гідний жити в Божому новому світі?" },
// { value: 175, title: "Чому варто довіряти Біблії?" },
// { value: 176, title: "Коли настане справжній мир і безпека?" },
// { value: 177, title: "Де знайти допомогу в час лиха?" },
// { value: 178, title: "Ходіть у непорочності" },
// {
//   value: 179,
//   title: "Відкидайте світські фантазії і пов’язуйте своє життя з Царством"
// },
// { value: 180, title: "Чому варто всім серцем надіятись на воскресіння?" },
// { value: 181, title: "Кінець може бути ближче, ніж здається" },
// { value: 182, title: "Що Боже Царство робить для нас вже тепер" },
// { value: 183, title: "Відверни свої очі від марноти!" },
// { value: 184, title: "Чи смерть – це кінець усього?" },
// { value: 185, title: "Як правда впливає на ваше життя?" },
// { value: 186, title: "Приєднайтесь до щасливого народу Бога" },
// { value: 187, title: "Чому люблячий Бог допускає зло?" },
// { value: 188, title: "Чи ти покладаєшся на Єгову?" },
// {
//   value: 189,
//   title: "Ходіть з Богом, і він благословлятиме вас тепер і цілу вічність"
// },
// { value: 190, title: "Досконале щастя в сім’ї — це реальність" },
// { value: 191, title: "Як любов і віра перемагають світ" },
// { value: 192, title: "Чи ви йдете дорогою, яка веде до вічного життя?" },
// { value: 193, title: "Порятунок під час всесвітнього лиха" },
// { value: 194, title: "Яку користь приносить нам мудрість від Бога" }
// ];
